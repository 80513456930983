/**
 * 登录、获取、更新用户信息等
 */
import Taro from '@tarojs/taro'
import route from '@/utils/route'
import { KEY } from './constants'
import api from './api'

export default {
  async login() {
    try {
      let res = await Taro.login()
      // 拿到微信授权code，去登录
      let resp = await api.handleLogin({
        code: res.code
      })
      // 拿到token，保存使用
      const token = resp.token
      this.setToken(token)
      return Promise.resolve(token)
    } catch (err) {
      return Promise.reject(err)
    }
  },

  updateUserInfo(userInfo) {
    //更新用户信息到后台
    api.updateUserInfo(userInfo).then((res) => {
      console.log(res, '更新用户信息成功')
    })
  },

  getToken() {
    if (window.location.href.includes('pages/tiktok')) {
      return Taro.getStorageSync('uchoice_token')
    }
    return Taro.getStorageSync(KEY.TOKEN)
  },

  setToken(token) {
    if (window.location.href.includes('pages/tiktok')) {
      Taro.setStorageSync('uchoice_token', token)
      return
    }
    Taro.setStorageSync(KEY.TOKEN, token)
  },

  onlyRemoveToken() {
    console.log('removeToken')
    Taro.removeStorageSync(KEY.TOKEN)
    Taro.removeStorageSync('userInfo')

    if (window.location.href.indexOf('pages/login/login') == -1) {
      localStorage.setItem('redirect_after_login', route.currentUrlWithLanguage())
    }
  },

  removeToken(isPush) {
    console.log('removeToken', isPush)
    Taro.removeStorageSync(KEY.TOKEN)
    Taro.removeStorageSync('userInfo')

    if (
      !window.location.href.includes('pages/login/login') &&
      window.location.href.includes('pages/tiktok')
    ) {
      localStorage.setItem('from_uchoice_tiktok', 1)
    } else {
      localStorage.setItem('from_uchoice_tiktok', 0)
    }

    if (!window.location.href.includes('pages/login/login')) {
      localStorage.setItem('redirect_after_login', route.currentUrlWithLanguage())

      if (window.location.href.includes('pages/tiktok')) {
        route.navigateToTiktokLogin()
      } else {
        route.navigateToLogin()
      }
    }
  }
}
