import http from '../http'
import mergeBaseUrl from '../baseUrl'

export interface ReportAnIncidentParams {
  traceId?: string
  eventName?: string
  param?: object
  path?: string
  eventType?: number
}


// 上报事件
export const reportAnIncident = (data: ReportAnIncidentParams) => {
  const truthyData = {
    ...data,
    param: data.param ? JSON.stringify(data.param) : undefined
  }

  return http.post(`api-member/toonMember/reportAnIncident`, truthyData)
}

export const sendBeaconForReportAnIncident = (body: any) => {
  var url = mergeBaseUrl('api-member/toonMember/reportAnIncident')

  const headers = {
    type: 'application/json'
  }
  const blob = new Blob([JSON.stringify(body)], headers)
  navigator.sendBeacon(url, blob)
}

