import utils from '@/utils/utils'

const couponEnObj = {
    voucher: 'A ฿xx voucher for you!',
    click: 'Click',
    couponCollected: 'You have a ฿xx coupon to be collected.',
    couponUsed: 'You have a ฿xx coupon to be used.',
    afterReceiving:
        '1. After receiving the coupon code and placing an order, you can get coupons and rebates.',
    pleaseclick:
        '2. Please click the button below to jump to lazada before using the code to place an order.',
    pleaseuse: '3. Please use it within 3 days after collecting it.',
    donotsend:
        '4. Do not send the coupon code to others, otherwise you will not get the commission.',
    getthecoupon: 'Get the coupon code and shop in lazada',
    getToLazada: 'Go to shop on lazada',
    couponTime: 'Valid till Aug 31, 23: 59: 59',
    howtouse: 'How to use lazada coupons?',
    step1: 'Step 1',
    clickthe: 'Click the bottom button',
    step2: 'Step 2',
    whenyouplace: 'When you place an order, paste the coupon code in here.',
    被领取完:
        'The coupon codes for this campaign have been all collected now. Welcome to participate in it next time.',
    shoponLazada: 'Coupon successfully claimed, please shop on Lazada.',
    Minspend: 'Min.spend',
    LazadaVouchers: 'Lazada Vouchers',
    AllCategories: 'All Categories'
}

const couponThObj = {
    voucher: 'A voucher ฿xx สำหรับคุณ',
    click: 'คลิก',
    couponCollected: 'คุณมีคูปอง ฿xx ที่เก็บได้',
    couponUsed: 'คุณได้ใช้คูปอง ฿xx บาทไปแล้ว',
    afterReceiving: '1. หลังจากได้รับรหัสโค้ดและทำการสั่งซื้อแล้ว คุณจะได้รับคูปองและส่วนลด',
    pleaseclick: '2. กรุณาคลิกปุ่มด้านล่างเพื่อไปที่ lazada ก่อนที่จะใช้โค้ดในการสั่งซื้อ',
    pleaseuse: '3.เมื่อได้รับโค้ดแล้ว โปรดใช้โค้ดภายใน 3 วัน',
    donotsend: '4. อย่าส่งรหัสโค้ดให้ผู้อื่น ไม่เช่นนั้น คุณจะไม่ได้รับค่าคอมมิชชั่น',
    getthecoupon: 'รับโค้ดแล้วช้อปใน lazada เลย',
    getToLazada: 'ไปที่ลาซาด้า',
    couponTime: 'ใช้ได้กับ ส.ค. 31 23: 59: 59',
    howtouse: 'วิธีใช้โค้ด',
    step1: 'ขั้นที่ 1 ',
    clickthe: 'คลิกที่ปุ่มด้านล่าง',
    step2: 'ขั้นตอนที่ 2',
    whenyouplace: ' เมื่อคุณทำการสั่งซื้อ ให้ใส่รหัสโค้ดที่นี่',
    被领取完: 'รหัสโค้ดในแคมเปญนี้ถูกเก็บหมดแล้ว มาเก็บโค้ดในครั้งต่อไปนะ',
    shoponLazada: 'การเคลมคูปองสำเร็จ กรุณาช้อปที่ Lazada',
    Minspend: 'ช้อปขั้นต่ำ',
    LazadaVouchers: 'คูปอง Lazada',
    AllCategories: 'หมวดหมู่ทั้งหมด'
}

const couponViObj = {
    voucher: 'Một phiếu giảm giá xx₫ cho bạn!',
    click: 'Nhấp vào',
    couponCollected: 'Bạn có một phiếu giảm giá xx₫ để được thu thập.',
    couponUsed: 'Bạn có một phiếu giảm giá xx₫ để sử dụng.',
    afterReceiving:
        '1. Sau khi nhận được mã phiếu giảm giá và đặt hàng, bạn có thể nhận được phiếu giảm giá và tiền hoàn lại.',
    pleaseclick:
        '2. Vui lòng nhấp vào nút bên dưới để nhảy đến lazada trước khi sử dụng mã để đặt hàng.',
    pleaseuse: '3. Vui lòng sử dụng trong vòng 3 ngày sau khi thu thập nó.',
    donotsend:
        '4. Không gửi mã phiếu giảm giá cho người khác, nếu không bạn sẽ không nhận được hoa hồng.',
    getthecoupon: 'Nhận mã phiếu giảm giá và mua sắm trên lazada',
    getToLazada: 'Đi mua sắm trên lazada',
    couponTime: 'Có hiệu lực đến ngày 15 tháng 6, 23: 59: 59',
    howtouse: 'Làm thế nào để sử dụng phiếu giảm giá lazada?',
    step1: 'Bước 1',
    clickthe: 'Nhấp vào nút bên dưới',
    step2: 'Bước 2',
    whenyouplace: 'Khi bạn đặt hàng, hãy dán mã phiếu giảm giá vào đây.',
    被领取完:
        'Các mã phiếu giảm giá cho chiến dịch này đã được thu thập hết. Chào mừng bạn tham gia vào lần sau.',
    shoponLazada: 'Phiếu giảm giá đã được nhận thành công, vui lòng mua sắm trên Lazada.',
    Minspend: 'Chi tiêu tối thiểu',
    LazadaVouchers: 'Phiếu giảm giá Lazada',
    AllCategories: 'Tất cả các danh mục'
};


export const couponEn = utils.localeFormat(couponEnObj, 'Coupon')
export const couponTh = utils.localeFormat(couponThObj, 'Coupon')
export const couponVi = utils.localeFormat(couponViObj, 'Coupon')