import Taro from '@tarojs/taro'
import api from '@/api/api'
import i18n from 'taro-i18n'
import platform from '@/utils/platform'
import route from '@/utils/route'
import env from '../../env'

const auth = (args = {}) => {
  const { reAuth, toLogin } = args

  /**
   * 防止跨站请求伪造
   * The state is used to maintain the state of your request and callback. This value will be included when redirecting the user back to the client. Check if the state returned in the callback matches what you sent earlier to prevent cross-site request forgery.
   */
  const tiktok_csrf_state = `${Math.random().toString(36).substring(2)}_for_${
    toLogin ? 'login' : 'auth'
  }`
  localStorage.setItem('tiktok_csrf_state', tiktok_csrf_state)

  // 如果是重新授权，就不要记录href
  if (!reAuth) {
    localStorage.setItem('redirect_after_tiktok_auth', window.location.href)
  }

  let redirect_uri = `${window.location.origin}/pages/tiktok/authorize/authing`

  // 本地调试的时候
  if (window.location.hostname.includes('192.168.31.226')) {
    redirect_uri = `https://test-h5.youpik.com/pages/tiktok/authorize/debug`
  }
  if (window.location.hostname.includes('192.168.31.167')) {
    redirect_uri = `https://test-h5.youpik.com/pages/tiktok/authorize/debug/hayley`
  }

  // 把路由参数先缓存到本地，在授权回调的时候，取回来
  localStorage.setItem('url_core_params_before_tiktok_auth', route.getUrlCoreQueryParams())

  // 新的生产
  let client_key = 'aw2h73yv0m7agdgk'

  // 旧的生产
  if (window.location.host.includes('product.youpik.com')) {
    client_key = 'awcv3k5whn6n5qef'
  }

  if (['dev', 'test'].includes(env)) {
    client_key = 'awmghiwf14g9bg0c'
  }
  window.location.href = `https://www.tiktok.com/v2/auth/authorize?client_key=${client_key}&response_type=code&scope=user.info.basic%2Cvideo.list%2Cvideo.upload%2Cuser.info.profile%2Cuser.info.stats&state=${tiktok_csrf_state}&redirect_uri=${redirect_uri}`
}

const reAuth = () => {
  auth({ reAuth: true })
}

const authAndLogin = () => {
  auth({ toLogin: true })
}

//访客必须登录才能复制链接的处理
const copyLink = (link) => {
  if (platform() === 'ios') {
    if (localStorage.getItem('tiktok_can_copy_link') == 1) {
      Taro.setClipboardData({
        data: link,
        success: function () {
          Taro.showToast({
            title: i18n.t._('copy_success')
          })
        }
      })
    } else {
      window.tiktokAuthModalShow()
    }
  } else {
    Taro.showLoading()
    api
      .isCanCopyLink()
      .then((res) => {
        Taro.hideLoading()

        if (res.code === 200 && res.result) {
          Taro.setClipboardData({
            data: link,
            success: function () {
              Taro.showToast({
                title: i18n.t._('copy_success')
              })
            }
          })
        } else {
          window.tiktokAuthModalShow()
        }
      })
      .catch(() => {
        Taro.hideLoading()
        window.tiktokAuthModalShow()
      })
  }
}

// ios在异步里复制不成功，所以想了这个办法
const checkIsCanCopyLink = () => {
  if (platform() === 'android') {
    return
  }

  api
    .isCanCopyLink()
    .then((res) => {
      if (res.code === 200 && res.result) {
        localStorage.setItem('tiktok_can_copy_link', '1')
      } else {
        localStorage.setItem('tiktok_can_copy_link', '0')
      }
    })
    .catch(() => {
      localStorage.setItem('tiktok_can_copy_link', '0')
    })
}

export { auth, reAuth, authAndLogin, copyLink, checkIsCanCopyLink }
