import utils from '@/utils/utils'

const replaceFun = (str) => {
  var replacedStr = str.replace(/\{\{([^}]+)\}\}/g, `<span style="color: red;">$1</span>`)
  return replacedStr
}

const toolsHomeEnObj = {
  使用模板: 'Template',
  帮助: 'Guide',
  预览: 'Preview',
  发布: 'Publish',
  个人网址设置: 'Customize link name',
  全局设置: 'Block theme Settings',
  重新编辑: 'Re-edit',
  'No block': 'No block',
  'No block has been added yet': 'No block has been added yet',
  'Add block': 'Add block',
  '添加模块后再发布！': 'Please add blocks before publishing!',
  '您目前没有编辑内容，发布后粉丝点击看到的是空白，建议您添加模块并编辑模块后，再发布':
    'You currently have no edited content. After publishing, fans will see a blank page. It is recommended to add and edit blocks before publishing.',
  去添加模块: 'Go to add blocks',
  选择模块类型: 'Select Block Type',
  Bio: 'Bio',
  '用于展示你的头像，个人简介以及社交媒体链接':
    'Show your profile picture, bio name, bio text and your social links.',
  '图片/图库': 'Image Gallery',
  '将单张/多张图片添加到您的网站': 'Add single/multiple images to your bio page.',
  'Lazada Affiliate': 'Lazada Affiliate',
  推荐联盟商品给粉丝: 'Recommend affiliate products to your fans.',
  捐款: 'Tip from fans',
  收到粉丝的打赏: 'Receive tips from your fans.',
  '您已成功发布个人网站 可通过以下网址访问':
    'You have successfully published your site.You can access it through the following url.',
  'Your site url': 'our site url',
  去自定义网址: 'Go to customize the site',
  浏览器查看网址: 'View site in browser',
  分享: 'Share',
  产品标题: 'Product block title',
  产品内容描述: 'Product block description',
  根据产品名称搜索: 'Search product by name…',
  '确定删除该模块？': 'Comfirm to delete this block?',
  '确定删除？': 'Confirm delete?',
  '确定删除该类目及该类目下的所有商品？':
    'Comfirm to delete this product group and all products under it?',
  '你的此次所有修改将不会被保存。你确定重新编辑吗？':
    'Your current changes will not be saved. Are you sure you want to re-edit?',
  该名字已有人使用: 'The user name is already used by someone else.',
  用户名格式错误: `Username only contains English or Thai characters, numbers, "-" and "_". Username cannot start and end with "-" and "_"`,
  'Welcome to Youpik.show !': 'Welcome to Youpik.show !',
  'What’s Youpik.show ?': `What's Youpik.show ?`,
  'User Manual': 'User Manual',
  'Create Now': 'Create Now',
  'Let’s get started !': `Let's get started !`,
  'Start with one of our designer - approved templates or start from scratch.': `Start with one of our designer - approved templates or start from scratch. You can customize or change your template at any time ! 
  Excited to see what you cook up😄. `,
  Continue: 'Continue',
  'Almost done !': 'Almost done !',
  'Customize the link name, and you will have a special link name for yourself on the Internet world.':
    'Customize the link name, and you will have a special link name for yourself on the Internet world.',
  'Please enter': 'Please enter',
  '(Suggestions: Many creators would like to use their English Nick name as the link name.)':
    '(Suggestions: Many creators would like to use their English Nick name as the link name.)',
  Done: 'Done',
  'Use the link via system generated': 'Use the link via system generated',
  Video: 'Video',
  Manual: 'Manual',
  Link: 'Link',
  Template: 'Template',
  Cases: 'Cases',
  Settings: 'Settings',
  'Choose the template you want!': 'Choose the template you want!',
  'The design of selected template will be applied to the entire site':
    'The design of selected template will be applied to the entire site',
  'Successful Cases': 'Successful Cases',
  'Awesome! Your bio link is live!': 'Awesome! Your bio link is live!',
  'Add it to your Facebook profile, Instagram bio, TikTok profile, Twitter, etc.you can put it in anywhere your fans or audience are.':
    'Add it to your Facebook profile, Instagram bio, TikTok profile, Twitter, etc. You can put it in anywhere your fans or audience are.',
  知道了: 'Got it.',
  'If you don’t want to use the link generated by the system above': `If you don't want to use the link generated by the system above, you can click the button below to customize your special link name.`,
  去自定义域名: 'Go to customize the link time',
  稍后再说: 'Do it later',
  'Youpik.show是一款帮助创作者建立特殊链接的工具': `Youpik.show is a tool that help creators to build special link, so that creators'fans can see the creators'various social media profile. Increase your fans and incomes`,
  'Youpik.show能为你提供什么？': 'What Youpik.show can provide for you? ',
  'Youpik.show的成功案例': 'Successful cases about Youpik.show',
  'Here are some cases from users about Youpik.show.':
    'Here are some cases from users about Youpik.show.',
  'success case1': `1. <span style="font-weight: bold;">A***a:</span> Youpik.show let my fans know my profile from other socials and increased my fans for other social media platform. It's really useful. `,
  'success case2':
    '2. <span style="font-weight: bold;">M***d:</span> With Youpik.show, I can import the high commission products with a single click and share it to my fans, after my fans placed orders and orders were completed, I can get  commissions and withdraw them right away. It increased my income a lot.',
  'success case3': `3. <span style="font-weight: bold;">J***n:</span> It's really a amazing tool. I often recommend some useful Lazada products to my fans. I can put the recommended Lazada products in the youpik.show link so that my audience can click them and jump to Lazada APP directly. Really convenient. 😁`,
  'success case4': `4. <span style="font-weight: bold;">S***a:</span> It's easy to use, and kind for new users, just follow the steps and then create the link quickly. Then I can share the link with others. They can know my various social media profile. `,
  good1: replaceFun(
    '1. {{Open up}} your links on various social media platforms and increase followers for your various social media platforms.'
  ),
  good2: replaceFun(
    '2. After adding products to wishlist, you can import products from your wishlist to your bio link with {{one single}} click to improve operational efficiency.'
  ),
  good3: replaceFun(
    '3. Provide {{high-commission}} products, and will receive {{commissions}} after fans place orders. And with the function for fans giving reward, these two ways will {{increase your income}} together.'
  ),
  good4: replaceFun('4. Unique {{creative template}} design to improve your content'),
  good5: replaceFun(
    '5. After registering for all the above functions, you can use {{all functions for free}}, no subscription and recharge are required.'
  )
}

const toolsHomeThObj = {
  使用模板: 'เทมเพลต',
  帮助: 'คู่มือ',
  预览: 'ตัวอย่าง',
  发布: 'เผยแพร่',
  个人网址设置: 'กำหนดชื่อลิงก์',
  全局设置: 'การตั้งค่ารูปแบบเพจ',
  重新编辑: 'แก้ไขอีกครั้ง',
  'No block': 'ไม่มีบล็อก',
  'No block has been added yet': 'ยังไม่มีการเพิ่มบล็อกใด ๆ ในขณะนี้',
  'Add block': 'เพิ่มบล็อก',
  '添加模块后再发布！': 'โปรดเพิ่มบล็อกก่อนการเผยแพร่!',
  '您目前没有编辑内容，发布后粉丝点击看到的是空白，建议您添加模块并编辑模块后，再发布':
    'ขณะนี้คุณยังไม่มีเนื้อหาที่แก้ไข หลังจากเผยแพร่แล้ว ผู้ติดตามของคุณจะเห็นหน้าเปล่า แนะนำให้เพิ่มและแก้ไขบล็อกก่อนการเผยแพร่',
  去添加模块: 'เพิ่มบล็อกของคุณ',
  选择模块类型: 'เลือกประเภทบล็อก',
  Bio: 'Bio',
  '用于展示你的头像，个人简介以及社交媒体链接':
    'ใช้สำหรับแสดงรูปโปรไฟล์ ชื่อ bio และลิงก์โซเชียลของคุณ',
  '图片/图库': 'คลังรูปภาพ',
  '将单张/多张图片添加到您的网站': 'เพิ่มรูปภาพเข้าเว็บไซต์ของคุณ',
  'Lazada Affiliate': 'Lazada Affiliate',
  推荐联盟商品给粉丝: 'แนะนำสินค้า Affiliate ให้กับผู้ติดตามของคุณ',
  捐款: 'รางวัลจากผู้ติดตามของคุณ',
  收到粉丝的打赏: 'ได้รับรางวัลจากผู้ติดตามของคุณ',
  '您已成功发布个人网站 可通过以下网址访问':
    'คุณได้เผยแพร่เพจของคุณเรียบร้อยแล้ว สามารถเข้าได้ทาง URL',
  'Your site url': 'URL ของคุณ',
  去自定义网址: 'ปรับแต่งเพจ',
  浏览器查看网址: 'ดูเว็บไซต์ในเบราว์เซอร์',
  分享: 'แชร์',
  产品标题: 'หัวข้อ',
  产品内容描述: 'คำบรรยาย',
  根据产品名称搜索: 'ค้นหาชื่อสินค้า',
  '确定删除该模块？': 'ยืนยันการลบบล็อกนี้หรือไม่',
  '确定删除？': 'ยืนยันการลบใช่หรือไม่',
  '确定删除该类目及该类目下的所有商品？': 'ยืนยันการลบกลุ่มสินค้านี้หรือไม่',
  '你的此次所有修改将不会被保存。你确定重新编辑吗？':
    'การเปลี่ยนแปลงปัจจุบันของคุณจะไม่ถูกบันทึก คุณแน่ใจหรือไม่ว่าต้องการแก้ไขอีกครั้ง',
  该名字已有人使用: 'ชื่อผู้ใช้นี้ถูกใช้แล้ว',
  用户名格式错误: `ชื่อผู้ใช้ประกอบด้วยภาษาอังกฤษหรือภาษาไทย ตัวเลข เครื่องหมาย "-" หรือ "_" แต่ไม่สามารถเริ่มและลงท้ายด้วยสองเครื่องหมายนี้ `,
  'Welcome to Youpik.show !': 'ยินดีต้อนรับสู่ Youpik.show !',
  'What’s Youpik.show ?': 'Youpik.show คืออะไร ?',
  'User Manual': 'คู่มือการใช้',
  'Create Now': 'สร้างบัญชี',
  'Let’s get started !': 'เริ่มเลย !',
  'Start with one of our designer - approved templates or start from scratch.': `เริ่มต้นด้วยเทมเพลตที่ได้รับการคัดเลือกจากดีไซเนอร์ของเรา หรือเริ่มต้นใหม่ทั้งหมด คุณสามารถปรับแต่งหรือเปลี่ยนเทมเพลตของคุณได้ทุกเมื่อ!
  ตื่นเต้นที่จะได้เห็นเทมเพลตคุณสร้างแล้ว😄`,
  Continue: 'ดำเนินการต่อ',
  'Almost done !': 'เกือบเสร็จแล้ว !',
  'Customize the link name, and you will have a special link name for yourself on the Internet world.':
    'สร้างชื่อลิงก์ และคุณจะมีชื่อลิงก์สำหรับคุณโดยเฉพาะในโลกอินเตอร์เน็ต',
  'Please enter': 'กรุณากดเข้า',
  '(Suggestions: Many creators would like to use their English Nick name as the link name.)':
    '(คำแนะนำ: ผู้สร้างจำนวนมากใช้ชื่อเล่นภาษาอังกฤษเป็นชื่อลิงก์)',
  Done: 'เสร็จเรียบร้อย',
  'Use the link via system generated': 'ใช้ลิงก์ผ่านระบบที่สร้างขึ้น',
  Video: 'คู่มือ',
  Manual: 'เริ่มเลย !',
  Link: 'ลิงก์',
  Template: 'เทมเพลต',
  Cases: 'เคสต่างๆ',
  Settings: 'การตั้งค่า',
  'Choose the template you want!': 'เลือกเทมเพลตที่คุณต้องการ!',
  'The design of selected template will be applied to the entire site':
    'รูปแบบของเพจจะเปลี่ยนตามเทมแพลตที่เลือก',
  'Successful Cases': 'เคสที่ประสบความสำเร็จ',
  'Awesome! Your bio link is live!': 'เยี่ยมไปเลย! Bio Link ของคุณพร้อมใช้งานแล้ว!',
  'Add it to your Facebook profile, Instagram bio, TikTok profile, Twitter, etc.you can put it in anywhere your fans or audience are.':
    'เพิ่มโปรไฟล์ Facebook, Instagram, TikTok, Twitter ฯลฯ คุณสามารถใส่ไว้ที่ใดก็ได้ที่มีผู้ติดตามของคุณ',
  知道了: 'เข้าใจแล้ว',
  'If you don’t want to use the link generated by the system above':
    'หากคุณไม่ต้องการใช้ลิงก์ที่สร้างโดยระบบด้านบน คุณสามารถคลิกปุ่มด้านล่างเพื่อปรับแต่งชื่อลิงก์ของคุณโดยเฉพาะได้',
  去自定义域名: 'ปรับแต่งเวลาของลิงก์',
  稍后再说: 'ไว้ทำภายหลัง',
  'Youpik.show是一款帮助创作者建立特殊链接的工具':
    'Youpik.show เป็นเครื่องมือที่ช่วยให้ครีเอเตอร์สร้างลิงก์พิเศษ ให้ผู้ติดตามสามารถดูโปรไฟล์โซเชียลมีเดียต่างๆ ของครีเอเตอร์ได้ ซึ่งนั่นจะช่วยเพิ่มผู้ติดตามและรายได้ของคุณ',
  'Youpik.show能为你提供什么？': 'Youpik.show จะมอบอะไรให้กับคุณ?',
  'Youpik.show的成功案例': 'เคสที่ประสบความสำเร็จของ Youpik.show',
  'Here are some cases from users about Youpik.show.':
    'และนี่คือตัวอย่างจากผู้ใช้ Youpik.show บางส่วน',
  'success case1':
    '1. <span style="font-weight: bold;">A***a:</span> Youpik.show ทำให้ผู้ติดตามของฉันใน Instagram รู้จักโปรไฟล์โซเชียลมีเดียอื่นๆของฉันและเพิ่มผู้ติดตามของฉันบนแพลตฟอร์มโซเชียลมีเดียอื่นๆด้วย มันมีประโยชน์มากจริงๆ',
  'success case2':
    '2. <span style="font-weight: bold;">M***d:</span> Youpik.show ช่วยให้ฉันสามารถแชร์ผลิตภัณฑ์ค่าคอมมิชชั่นสูงให้กับผู้ติดตามได้ภายในคลิกเดียว หลังจากที่ผู้ติดตามของฉันเลือกและสั่งซื้อสำเร็จ ฉันก็จะได้รับค่าคอมมิชชั่นและถอนออกได้ทันที และนั่นทำให้รายได้ของฉันเพิ่มมากขึ้น',
  'success case3':
    '3. <span style="font-weight: bold;">J***n:</span> มันเป็นเครื่องมือที่น่าทึ่งมาก ฉันมักจะแนะนำสินค้าที่มีประโยชน์ของ Lazada ให้กับผู้ติดตามของฉันอยู่เสมอ ฉันสามารถใส่สินค้าจากลาซาด้าที่อยากแนะนำลงในลิงก์ Youpik.show เพื่อให้ผู้ติดตามของฉันสามารถคลิกที่สินค้าและเข้าไปที่ Lazada ได้โดยตรง สะดวกจริงๆ 😁',
  'success case4':
    '4. <span style="font-weight: bold;">S***a:</span> ใช้งานง่ายและสะดวกกับผู้ใช้ใหม่ เพียงทำตามขั้นตอนแล้วสร้างลิงก์อย่างรวดเร็ว จากนั้นฉันก็สามารถแชร์ลิงก์กับผู้อื่นได้เลย ทำให้พวกเขาเห็นโปรไฟล์โซเชียลมีเดียต่างๆ ของฉันได้',
  good1: replaceFun(
    '1. {{เปิดลิงก์}}ของคุณบนแพลตฟอร์มโซเชียลมีเดียต่างๆ และเพิ่มผู้ติดตามสำหรับแพลตฟอร์มโซเชียลมีเดียต่างๆ ของคุณ'
  ),
  good2: replaceFun(
    '2. หลังจากที่เพิ่มสินค้าลงในลิสแล้ว คุณสามารถเพิ่มสินค้าจากลิสไปยัง Bio Link ได้ด้วยการ{{คลิกเพียงครั้งเดียว}}เพื่อเพิ่มประสิทธิภาพในการดำเนินงาน'
  ),
  good3: replaceFun(
    '3. จัดหาสินค้าที่มี{{ค่าคอมมิชชั่นสูง}}และจะได้รับค่า{{คอมมิชชั่น}}หลังจากที่ผู้ติดตามสั่งซื้อ และฟังก์ชั่นที่ให้ผู้ติดตามสามารถให้รางวัลแก่คุณได้ ซึ่งทั้งสองช่องทางนี้จะ{{เพิ่มรายได้}}ให้กับคุณ'
  ),
  good4: replaceFun('4. มีรูปแบบของ{{เทมเพลตที่สร้างสรรค์}}เพื่อใช้พัฒนาคอนเทนต์ของคุณ'),
  good5: replaceFun(
    '5. หลังจากลงทะเบียนฟังก์ชั่นข้างต้นทั้งหมดแล้ว คุณจะสามารถ {{ใช้ฟังก์ชั่นทั้งหมดได้ฟรี}} โดยไม่จำเป็นต้องสมัครสมาชิกหรือเติมเงิน'
  )
}

export const toolsHomeViObj = {
  使用模板: 'Mẫu',
  帮助: 'Hướng dẫn',
  预览: 'Xem trước',
  发布: 'Xuất bản',
  个人网址设置: 'Tùy chỉnh liên kết',
  全局设置: 'Cài đặt chủ đề khối',
  重新编辑: 'Chỉnh sửa lại',
  'No block': 'Không có khối',
  'No block has been added yet': 'Chưa có khối nào được thêm',
  'Add block': 'Thêm khối',
  '添加模块后再发布！': 'Vui lòng thêm khối trước khi xuất bản!',
  '您目前没有编辑内容，发布后粉丝点击看到的是空白，建议您添加模块并编辑模块后，再发布':
    'Hiện tại bạn chưa có nội dung nào được chỉnh sửa. Sau khi xuất bản, người hâm mộ sẽ thấy một trang trống. Nên thêm và chỉnh sửa các khối trước khi xuất bản.',
  去添加模块: 'Đi tới thêm khối',
  选择模块类型: 'Chọn loại khối',
  Bio: 'Tiểu sử',
  '用于展示你的头像，个人简介以及社交媒体链接':
    'Hiển thị ảnh hồ sơ, tên tiểu sử, văn bản tiểu sử và liên kết xã hội của bạn.',
  '图片/图库': 'Thư viện hình ảnh',
  '将单张/多张图片添加到您的网站': 'Thêm một hoặc nhiều hình ảnh vào trang tiểu sử của bạn.',
  'Lazada Affiliate': 'Lazada Affiliate',
  推荐联盟商品给粉丝: 'Giới thiệu sản phẩm liên kết đến người hâm mộ của bạn.',
  捐款: 'Ủng hộ từ người hâm mộ',
  收到粉丝的打赏: 'Nhận ủng hộ từ người hâm mộ.',
  '您已成功发布个人网站 可通过以下网址访问':
    'Bạn đã xuất bản trang web cá nhân thành công. Bạn có thể truy cập nó qua URL sau.',
  'Your site url': 'URL trang web của bạn',
  去自定义网址: 'Đi tới tùy chỉnh liên kết',
  浏览器查看网址: 'Xem trang web trên trình duyệt',
  分享: 'Chia sẻ',
  产品标题: 'Tiêu đề khối sản phẩm',
  产品内容描述: 'Mô tả khối sản phẩm',
  根据产品名称搜索: 'Tìm sản phẩm theo tên…',
  '确定删除该模块？': 'Xác nhận xóa khối này?',
  '确定删除？': 'Xác nhận xóa?',
  '确定删除该类目及该类目下的所有商品？':
    'Xác nhận xóa nhóm sản phẩm này và tất cả các sản phẩm bên dưới nó?',
  '你的此次所有修改将不会被保存。你确定重新编辑吗？':
    'Tất cả các thay đổi hiện tại của bạn sẽ không được lưu. Bạn có chắc chắn muốn chỉnh sửa lại không?',
  该名字已有人使用: 'Tên người dùng đã được sử dụng bởi người khác.',
  用户名格式错误: `Tên người dùng chỉ chứa ký tự tiếng Anh hoặc Thái, số, "-" và "_". Tên người dùng không được bắt đầu và kết thúc bằng "-" và "_"`,
  'Welcome to Youpik.show !': 'Chào mừng đến với Youpik.show!',
  'What’s Youpik.show ?': `Youpik.show là gì?`,
  'User Manual': 'Hướng dẫn sử dụng',
  'Create Now': 'Tạo ngay',
  'Let’s get started !': `Hãy bắt đầu nào!`,
  'Start with one of our designer - approved templates or start from scratch.': `Bắt đầu với một trong những mẫu được phê duyệt của chúng tôi hoặc bắt đầu từ đầu. Bạn có thể tùy chỉnh hoặc thay đổi mẫu bất cứ lúc nào! Mong chờ xem bạn tạo ra gì 😄.`,
  Continue: 'Tiếp tục',
  'Almost done !': 'Gần xong rồi!',
  'Customize the link name, and you will have a special link name for yourself on the Internet world.':
    'Tùy chỉnh tên liên kết, và bạn sẽ có một tên liên kết đặc biệt cho riêng mình trên thế giới Internet.',
  'Please enter': 'Vui lòng nhập',
  '(Suggestions: Many creators would like to use their English Nick name as the link name.)':
    '(Gợi ý: Nhiều người sáng tạo muốn sử dụng biệt danh tiếng Anh của họ làm tên liên kết.)',
  Done: 'Xong',
  'Use the link via system generated': 'Sử dụng liên kết do hệ thống tạo',
  Video: 'Video',
  Manual: 'Hướng dẫn',
  Link: 'Liên kết',
  Template: 'Mẫu',
  Cases: 'Trường hợp',
  Settings: 'Cài đặt',
  'Choose the template you want!': 'Chọn mẫu bạn muốn!',
  'The design of selected template will be applied to the entire site':
    'Thiết kế của mẫu đã chọn sẽ được áp dụng cho toàn bộ trang web',
  'Successful Cases': 'Các trường hợp thành công',
  'Awesome! Your bio link is live!': 'Tuyệt vời! Liên kết tiểu sử của bạn đã hoạt động!',
  'Add it to your Facebook profile, Instagram bio, TikTok profile, Twitter, etc.you can put it in anywhere your fans or audience are.':
    'Thêm nó vào hồ sơ Facebook, tiểu sử Instagram, hồ sơ TikTok, Twitter, v.v. Bạn có thể đặt nó ở bất kỳ đâu mà người hâm mộ hoặc khán giả của bạn có thể thấy.',
  知道了: 'Đã hiểu.',
  'If you don’t want to use the link generated by the system above': `Nếu bạn không muốn sử dụng liên kết do hệ thống tạo ở trên, bạn có thể nhấp vào nút bên dưới để tùy chỉnh tên liên kết đặc biệt của bạn.`,
  去自定义域名: 'Đi tới tùy chỉnh tên miền',
  稍后再说: 'Làm sau',
  'Youpik.show是一款帮助创作者建立特殊链接的工具': `Youpik.show là một công cụ giúp các nhà sáng tạo xây dựng liên kết đặc biệt, để người hâm mộ của các nhà sáng tạo có thể thấy hồ sơ xã hội của họ. Tăng số lượng người hâm mộ và thu nhập của bạn.`,
  'Youpik.show能为你提供什么？': 'Youpik.show có thể cung cấp cho bạn những gì?',
  'Youpik.show的成功案例': 'Các trường hợp thành công của Youpik.show',
  'Here are some cases from users about Youpik.show.':
    'Dưới đây là một số trường hợp từ người dùng về Youpik.show.',
  'success case1': `1. <span style="font-weight: bold;">A***a:</span> Youpik.show giúp người hâm mộ của tôi biết hồ sơ của tôi từ các mạng xã hội khác và tăng số lượng người hâm mộ cho các nền tảng mạng xã hội khác. Nó thực sự hữu ích.`,
  'success case2':
    '2. <span style="font-weight: bold;">M***d:</span> Với Youpik.show, tôi có thể nhập các sản phẩm có hoa hồng cao chỉ với một cú nhấp chuột và chia sẻ nó với người hâm mộ của tôi. Sau khi người hâm mộ của tôi đặt hàng và đơn hàng được hoàn tất, tôi có thể nhận hoa hồng và rút ngay lập tức. Điều này đã tăng thu nhập của tôi rất nhiều.',
  'success case3': `3. <span style="font-weight: bold;">J***n:</span> Đây thực sự là một công cụ tuyệt vời. Tôi thường giới thiệu một số sản phẩm hữu ích của Lazada đến người hâm mộ của tôi. Tôi có thể đặt các sản phẩm Lazada được giới thiệu trong liên kết youpik.show để khán giả của tôi có thể nhấp vào chúng và chuyển đến ứng dụng Lazada ngay lập tức. Thực sự tiện lợi. 😁`,
  'success case4': `4. <span style="font-weight: bold;">S***a:</span> Dễ sử dụng và thân thiện với người dùng mới, chỉ cần làm theo các bước và sau đó tạo liên kết nhanh chóng. Sau đó, tôi có thể chia sẻ liên kết với người khác. Họ có thể biết hồ sơ mạng xã hội của tôi.`,
  good1: replaceFun(
    '1. {{Mở}} liên kết của bạn trên nhiều nền tảng mạng xã hội và tăng số lượng người theo dõi cho các nền tảng mạng xã hội khác nhau.'
  ),
  good2: replaceFun(
    '2. Sau khi thêm sản phẩm vào danh sách yêu thích, bạn có thể nhập các sản phẩm từ danh sách yêu thích của bạn vào liên kết tiểu sử của bạn với {{một cú nhấp chuột}} để cải thiện hiệu quả hoạt động.'
  ),
  good3: replaceFun(
    '3. Cung cấp {{sản phẩm có hoa hồng cao}}, và sẽ nhận {{hoa hồng}} sau khi người hâm mộ đặt hàng. Và với chức năng thưởng cho người hâm mộ, hai cách này sẽ {{tăng thu nhập của bạn}} cùng nhau.'
  ),
  good4: replaceFun('4. Thiết kế {{mẫu sáng tạo}} độc đáo để cải thiện nội dung của bạn'),
  good5: replaceFun(
    '5. Sau khi đăng ký tất cả các chức năng trên, bạn có thể sử dụng {{tất cả các chức năng miễn phí}}, không yêu cầu đăng ký và nạp tiền.'
  )
}


export const toolsHomeEn = utils.localeFormat(toolsHomeEnObj, 'toolsHome')
export const toolsHomeTh = utils.localeFormat(toolsHomeThObj, 'toolsHome')
export const toolsHomeVi = utils.localeFormat(toolsHomeViObj, 'toolsHome')
