import { activityTh, activityEn, activityVi } from './lang/activity'
import { venueTh, venueEn, venueVi } from './lang/venue'
import { promptPayEn, promptPayTh, promptPayVi } from './lang/promptPay'
import { resultEn, resultTh, resultVi } from './lang/result'
import { orderEn, orderTh, orderVi } from './lang/order'
import { itemEn, itemTh, itemVi } from './lang/item'
import { loginEn, loginTh, loginVi } from './lang/login'
import { lazadaEn, lazadaTh, lazadaVi } from './lang/lazada'
import { tiktokEn, tiktokTh, tiktokVi } from './lang/tiktok'
import { rechargeEn, rechargeTh, rechargeVi } from './lang/recharge'
import { toolsGalEn, toolsGalTh, toolsGalVi } from './lang/toolsGal'
import { bypEn, bypTh, bypVi } from './lang/byp'
import { toolsHomeEn, toolsHomeTh, toolsHomeVi } from './lang/toolsHome'
import { toolsLazadaEn, toolsLazadaTh, toolsLazadaVi } from './lang/toolsLazada'
import { couponEn, couponTh, couponVi } from './lang/coupon'
import { toolsGlobalEn, toolsGlobalTh, toolsGlobalVi } from './lang/toolsGlobal'
import { toolsRevisionEn, toolsRevisionTh, toolsRevisionVi } from './lang/toolsRevision'
import { toolsCommonEn, toolsCommonTh, toolsCommonVi } from './lang/toolsCommon'
import { toolsTemplateEn, toolsTemplateTh, toolsTemplateVi } from './lang/toolsTemplate'
import { selectionEn, selectionTh, selectionVi } from './lang/selection'
import { distEn, distTh, distVi } from './lang/dist'
import { commonEn, commonTh, commonVi } from './lang/common'

export default function get() {
  return {
    // en 和 th是语言类型,可以新增语言或重命名
    en: {
      ...{
        'remote area': 'remote area',
        'Note The range of remote areas is provided by the logistics partner Flash':
          'Note: *The range of remote areas is provided by the logistics partner Flash',
        'Some goods and activities do not include free shipping in remote areas':
          'Some goods and activities do not include free shipping in remote areas.',
        search: 'Search',
        inviteCode_toast: 'Please fill in a valid invitation code',
        success: 'Success!',
        please_enter_account: 'please enter account',
        please_enter_password: 'please enter password',
        please_enter_mobile: 'please enter mobile',
        please_enter_code: 'please enter code',
        loading: ' ',
        password: 'Password',
        sms: 'SMS',
        account_number: 'Account Number',
        login: 'Login',
        mobile_number: 'Mobile Number',
        sms_code: 'SMS Code',
        code: 'Code',
        send: 'SEND',
        next: 'Next',
        forgot_password: 'Forgot Password ?',
        sign_up_now: 'Sign Up Now',
        for_you: 'For You',
        sales: 'Sales',
        earn: 'Earn',
        earn_l: 'Earn',
        price: 'Price',
        add_to_cart_to_shop_on: 'Add To Cart To Shop On',
        box_content: 'Box Content',
        item_id: 'ItemID',
        get_voucher: 'Get Voucher',
        collect: 'Collect',
        delivery_options: 'Delivery Options',
        delevery_service: 'Delivery Service',
        cash_on_delivery_available: 'Cash on Delivery available',
        delevery_fee: 'Delivery Fee',
        days: 'days',
        hi_my_firend_more:
          'Hi my friend, grab an exclusive voucher for your first order!get extra 30.- voucher in Youpik APP!',
        all_items_are_available: 'All items are available!',
        continue_with_facebook: 'Continue with Facebook',
        login_with_phone: 'Login with Phone',
        login_with_line: 'Login with Line',
        privacy_policy: 'Privacy Policy',
        change_language: 'เปลี่ยนภาษา',
        english: 'English',
        thai: 'Thai',
        country_vi: 'Vietnam',
        need_help: 'Need Help!',
        quantity: 'Quantity',
        buy_now: 'Buy Now',
        inviteCode: 'InviteCode',
        search_records: 'Search records',
        no_products_found: 'No products found',
        please_try_other_keywords: 'Please try other keywords',
        pick_for_you: 'Pick For You',
        add_address: 'Add address',
        edit: 'Edit',
        default_shipping_address: 'Default shipping address',
        default_billing_address: 'Default billing address',
        no_provice: 'no provice',
        no_district: 'no district',
        no_postcode: 'no postcode',
        no_address: 'no address',
        no_fullname: 'no fullname',
        no_phone: 'no phone',
        the_length_of_the_phone_number_cannot_be_less_than_9:
          'The length of the phone number cannot be less than 10',
        select_a_label_for_effective_delivery: 'Select a label for effective delivery',
        for_me: 'FOR ME',
        for_client: 'FOR CLIENT',
        province: 'Province',
        district: 'District',
        postCode: 'PostCode',
        make_default_shipping_address: 'Make default shipping address',
        make_default_billing_address: 'Make default billing address',
        save: 'Save',
        save_more: 'Save More',
        earn_more: 'Earn More',
        top_picks: 'Top Picks',
        seven_days_easy_return: 'seven days easy return',
        please_select: 'Please select',
        no_stock: 'Out of Stock',
        product_options: 'Product Options',
        delevery: 'Delivery',
        specifications: 'Specification',
        brand: 'Brand',
        products: 'Products',
        all_products: 'All Products',
        people_who_viewed_this_item_also_viewed: 'People Who Viewed This Item Also Viewed',
        youpiks_promise: "Youpik's Promise",
        highlights: 'Highlights',
        minishop_editors_choice: 'Minishop Editors Choice',
        please_enter_invitation_code: 'Please enter Invitation Code',
        wrong_invitation_code: 'Wrong invitation code',
        bind_success: 'bind success',
        is_this_your_inviter: 'Is this your inviter?',
        invitation_code: 'Invitation Code',
        cancel: 'Cancel',
        confirm: 'Confirm',
        welcome: 'WELCOME',
        you_are_invited_by_a_friend_to_join_youpik_community_please_enter_your_friend_invitation_code:
          'You are invited by a friend to join Youpik Community. Please enter your friend’s invitation code',
        no_invitation_from_a_friend_see: 'No invitation from a friend? See',
        default_code: 'Default Code',
        the_two_passwords_are_inconsistent: 'The two passwords are inconsistent',
        please_set_password: 'please set password',
        we_will_send_a_one_time_SMS_code_to_your_mobile_number:
          'We will send a one time SMS code to your Mobile Number',
        please_enter_your_new_password_below: 'Please enter your new password below',
        new_password: 'New Password',
        retype_password: 'Retype Password',
        verify_code: 'Verify Code',
        reset_password: 'Reset Password',
        to_pay: 'To Pay',
        to_ship: 'To Ship',
        to_receive: 'To Receive',
        to_review: 'To Review',
        hi_welcome_to_youpik: 'Hi,welcome to Youpik',
        signup: 'Signup',
        my_purchase_history: 'My Purchase History',
        view_all: 'View All',
        logout: 'Logout',
        no_select_item: 'no select item',
        no_select_reason: 'no select reason',
        please_select_reason: 'Please select reason',
        cancellation_reason: 'Cancellation Reason',
        detailed_explanation_of_selected_reason: 'Detailed Explanation of Selected Reason',
        submit: 'Submit',
        package_one_of_one: 'Package 1 of 1',
        shipped_by_seller_name: 'Shipped by Seller',
        choose_your_delivery_option: 'Choose your delivery option',
        promo_saved: 'Promo Saved',
        one_item_total: 'Item, Total',
        subtotal_one_item: 'Subtotal ',
        shipping_fee: 'Shipping Fee',
        shipping_fee_discount: 'Shipping Fee Discount',
        total: 'Total',
        GST_applied_where_applicable: 'GST Applied Where Applicable',
        place_order: 'Place Order',
        bought_by: 'Bought By',
        shipping_address: 'Shipping Address',
        billing_address: 'Billing Address',
        order_no: 'Order No',
        package: 'Package',
        subtotal: 'Subtotal',
        item: 'item',
        confirm_cancel: 'confirm cancel?',
        order: 'Order',
        placed_on: 'Placed on',
        items: 'Items',
        pay_now: 'Pay Now',
        pay_code_tip:
          'Lazada Payment Protection covers unauthorized transactions made using your Lazada account credentials(T&Cs apply)',
        recommended_method: 'Recommended methods',
        credit_debit_card: 'Credit/Debit Card',
        trueMoney_wallet: 'TrueMoney Wallet',
        payment_method: 'Payment method(s)',
        cash_on_delivery: 'Cash on Delivery',
        total_amount: 'Total Amount',
        please_wait: 'Please wait',
        you_can_pay_in_cash_to_our_courier_when_you_receive_the_goods_at_your_doorstep:
          'You can pay in cash to our courier when you receive the goods at your doorstep.',
        pay_with_your_trueMoney_account_make_sure_you_have_enough_balance_in_your_account:
          'Pay with your TrueMoney account. Make sure you have enough balance in your account',
        confirm_order: 'Confirm Order',
        save_money: 'Save Money',
        earn_money: 'Earn Money',
        no_capital: 'No Capital',
        effortless_earning: 'Effortless Earning',
        payment_success: 'Payment Success',
        your_order_number_is: 'Your order number is',
        payment_failed: 'Payment Failed',
        for_more_details_track_your_delivery_status_under:
          'For more details, track your delivery status under',
        my_account_my_order: 'My Account > My Order',
        view_order: 'View Order',
        we_ve_sent_a_confirmation_email_to: 'We’ve sent a confirmation email to',
        join_as_youpikker_and_start_to_earn_today: 'Join as Youpiker and start to earn today!',
        manage_selections: 'Manage Selections',
        special_for_you_shop_now: 'Special For You - Shop Now!',
        download_APP_to_get_30_30_vouchers: 'Download APP to get 30+30 vouchers',
        best_selling_products: 'Best Selling Products',
        enjoy_cash_back_when_you_buy: 'Enjoy cash back when you buy',
        earn_incentives_when_you_share_successfully: 'Earn incentives when you share successfully',
        more_benefits_await_as_you_grow_your_membership_level_from_basic_to_super_and_partner:
          'More benefits await as you grow your membership level from Basic, to Super and Partner',
        buy_vip_products: 'Buy VIP products',
        download_or_open_youpik_application: 'Download or open youpik application',
        start_sharing_and_earning_now: 'Start sharing and earning now!',
        vip_products: 'VIP Products',
        purchase_these_exclusively_curated_products_to_be_a_member_today:
          'Purchase these exclusively curated products to be a member today',
        discount_fee: 'Discount Fee',
        order_details: 'Order Details',
        receiver: 'Receiver',
        track_num: 'Tracking Number',
        delivery_partner: 'Delivery Partner',
        return_reason: 'Return Reason',
        reason: 'Reason',
        return_to: 'Return to',
        return_request_title: 'Return/Refund Policy',
        return_request_tx:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis',
        return_request_tx2:
          '1. Return/Refund decision will be based on comments & pictures you provide',
        return_request_tx3:
          '2. Print your Return Label/Form beforehand.Delivery company may not accept your return parcel without one',
        return_request_tx4:
          '3. "Change of Mind"return is only applicable for selected items.These items must be sealed',
        got_it: 'Got it',
        upload_image: 'Upload Image',
        by_submit: 'By submitting this form, I accept the Return/Refund Policy of Youpik.',
        total_refund: 'Total Refund',
        return_request_1: 'Return Request(1/2)',
        return_request_2: 'Return Request(2/2)',
        return_request2_title: 'Delivery Confirmation',
        return_request2_tx:
          'Return can only be requested once you received your item(s).Have you received selected item(s).',
        continue: 'Continue',
        change: 'Change',
        no_bank: 'Refund Via Bank Transfer',
        item_price: 'Item Price',
        expected_total: 'Expected Total Refund',
        bank_transfer: 'Bank Transfer',
        select_a_bank: 'Select a Bank',
        account_name: 'Account Name',
        account_name_pl: 'Enter your bank account name',
        account_number_pl: 'Eg.12344567890983',
        my_returens: 'Returns/Refunds',
        my_cancel: 'My Cancellations',
        account: 'Account',
        setting: 'Setting',
        returns: 'Returns',
        cancellations: 'Cancellations',
        store: 'Store',
        benefits: 'Benefits of becoming VIP',
        how_to_vip: 'How to become a VIP',
        order_list: 'Order List',
        all: 'All',
        return: 'RETURN',
        track_package: 'Track Package',
        cancel_request: 'Cancellation Request',
        order_confirm: 'Order Confirm',
        select_payment_method: 'Select Payment Method',
        order_received: 'Order Received',
        address_list: 'Address List',
        address_manage: 'Address Manage',
        full_name: 'Full Name',
        address: 'Address',
        phone_number: 'Phone Number',
        added: 'Added',
        add_to_my_shop: 'Add to My Shop',
        home: 'Home',
        my_account: 'My Account',
        my_orders: 'My Orders',
        set_password: 'Set Password',
        seven_days_easy_return2: '7 Days Easy Return',
        order_empty: 'There are no orders placed yet.',
        order_empty_btn: 'Continue Shopping',
        voucher_for_you: 'Voucher for you!',
        sorry: 'Sorry!',
        noItem_text: 'This product is no longer available now',
        back_to_homepage: 'Back to homepage',
        dps: 'dbs',
        standard_delivery2: 'Standard Delivery',
        days2: '(3-8 days)',
        days3: '(10-15 days)',
        dpsDays: 'Delivery by Seller',
        no_pdf_msg: 'This browser does not support PDF files, please click to view:',
        download_pdf: 'Download PDF',
        daily_deals: 'Daily Deals',
        more: 'More',
        sold: 'Sold',
        ProductID: 'Product ID',
        goods_sold_out: 'Goods sold out',
        start_in: 'Start in',
        end_in: 'End in',
        or_continue_with: 'or continue with',
        chat_now: 'Chat Now',
        packing_time: 'Packing Time',
        pay_time: 'Pay Time',
        ends_in: 'Ends in',
        left: 'left',
        sold_out: 'Sold out',
        app_title: 'Youpik Application',
        uchoice_title: 'uChoice Application',
        app_tx1: 'New Experience with Social Commerce',
        app_tx2: 'Shop to Save，Share to Earn',
        uchoice_tx1: 'TikTok Creator Product Selection Tool',
        app_tx3: 'Click to download and shop right away!',
        app_dowload: 'Download at',
        app_android: 'Android',
        app_appStore: 'App Store',
        app_tx4: `Please open this link with your phone's default browser and click the download button`,
        copy_success: 'Copy successfully!',
        notice: 'Notice',
        notice_msg:
          'เนื่องจากสถานการณ์การแพร่ระบาดของเชื้อโควิด19 ส่งผลให้เกิดความล่าช้าในการขนส่งสินค้าจำนวนมากในพื้นที่ดังกล่าว ต้องขออภัยในความไม่สะดวกเป็นอย่างยิ่ง หากคุณมีข้อสงสัยเพิ่มเติม สามารถติดต่อสอบถามมายังฝ่ายลูกค้าสัมพันธ์ของ Youpik ได้ผ่านแอปพลิเคชั่น โดยติดต่อผ่านทางแอปแชท เพียงกดไปที่หน้าบัญชี และ ข้อความ',
        no_stock_good: 'Non-stocked Goods',
        activity: 'Activity',
        activity_end_msg: "You're late. The event is over",
        exceeds_product_limit: 'Exceeds product limit',
        cross_border: 'Cross-border',
        cross_ship: 'Ship from oversea',
        share_to_earn: 'Share to Earn',
        end_time: 'Ending in',
        Real_time_leaderboar: 'Real Time Leaderboard',
        Campaign_leaderboard: 'Campaign Leaderboard',
        Estimated_rewards: 'Estimated Rewards',
        min_spend: 'Min.spend',
        valid_till: 'Valid Till',
        youpik_birthday: 'Youpik 2nd Birthday Celebration',
        acTab1: '11% Commission',
        acTab2: '111 Commission',
        acTab3: '1 Deal',
        acTab4: '111 Deal',
        acTab121: '12% Commission',
        acTab122: '112 Commission',
        acTab123: 'Spacial YPC 222',
        acTab124: '112.- Deal',
        acTab125: '12 Deal ',
        coming_soon: 'Coming Soon',
        ac_no_token: 'Please go to the settings page to upgrade to the latest version to receive',
        city: 'City',
        no_city: 'no city',
        for_shop: 'For shopping at Youpik',
        cancel_reason: 'Cancellation Reason',
        cancel_item: 'Cancelled Item',
        track_progress: 'Track Progress',
        cancel_info: 'Cancellation Information',
        order_num: 'Order number',
        request_on: 'Requested on',
        supplied_by: 'Supplied by',
        chat: 'Chat',
        refund_on: 'Refund on its way!',
        refunded: 'Refunded',
        refund_reject: 'Refunded rejected',
        refund_msg1:
          'Your refund request has been approved,please sit tight while our system is processing your refund.',
        refund_msg2: 'Your refund request finished',
        refund_msg3: 'If you have a inquiry, please contact customer service',
        refund_reason: 'Refund Reason',
        refund_time: 'Refund Time',
        refund_amount: 'Refund Amount',
        refund_method: 'Refund Method',
        related_product: 'Related Product',
        view_history: 'View Communication History',
        returned_on: 'Returned on',
        refund_on_its: 'Refund on its way',
        thank_you: 'Thank you!',
        for_our: 'For Our Cancellation',
        returns_refund: 'Returns/Refunds',
        sold_by_redmart: 'Sold by ',
        live_title1: 'What is Live Sales?',
        live_title2: 'How to use Live Sales',
        live_ms1:
          'Live Sales is feature for Youpikker that support promoting items in Youpik applicationthrough live streaming channel. Youpikker can create their own product list and connect Facebook live, then youpik system will automatically link event with Facebook.This feature will help Youpikker to easily get more commissions!',
        live_ms2: '1、Click Live Sales icon and create items list',
        live_ms3: '2、Select items (Maximum 200 items) and create item list',
        live_ms4: '3、Connect item list with Facebook live',
        live_ms5: '4、When successfully connect, Youpikker can start live streaming right away',
        cancelled: 'Cancelled',
        your: 'Your',
        item_has: 'item(s) has been cancelled',
        select: 'Select',
        order_has: 'Order has been cancelled,look at the others',
        home_page: 'Home Page',
        cancel_detail: 'Cancellation Detail',
        refund_detail: 'Refund detail',
        your_refund_ms:
          'Your refund request will be handle in 1-2 weekdays You can see the refund progress in Returns/Refund list',
        unpaid: 'Unpaid',
        refund: 'REFUND',
        number: 'Number',
        refund_type: 'Refund method',
        i_accept: 'I accept the youpik',
        refund_agree: 'return refund agreement',
        bank_name: 'Bank Name',
        bank_code: 'Bank Code',
        refund_isMsg: 'Actual payment amount at the time of order',
        progress1: 'Submit Application',
        progress2: 'Merchant Processing',
        progress3: 'Processing',
        progress4: 'Finish',
        flash_express: 'Flash Express',
        thailand_post: 'Thailand Post',
        scg_express: 'SCG EXPRESS',
        alpha_fast: 'Alpha Fast',
        lalamove: 'Lalamove',
        kerry_express: 'Kerry Express',
        line_man: 'Line Man',
        grab_express: 'Grab Express',
        tnt_express: 'TNT Express',
        ltd: 'I.T. TRANSPORT.CO.,LTD',
        niko: 'Niko’s Logistics',
        nim_express: 'Nim Express',
        dhl_express: 'DHL Express',
        ninjavan: 'ninjavan',
        skootar: 'Skootar',
        jt_express: 'J&T Express',
        speed_d: 'Speed - D',
        best_express: 'Best Express',
        applying: 'Refund Request',
        processing: 'Processing',
        processed: 'Finished',
        date: 'Date',
        you_can_before: 'You can apply for after-sales before',
        as_alone_as: 'As long as within 7 days after the sale, you can apply again',
        logistics_name: 'Logistics name',
        tracking_number: 'Track No.',
        please_enter_tracking_number: 'Please enter the tracking number',
        sender_phone: 'Sender Phone',
        please_enter: 'Please enter',
        courier_fees: 'Courier Fees',
        bank_msg1: 'Please provide the bank account information',
        bank_msg2: 'Only letters and space allowed',
        bank_msg3: 'Digits only，up to 15 digits',
        refund_failed: 'Refund Failed',
        refund_expired: 'Refund Expired',
        in_shipping_process: 'In shipping process',
        please_waiting_for_seller_operation: 'Please waiting for seller operation',
        please_return_item: 'Please return item',
        seller_confirm_receiving_item:
          'Seller confirm receiving item, refund will process within 7 days',
        please_wait_seller_confirm_receive_item_after_seller_confirm_you_will_get_the_refund:
          'Please wait seller confirm receive item, after seller confirm, you will get the refund',
        request_has_been_cancelled_contact_cs:
          'Request has been cancelled, please contact CS if any issues',
        you_after_sale:
          'Your after-sale application has been submitted successfully, and the merchant will complete the review within 7 days',
        please_push_the:
          'Please push the goods to the designated return address within 10 days, it is expected that the refund application will be closed',
        done: 'Done',
        seller_is_operating: 'Seller is operating',
        submit_request: 'Submit Request',
        return_address: 'Return Address',
        shipping_label: 'Shipping Label',
        shipping_bill: 'Shipping Bill',
        return_method: 'Return Method',
        select_shipping_company: 'Select shipping company',
        request_created_time: 'Request Created Time',
        estimated_payment_amount: 'Estimated payment amount',
        actual_payment_amount: 'Actual payment amount',
        after_the_merchant:
          'After the merchant agrees to return the goods, they will bear at most the freight',
        please_upload_ship_label: 'Please upload shipping label',
        please_upload_ship_bill: 'Please upload shipping bill',
        i_agree_that:
          'I agree that Youpik may collect,save and process my bank account information for the purpose of processing refund of payments',
        are_you_confirm: 'Are you confirming the submission?',
        yes: 'Yes',
        no: 'No',
        please_confirm: 'Please confirm',
        donot_edit: 'The return information cannot be modified after submission',
        mem_title: 'Upgrading Rules and Memberships',
        mem_small_title: 'Youpik Upgrading Rules and Memberships',
        mem_h3_1: '1. How to Upgrade',
        mem_h4_1_a_t: '(a) Upgrade to Basic Youpikker',
        mem_h4_1_a_t1: 'Customers can buy a Youpik Choice to upgrade as a Basic Youpikker。',
        mem_h4_1_a_t2:
          'Follow below to upgrade: Go back to Youpikker Page—>Find the products with a label of Youpik Choice—>Pick a Youpik Choice—>Buy Now',
        mem_h4_1_b_t: '(b) Upgrade to Super Youpikker',
        mem_h4_1_b_t1:
          'Customers should become a Basic Youpikker. Basic Youpikkers need to invite 20 customers to be Youpikkers in order to upgrade to Super Youpikker',
        mem_h4_1_b_t2: 'Follow below to upgrade:',
        mem_h4_1_b_t3:
          'Go back to Youpikker Page—>Find the products with a label of Youpik Choice—>Pick a Youpik Choice—>Share Now',
        mem_h4_1_b_t4: 'OR',
        mem_h4_1_b_t5:
          'Share your invite code—>Invite to download Youpik APP—>Using your invite code to register—>Buy a Youpik Choice by themselves',
        mem_h4_1_c_t: '(c) Upgrade to Ultimate Youpikker',
        mem_h4_1_c_t1:
          'Customers should become a Super Youpikker. Total number of people of the Super Youpikker and the team, including youpikkers invited by Super Youpikkers and their downlines, is 500, the Super Youpikker will be upgraded to a Ultimate Youpikker',
        mem_h4_1_c_t2: 'Follow below to upgrade:',
        mem_h4_1_c_t3:
          'Go back to Youpikker Page—>Find the products with a label of Youpik Choice—>Pick a Youpik Choice—>Share Now',
        mem_h4_1_c_t4: 'OR',
        mem_h4_1_c_t5:
          'Share your invite code—>Invite to download Youpik APP—>Using your invite code to register—>Buy a Youpik Choice by themselves',
        mem_h3_2: '2. Youpikker Membership',
        mem_h3_2_header1: 'Membership',
        mem_h3_2_header2: 'Customer',
        mem_h3_2_header3: 'Basic',
        mem_h3_2_header4: 'Super',
        mem_h3_2_header5: 'Ultimate',
        mem_h3_2_td1: 'Basic Mall Equity',
        mem_h3_2_td2: 'Commission%-Direct Selling (Normal Products)',
        mem_h3_2_td3: 'Commission%-Direct Selling (Youpik Choice)',
        mem_h3_2_td4: 'Commission%-Indirect Selling (Normal Products)',
        mem_h3_2_td5: 'Commission%-Indirect Selling (Youpik Choice)',
        mem_h3_2_td6: 'Youpikker Mission Rewards',
        mem_h3_2_td7: 'Free Live Tools',
        mem_h3_2_td8: 'Same Level Rewards',
        mem_h3_2_td9: 'Youpik Activities',
        mem_h3_3: '3. Commission Calculation Rules',
        mem_h3_3_a: '(a) Youpik Choice Commissions',
        mem_h3_3_a_t1: '- Youpik Choice Commission%',
        mem_h3_3_a_t1_header1: 'Youpik Choice Type',
        mem_h3_3_a_t1_header2: 'Commission%',
        mem_h3_3_a_t2: '- How to Calculate Commissions？',
        mem_h3_3_a_t2_header1: 'Youpik Choice Type',
        mem_h3_3_a_t2_header2: 'Basic Youpikker',
        mem_h3_3_a_t2_header3: 'Super Youpikker',
        mem_h3_3_a_t2_header4: 'Ultimate Youpikker',
        mem_h3_3_a_t2_td1: '฿999*60% *42%*(1-5%WHT) Confirmed Income ฿239.16',
        mem_h3_3_a_t2_td2: '฿999*60% *42%*(1-5%WHT) Confirmed Income ฿239.16',
        mem_h3_3_a_t2_td3: '฿999*60% *16%*(1-5%WHT) Confirmed Income ฿91.11',
        mem_h3_3_a_t2_td4: '฿999*60% *42%*(1-5%WHT) Confirmed Income ฿239.16',
        mem_h3_3_a_t2_td5: '฿999*60% *58%*(1-5%WHT) Confirmed Income ฿330.27',
        mem_h3_3_a_t2_td6: '฿999*60% *42%*2*(1-5%WHT) Confirmed Income ฿478.32',
        mem_h3_3_a_t2_td7: '฿999*60% *8%*(1-5%WHT) Confirmed Income ฿45.55',
        mem_h3_3_a_t2_td8: '฿999*60% *100%*(1-5%WHT) Confirmed Income ฿569.43',
        mem_h3_3_b: '(b) Normal Product Commissions',
        mem_h3_3_b_t1: '- How to Calculate Commissions？',
        mem_h3_3_b_t2: 'Commission% of normal products is 5% as common',
        mem_h3_3_b_t2_header1: 'Normal Products',
        mem_h3_3_b_t2_header2: 'Basic Youpikker',
        mem_h3_3_b_t2_header3: 'Super Youpikker',
        mem_h3_3_b_t2_header4: 'Ultimate Youpikker',
        mem_h3_3_b_t2_td1: '฿1,000*5% *77%*(1-5%WHT) Confirmed Income  ฿36.58',
        mem_h3_3_b_t2_td2: '฿1,000*5% *15%*(1-5%WHT) Confirmed Income ฿7.13',
        mem_h3_3_b_t2_td3: '฿1,000*5% *8%*(1-5%WHT) Confirmed Income  ฿3.80',
        mem_h3_3_b_t2_td4: '฿1,000*5% *77%*(1-5%WHT) Confirmed Income  ฿36.58',
        mem_h3_3_b_t2_td5: '฿1,000*5% *8%*(1-5%WHT) Confirmed Income  ฿3.80',
        mem_h3_3_b_t2_td6: '฿1,000*5% *77%*(1-5%WHT) Confirmed Income  ฿36.58',
        mem_bot_1: '**WHT is Witholding Tax as full name**',
        mem_bot_2: '**Commission income will be charged a 5% withholding tax',
        mem_bot_3: 'All interpretation rights are owned by Youpik',
        just_for_you: 'Pick For You',
        hot_items: 'Hot Items',
        youpik_plus: 'YOUPIK PLUS',
        electric_devices: 'Electric Devices',
        gm: 'General Merchandise ',
        men_clothes: 'Men Clothes',
        women_clothes: 'Women Clothes',
        beauty: 'Beauty',
        mother_baby: 'Mother & Baby',
        grocery: 'Grocery',
        fashion_accessories: 'Fashion Accessories',
        health: 'Health',
        others: 'Others',
        novice_must_learn: 'About Youpik',
        become_member: 'Join As Youpikker',
        limited_time_purchas: 'Flash Deal',
        live_sales: 'Live Sales',
        tab_sales: 'Sales',
        tab_new: 'Newest',
        tab_earn: 'Earn',
        tab_search: 'Quick Filter',
        home_All: 'All',
        home_Thailand: 'Local',
        home_China: 'Cross Boarder',
        quick_filter: 'Quick Filter',
        shipping_from: 'Shipping From',
        price_area: 'Price(฿)',
        highest_price: 'Highest Price',
        lowest_price: 'Lowest Price',
        home_confirm: 'Confirm',
        home_reset: 'Reset',
        swipe_left_to_see_more: 'Swipe left to see more',
        recommended_today: 'Today’s Recommendation',
        delete: 'Delete',
        confirm_delete_address_tip: 'Are you sure you want to delete the address?',
        confirm_text: 'Confirm',
        cancel_text: 'Cancel',
        If_the_variation_name_does_not_match_the_picture:
          'If the variation name does not match the picture, please refer to the variation name.',
        kingkong_lazada: 'High Com',
        'Search product': 'Search product',
        'View before buying': 'How to buy',
        'Products from Lazada': 'Products from Lazada',
        'invites you to be a Youpikker': 'invites you to be a Youpikker',
        'Download Youpik APP': 'Download Youpik APP',
        'Apple users enter APP Store to search for Youpik, click to download.Android users click the link below to download':
          'Apple users enter APP Store to search for Youpik, click to download.Android users click the link below to download.',
        'Sign up/sign in': 'Sign up/sign in',
        'Enter the invitation code after registration':
          'Enter the invitation code after registration',
        'Become a Youpikker': 'Become a Youpikker',
        'Go to the Youpikker page, buy any Youpik Plus product to become a Youpikker and start sharing and earning now':
          'Go to the Youpikker page, buy any Youpik Plus product to become a Youpikker and start sharing and earning now~',
        'Become a Youpikker as soon as payment is made':
          'Become a Youpikker as soon as payment is made.',
        'Become a Youpkker and save': 'Become a Youpkker and save ฿#replaceme#',
        Guide: 'Guide',
        CONTINUE: 'CONTINUE',
        'The product information has changed please place an order again':
          'The product information has changed. please place an order again',
        'Network request exception Please try again later':
          'Network request exception Please try again later',
        'Shipping address cannot be empty': 'Shipping address cannot be empty',
        'User Privacy Policy': 'Privacy Policy',
        'Youpik terms of use': 'Youpik terms of use',
        'uChoice terms of use': 'uChoice terms of use',
        'Terms Agree': 'Terms Agree',
        Agree: 'Agree',
        'If you have any questions, please contact us: 02-3295929':
          'If you have any questions, please contact us: 02-3295929',
        Withdraw: 'Withdraw',
        'Withdraw the agreement will affect the use of Youpik APP':
          'Withdraw the agreement will affect the use of Youpik APP',
        'Agree Privacy Policy': 'Agree Privacy Policy',
        'Due to the requirements of national laws and regulations, updated':
          'Due to the requirements of national laws and regulations, updated',
        and: 'and',
        kingkong_category: 'Categories',
        'how can we help': 'how can we help?',
        'Home Page': 'Home Page',
        'Help Center': 'Help Center',
        'Top Tutorials': 'Top Tutorials',
        Reviews: 'Reviews',
        ViewAll: 'View All',
        Review: 'Review',
        Sortbydefault: 'Sort by default',
        Sortbylatest: 'Sort by latest',
        Therearenoitemstoreview: 'No reviews yet',
        Nocontent: 'No content',
        'Network error, failed to save address information, please fill in again':
          'Network error, failed to save address information, please fill in again',
        Days: 'Days',
        standard_delivery_days: 'Standard Delivery (3-8 days)',
        standard_delivery_days_cross_border: 'Standard Delivery (25-30 days)',
        standard_delivery_days_cross_border_dbs: 'Standard Delivery (10-20 days)',
        优惠券购买成功: 'You have purchased the vouchers',
        '可在【Account-My Voucher】查看': 'You can check it in [Account-My Voucher]',
        '请在“Youpik APP”中查看并使用': 'Please check and use the “Youpik APP” ',
        立即查看: 'View Now',
        Estimated: 'Estimated Orders',
        'GO TO APP': 'GO TO APP',
        'Ship from overseas': 'Ship from overseas (25-30 days)',
        'Ship from overseas dbs': 'Ship from overseas (10-20 days)',
        'Economy Delivery': 'Economy Delivery',
        'please read before ordering': 'please read before ordering',
        'When you view the product please order immediately within 24 hours':
          'When you view the product, please order immediately within 24 hours',
        'Every order must be pressed through youpik app only to receive the commission':
          'Every order must be pressed through youpik app only to receive the commission',
        'Refund Rules': 'Refund Rules',
        'Refunds are not supported': 'Refunds are not supported',
        'Voucher Rules': 'Voucher Rules',
        'one voucher can be used once only It also can be used in combination with shipping vouchers':
          '1 voucher can be used once only. It also can be used in combination with shipping vouchers',
        'one voucher can be used at a time It also can be used in combination with discount vouchers':
          '1 one voucher can be used at a time. It also can be used in combination with discount vouchers',
        'Buy Now': 'Buy Now',
        canceled_on: 'canceled on:',
        'Delivery Failed': 'Delivery Failed',
        'Logistics exception': 'Logistics exception',
        'Delivery failed and returned to the merchant':
          'Delivery failed and returned to the merchant',
        'Logistics lost packages': 'Logistics lost packages',
        reason_sc_ops_1: 'No Stock',
        reason_sc_ops_2: 'Price is wrong',
        reason_sc_ops_3: 'Another reason',
        reason_sc_ops_9: 'Shipping address information does not suit with format',
        reason_sc_ops_80: 'Customer cancels task',
        reason_sc_ops_83: 'Cannot contact customer',
        reason_sc_ops_85: "Sender's phone number is empty",
        reason_sc_ops_90: 'The package is not ready to be collected',
        reason_sc_ops_91: 'The package does not meet the transportation standard',
        reason_sc_ops_95: 'Delayed to pick up due to shortage of vehicle/manpower',
        reason_sc_ops_98: 'Wrong address',
        reason_sc_ops_99: 'Package does not meet the collection conditions: oversized',
        reason_sc_ops_100: 'Package does not meet the collection conditions: contraband',
        reason_sc_ops_105: 'Damaged external package',
        reason_app_1: 'Change payment method',
        reason_app_2: 'Delivery time is too long',
        reason_app_3: 'Duplicate order',
        reason_app_4: 'Change of Delivery Address',
        reason_app_5: 'Change of mind',
        reason_app_6: 'Decided on another product',
        reason_app_7: 'Shipping cost too high',
        reason_app_8: 'Found better price elsewhere',
        Orders: 'Orders',
        'Merchant delivery time out You can buy again':
          'Merchant delivery time out You can buy again',
        receive_wrong_item_wrong_size_wrong_color: 'Receive wrong item/ wrong size/wrong color',
        missing_item_accessory_freebie: 'Missing item/accessory/freebie',
        damange_faulty_item: 'Damange/Faulty item',
        dont_want_the_item_or_it_does_not_suit_me: 'Dont want the item or It does not suit me',
        item_doesnot_match_description_pictures: 'Item doesnot match description/pictures',
        counterfeit_item: 'Counterfeit item',
        product_expired: ' Product expired',
        'Please youpikker click here to log in': 'Please youpikker click here to log in',
        'The commission is only an estimated commission, the actual commission is subject to the order information':
          'The commission is only an estimated commission, the actual commission is subject to the order information',
        reason_app_9: 'Your purchase has been judged as a fake order by the system',
        refund_on_its_way: 'Refund on its way!',
        refund_on_its_way2: 'Refund on its way',
        add_bank_account: 'Add bank account',
        Utilities: 'Utilities',
        'Top-ups': 'Top-ups',
        'At least 2 commission': 'At least 2% commission',
        'Only Youpikker can get commission': 'Only Youpikker can get commission',
        'Click the button below to enter Lazada Top-up page':
          'Click “Pay Now” button below to go to Lazada',
        'For each click, only the first order can get the commission. If you need to spend multiple times, please re-enter Youpik product detail page and click the button below to jump again.':
          'Making payment on Lazada by clicking Lazada Wallet button, and go to Bill Payment in order to pay bill',
        'Orders will be displayed in Youpikker-Sale History within 48 hours.':
          'Cashback will be shown in account page within 48 hours',
        'The confirmed commission amount is subject to the display in the order.':
          'Once the transaction is complete, any earned cashback will be applied to Youpik Wallet',
        'Top-up Now': 'Top-up Now',
        'You can get a minimum 2 commission for all payment on Lazada from Youpik.':
          'Get cashback with every transaction on Lazada via Youpik Ultimate',
        'Guide Steps:': 'Guide Steps:',
        'Click the Top-up Now button': 'Click the Top-up Now button',
        'Select Top-up&E-voucher': 'Select Top-up & E-voucher',
        'Checkout Now': 'Pay Now',
        'Upgrade Now': 'Upgrade Now',
        'Login Now': 'Login Now',
        'Select Utilities': 'Select Utilities',
        'Click the Checkout Now button': 'Click the Checkout Now button',
        'FAQ & Customer Service': 'FAQ & Customer Service',
        'best seller in': 'best seller in',
        Categories: 'Categories',
        'Whole Bestsellers': 'Whole Bestsellers',
        'The product you searched for does not exist':
          'The product you searched for does not exist',
        no_data: 'No Data',
        h5_download_app: 'Save money up to 37%! Download Now ',
        'Download uChoice Pro': 'Download uChoice Pro',
        'You will be directed to the App Store or Google Play Store. Please download uChoice Pro now.':
          'You will be directed to the App Store or Google Play Store. Please download uChoice Pro now.',
        'Comm.': 'Com',
        'Request Sample': 'Request Sample',
        'No more products': 'No more products',
        Commm: 'Com.',
        'Com. Rate': 'Com. Rate',
        DOs: 'DOs',
        DONTs: `DON'Ts`,
        'Privacy Policy': 'Privacy Policy',
        Disagree: 'Disagree',
        'It will affect the usage of Youpik Ultimate APP after you confirm to disagree the document':
          'It will affect the usage of Youpik Ultimate APP after you confirm to disagree the document',
        'If you have any questions, please contact: 02-3295929':
          'If you have any questions, please contact: 02-3295929'
      },
      ...venueEn,
      ...promptPayEn,
      ...resultEn,
      ...orderEn,
      ...itemEn,
      ...activityEn,
      ...loginEn,
      ...lazadaEn,
      ...tiktokEn,
      ...rechargeEn,
      ...bypEn,
      ...toolsGalEn,
      ...toolsHomeEn,
      ...couponEn,
      ...toolsLazadaEn,
      ...toolsGlobalEn,
      ...toolsCommonEn,
      ...toolsTemplateEn,
      ...toolsRevisionEn,
      ...selectionEn,
      ...distEn,
      ...commonEn,
    },
    th: {
      ...{
        'remote area': 'พื้นที่ห่างไกล',
        'Some goods and activities do not include free shipping in remote areas':
          'การจัดส่งในพื้นที่ห่างไกลบางรายการ ไม่ร่วมรายการจัดส่งฟรี',
        'Note The range of remote areas is provided by the logistics partner Flash':
          'หมายเหตุ: พื้นที่ห่างไกล กำหนดโดยผู้ให้บริการขนส่ง Flash',
        search: 'ค้นหา',
        inviteCode_toast: 'โปรดกรอกรหัสเชิญที่ถูกต้องอีกรอบ',
        success: 'ความสำเร็จ!',
        please_enter_account: 'กรุณาใส่บัญชี',
        please_enter_password: 'กรุณาใส่รหัสผ่าน',
        please_enter_mobile: 'กรุณาใส่มือถือ',
        please_enter_code: 'กรุณาใส่รหัส',
        loading: ' ',
        password: 'รหัสผ่าน',
        sms: 'SMS',
        account_number: 'หมายเลขบัญชี',
        login: 'เข้าสู่ระบบ',
        mobile_number: 'หมายเลขมือถือ',
        sms_code: 'รหัส SMS',
        code: 'รหัส',
        send: 'ส่ง',
        next: 'ต่อไป',
        forgot_password: 'ลืมรหัสผ่าน',
        sign_up_now: 'ลงทะเบียนเลย',
        for_you: 'สำหรับคุณ',
        sales: 'ฝ่ายขาย',
        earn: 'เงินคืน',
        earn_l: 'รับ',
        price: 'ราคา',
        add_to_cart_to_shop_on: 'หยิบใส่รถเข็นเพื่อซื้อสินค้าบน',
        box_content: 'เนื้อหาบนกล่อง',
        item_id: 'รหัสรายการสินค้า',
        get_voucher: 'รับบัตรกำนัล',
        collect: 'เก็บ',
        delivery_options: 'ตัวเลือกการจัดส่ง',
        delevery_service: 'บริการจัดส่ง',
        cash_on_delivery_available: 'มีบริการเก็บเงินปลายทาง',
        delevery_fee: 'ค่าจัดส่ง',
        days: 'วัน',
        hi_my_firend_more:
          'สวัสดีเพื่อนของฉัน คว้าคูปองพิเศษสำหรับการสั่งซื้อครั้งแรกของคุณ รับบัตรกำนัลเพิ่ม 30.- ในแอป Youpik!',
        all_items_are_available: 'มีครบทุกรายการ!',
        continue_with_facebook: 'ต่อด้วย Facebook',
        login_with_phone: 'เข้าสู่ระบบด้วยโทรศัพท์',
        login_with_line: 'เข้าสู่ระบบด้วย Line',
        privacy_policy: 'นโยบายความเป็นส่วนตัว',
        change_language: 'Change Language',
        english: 'ภาษาอังกฤษ',
        thai: 'ไทย',
        country_vi: 'เวียดนาม',
        need_help: 'ต้องการความช่วยเหลือ!',
        quantity: 'คุณภาพ',
        buy_now: 'ซื้อเลย',
        inviteCode: 'รหัสเชิญ',
        search_records: 'ค้นหาบันทึก',
        no_products_found: 'ไม่พบสินค้า',
        please_try_other_keywords: 'โปรดลองใช้คีย์เวิร์ดอื่นๆ',
        pick_for_you: 'เลือกเพื่อคุณ',
        add_address: 'เพิ่มที่อยู่',
        edit: 'แก้ไข',
        default_shipping_address: 'ที่อยู่จัดส่งเริ่มต้น',
        default_billing_address: 'ที่อยู่สำหรับการเรียกเก็บเงินเริ่มต้น',
        no_provice: 'ไม่มีจังหวัด',
        no_district: 'ไม่มีอำเภอ',
        no_postcode: 'ไม่มีรหัสไปรษณีย์',
        no_address: 'ไม่มีที่อยู่',
        no_fullname: 'ไม่มีชื่อเต็ม',
        no_phone: 'ไม่มีโทรศัพท์',
        the_length_of_the_phone_number_cannot_be_less_than_9:
          'ความยาวของหมายเลขโทรศัพท์ต้องไม่น้อยกว่า 10',
        select_a_label_for_effective_delivery: 'เลือกฉลากเพื่อการจัดส่งที่มีประสิทธิภาพ',
        for_me: 'สำหรับฉัน',
        for_client: 'สำหรับลูกค้า',
        province: 'จังหวัด',
        district: 'อำเภอ',
        postCode: 'รหัสไปรษณีย์',
        make_default_shipping_address: 'ตั้งเป็นที่อยู่จัดส่งเริ่มต้น',
        make_default_billing_address: 'ตั้งเป็นที่อยู่จัดส่งเริ่มต้น',
        save: 'บันทึก',
        save_more: 'บันทึกเพิ่มเติม',
        earn_more: 'เพิ่มรายได้',
        top_picks: 'ตัวเลือกยอดนิยม',
        seven_days_easy_return: 'คืนได้ภายไนหนึ่งอาทิต',
        seven_days_easy_return2: 'คืนสินค้า ง่ายใน 7 วัน',
        please_select: 'โปรดเลือก',
        no_stock: 'สินค้าหมด',
        product_options: 'ตัวเลือกสินค้า',
        delevery: 'จัดส่ง',
        specifications: 'สเปค',
        products: 'ผลิตภัณฑ์',
        all_products: 'สินค้าทั้งหมด',
        people_who_viewed_this_item_also_viewed: 'คนที่ดูรายการนี้ยังดู',
        youpiks_promise: 'คำสัญญาของ Youpik',
        highlights: 'ไฮไลต์',
        minishop_editors_choice: 'ทางเลือกบรรณาธิการของ Minishop',
        please_enter_invitation_code: 'กรุณาใส่รหัสเชิญ',
        wrong_invitation_code: 'รหัสเชิญผิด',
        bind_success: 'ผูกความสำเร็จ',
        is_this_your_inviter: 'นี่คือผู้เชิญของคุณหรือไม่',
        invitation_code: 'รหัสการเชิญ',
        cancel: 'ยกเลิก',
        confirm: 'ยืนยัน',
        welcome: 'ยินดีต้อนรับ',
        you_are_invited_by_a_friend_to_join_youpik_community_please_enter_your_friend_invitation_code:
          'คุณได้รับเชิญจากเพื่อนให้เข้าร่วมชุมชน Youpik กรุณาใส่รหัสเชิญเพื่อนของคุณ',
        no_invitation_from_a_friend_see: 'ไม่มีคำเชิญจากเพื่อน  ดู',
        default_code: 'รหัสเริ่มต้น',
        the_two_passwords_are_inconsistent: 'รหัสผ่านทั้งสองไม่สอดคล้องกัน',
        please_set_password: 'กรุณาตั้งรหัสผ่าน',
        we_will_send_a_one_time_SMS_code_to_your_mobile_number:
          'เราจะส่งรหัส SMS แบบครั้งเดียวไปยังหมายเลขโทรศัพท์มือถือของคุณ',
        please_enter_your_new_password_below: 'กรุณากรอกรหัสผ่านใหม่ด้านล่าง',
        new_password: 'รหัสผ่านใหม่',
        retype_password: 'พิมพ์รหัสผ่านอีกครั้ง',
        verify_code: 'รหัสยืนยัน',
        reset_password: 'รีเซ็ตรหัสผ่าน',
        to_pay: 'ทีต้องชำระเงิน',
        to_ship: 'เตรียมจัดส่ง',
        to_receive: 'ระหว่างขนส่ง',
        to_review: 'ที่ต้องรีวิว',
        hi_welcome_to_youpik: 'สวัสดี ยินดีต้อนรับสู่ youpik',
        signup: 'ลงชื่อ',
        my_purchase_history: 'ประวัติการซื้อของฉัน',
        view_all: 'ดูทั้งหมด',
        logout: 'ออกจากระบบ',
        no_select_item: 'ไม่เลือกรายการ',
        no_select_reason: 'ไม่มีเหตุผลที่เลือก',
        please_select_reason: 'กรุณาเลือกเหตุผล',
        cancellation_reason: 'เหตุผลในการยกเลิก ',
        detailed_explanation_of_selected_reason: 'คำอธิบายโดยละเอียดของเหตุผลที่เลือก ',
        submit: 'ส่ง ',
        package_one_of_one: 'แพ็คเกจ 1 จาก 1',
        shipped_by_seller_name: 'จัดส่งโดยผู้ขาย',
        choose_your_delivery_option: 'เลือกตัวเลือกการจัดส่งของคุณ',
        promo_saved: 'บันทึกโปรโมชั่นแล้ว',
        one_item_total: 'รายการ รวมทั้งหมด',
        subtotal_one_item: 'ยอดรวม ',
        shipping_fee: 'ค่าจัดส่ง',
        shipping_fee_discount: 'ส่วนลดค่าธรรมเนียมการจัดส่ง',
        total: 'ทั้งหมด',
        GST_applied_where_applicable: 'ใช้ GST เมื่อบังคับ',
        place_order: 'สถานที่การสั่งซื้อ',
        bought_by: 'ซื้อโดย',
        shipping_address: 'ที่อยู่จัดส่ง',
        billing_address: 'ที่อยู่เรียกเก็บเงิน',
        order_no: 'รายการสั่งซื้อ',
        package: 'แพ็คเกจ',
        subtotal: 'ผลรวมย่อย ',
        item: 'สิ่งของ',
        confirm_cancel: 'ยืนยัน ยกเลิก?',
        order: 'ออเดอร์',
        placed_on: 'วางบน',
        items: 'ไอเท็ม',
        pay_now: 'จ่ายตอนนี้',
        pay_code_tip:
          'การคุ้มครองการชำระเงินของลาซาด้าครอบคลุมธุรกรรมที่ไม่ได้รับอนุญาตโดยใช้ข้อมูลบัญชีลาซาด้าของคุณ (เป็นไปตามข้อกำหนดและเงื่อนไข)',
        recommended_method: 'วิธีการแนะนำ',
        credit_debit_card: 'บัตรเครดิต/เดบิต',
        trueMoney_wallet: 'ทรูมันนี่ วอลเล็ท',
        payment_method: 'วิธีการชำระเงิน)',
        cash_on_delivery: 'เก็บเงินปลายทาง',
        total_amount: 'จำนวนเงินทั้งหมด ',
        please_wait: 'Please wait',
        you_can_pay_in_cash_to_our_courier_when_you_receive_the_goods_at_your_doorstep:
          'คุณสามารถชำระเป็นเงินสดให้กับผู้จัดส่งของเราเมื่อคุณได้รับสินค้าที่หน้าประตูบ้านของคุณ',
        pay_with_your_trueMoney_account_make_sure_you_have_enough_balance_in_your_account:
          'ชำระเงินด้วยบัญชีทรูมันนี่ ตรวจสอบให้แน่ใจว่าคุณมียอดเงินคงเหลือเพียงพอในบัญชีของคุณ',
        confirm_order: 'ยืนยันรายการ ',
        save_money: 'ประหยัดเงิน',
        earn_money: 'ได้รับ',
        no_capital: 'ไม่มีทุน',
        effortless_earning: 'สร้างรายได้อย่างง่ายดาย',
        payment_success: 'ชำระเงินสำเร็จ',
        your_order_number_is: 'หมายเลขคำสั่งซื้อของคุณคือ',
        payment_failed: 'การชำระเงินล้มเหลว',
        for_more_details_track_your_delivery_status_under:
          'สำหรับข้อมูลโดยละเอียดเพิ่มเติม โปรดติดตามสถานะการจัดส่งของคุณด้านล่าง',
        my_account_my_order: 'บัญชีของฉัน> คำสั่งซื้อของฉัน',
        view_order: 'ดูคำสั่งซื้อ',
        we_ve_sent_a_confirmation_email_to: 'เราได้ส่งอีเมลยืนยันไปที่',
        join_as_youpikker_and_start_to_earn_today: 'เข้าร่วมเป็น Youpiker และเริ่มรับวันนี้',
        manage_selections: 'จัดการการเลือก',
        special_for_you_shop_now: 'พิเศษสำหรับคุณ - ช้อปเลย',
        download_APP_to_get_30_30_vouchers: 'ดาวน์โหลดแอปเพื่อรับบัตรกำนัล 30+30 ใบ',
        best_selling_products: 'สินค้าขายดี',
        enjoy_cash_back_when_you_buy: 'เพลิดเพลินกับเงินคืนเมื่อคุณซื้อ',
        earn_incentives_when_you_share_successfully: 'รับสิ่งจูงใจเมื่อคุณแชร์สำเร็จ',
        more_benefits_await_as_you_grow_your_membership_level_from_basic_to_super_and_partner:
          'สิทธิประโยชน์อีกมากมายรอคุณอยู่เมื่อคุณเพิ่มระดับการเป็นสมาชิกจากระดับพื้นฐาน สู่ระดับซูเปอร์ และพันธมิตร',
        buy_vip_products: 'ช้อปสินค้า VIP',
        download_or_open_youpik_application: 'ดาวน์โหลด หรือ เปิดแอป Youpik เลย',
        start_sharing_and_earning_now: 'เริ่มต้นการแชร์เพื่อรับเงินได้เลย!',
        vip_products: 'สินค้า VIP',
        purchase_these_exclusively_curated_products_to_be_a_member_today:
          'เพียงซื้อสินค้าประเภท VIP  ก็ได้เป็นสมาชิก VIP เลย',
        discount_fee: 'ค่าธรรมเนียมส่วนลด',
        order_details: 'รายละเอียดคำสั่งซื้อ',
        receiver: 'ผู้รับ',
        track_num: 'หมายเลขติดตาม',
        delivery_partner: 'พันธมิตรการจัดส่ง',
        return_reason: 'เหตุผลการคืนสินค้า',
        reason: 'เหตุผล',
        return_to: 'กลับไปที่',
        return_request_title: 'นโยบายการคืนสินค้า/คืนเงิน',
        return_request_tx:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis',
        return_request_tx2: '1. การคืนสินค้า/การคืนเงินจะขึ้นอยู่กับความคิดเห็นและรูปภาพที่คุณให้',
        return_request_tx3:
          '2. พิมพ์ลาเบล/ แบบฟอร์มการส่งคืนของคุณก่อนส่งคืนสินค้า บริษัทจัดส่งอาจไม่รับพัสดุคืนของคุณหากไม่มีลาเบลหรือหากสินค้าไม่ได้บรรจุอย่างปลอดภัย',
        return_request_tx4:
          '3. การคืน "เปลี่ยนใจ" ใช้ได้เฉพาะรายการที่เลือกเท่านั้น รายการเหล่านี้ต้องปิดผนึก',
        got_it: 'รับทราบแล้ว',
        upload_image: 'อัพโหลดรูปภาพ',
        by_submit: 'การส่งแบบฟอร์มนี้คือการยืนยอมนโยบายการคืนสินค้า / การคืนเงินของ Youpik',
        total_refund: 'ยอดรวมการคืนเงิน',
        return_request_1: 'คำขอคืนสินค้า(1/2)',
        return_request_2: 'คำขอคืนสินค้า(2/2)',
        return_request2_title: 'ยืนยันการจัดส่ง',
        return_request2_tx:
          'สามารถขอคืนสินค้าได้เมื่อคุณได้รับสินค้าแล้วเท่านั้น คุณได้รับสินค้าที่เลือกแล้วหรือยัง',
        continue: 'ต่อไป',
        change: 'เปลี่ยน',
        no_bank: 'คืนเงินด้วยการโอนเงินผ่านธนาคาร',
        item_price: 'ราคาสินค้า',
        expected_total: 'จำนวนทั้งหมดที่จะได้รับเงินคืน',
        bank_transfer: 'โอนเงินผ่านบัญชีธนาคาร',
        select_a_bank: 'เลือกบัญชีธนาคาร',
        account_name: 'ชื่อบัญชี',
        account_name_pl: 'ป้อนชื่อ​บัญชี​ธนาคาร​ของ​คุณ',
        account_number_pl: 'เช่น 12344567890983',
        my_returens: 'คืนสินค้า/คืนเงิน',
        my_cancel: 'การยกเลิกของฉัน',
        account: 'บัญชีผู้ใช้',
        setting: 'การตั้งค่า',
        returns: 'คืนสินค้า คืนเงิน',
        cancellations: 'การยกเลิกสินค้า',
        store: 'เก็บ',
        benefits: 'สิทธิประโยน์สำหรับสมาชิก VIP',
        how_to_vip: 'สมัครเป็นสมาชิก VIP ได้ยังไง',
        order_list: 'รายการสั่งซื้อ',
        all: 'ทั้งหมด',
        return: 'การคืนสินค้า',
        track_package: 'ติดตามพัสดุ',
        cancel_request: 'คำขอยกเลิก',
        order_confirm: 'ยืนยันการสั่งซื้อ',
        select_payment_method: 'เลือกวิธีชำระเงิน',
        order_received: 'รับออร์เดอร์แล้ว',
        address_list: 'รายการที่อยู่',
        address_manage: 'การจัดการที่อยู่',
        full_name: 'ชื่อเต็ม',
        address: 'ที่อยู่',
        phone_number: 'หมายเลขโทรศัพท์',
        added: 'บวก',
        add_to_my_shop: 'เพิ่มในร้านค้าของฉัน',
        brand: 'แบรนด์',
        home: 'บ้าน',
        my_account: 'บัญชีของฉัน',
        my_orders: 'คำสั่งซื้อของฉัน',
        set_password: 'ตั้งรหัสผ่าน',
        order_empty: 'ยังไม่มีการสั่งซื้อ',
        order_empty_btn: 'ช้อปปิ้งต่อ',
        voucher_for_you: 'บัตรกำนัลสำหรับคุณ!',
        sorry: 'ขอโทษ!',
        noItem_text: 'สินค้านี้ไม่มีจำหน่ายแล้วในขณะนี้',
        back_to_homepage: 'กลับหน้าแรก',
        dps: 'dps',
        standard_delivery2: 'การขนส่งแบบมาตรฐาน',
        days2: '(3-8 วัน)',
        days3: '(10-15 วัน)',
        dpsDays: 'Delivery by Seller',
        no_pdf_msg: 'เบราว์เซอร์นี้ไม่รองรับไฟล์ PDF โปรดคลิกเพื่อดู:',
        download_pdf: 'ดาวน์โหลด PDF',
        daily_deals: 'ข้อเสนอรายวัน',
        more: 'More',
        sold: 'ขายแล้ว',
        ProductID: 'รหัสสินค้า',
        goods_sold_out: 'สินค้าหมด',
        start_in: 'เริ่มใน',
        end_in: 'สิ้นสุดใน',
        or_continue_with: 'หรือต่อด้วย',
        chat_now: 'คุยตอนนี้',
        packing_time: 'เวลาบรรจุ',
        pay_time: 'จ่ายเวลา',
        ends_in: 'สิ้นสุดใน',
        left: 'ซ้าย',
        sold_out: 'ขายหมดแล้ว',
        app_title: 'แอปพลิเคชั่น Youpik',
        uchoice_title: 'แอปพลิเคชั่น uChoice',
        app_tx1: 'ประสบการณ์ใหม่กับโซเชียลคอมเมิร์ซ',
        app_tx2: 'ยิ่งช้อป ยิ่งแชร์ ยิ่งได้!',
        uchoice_tx1: 'เครื่องมือเลือกสรรสินค้าสำหรับครีเอเตอร์ TikTok',
        app_tx3: 'ดาวน์โหลดแล้วช้อปเลย',
        app_dowload: 'ดาวน์โหลดได้ที่',
        app_android: 'แอนดรอยด์',
        app_appStore: 'App Store',
        app_tx4: 'เปิดลิงก์นี้ด้วยเบราว์เซอร์ของโทรศัพท์ แล้วคลิกดาวน์โหลด',
        copy_success: 'คัดลอกสำเร็จ!',
        notice: 'ประกาศ',
        notice_msg:
          'เนื่องจากสถานการณ์การแพร่ระบาดของเชื้อโควิด19 ส่งผลให้เกิดความล่าช้าในการขนส่งสินค้าจำนวนมากในพื้นที่ดังกล่าว ต้องขออภัยในความไม่สะดวกเป็นอย่างยิ่ง หากคุณมีข้อสงสัยเพิ่มเติม สามารถติดต่อสอบถามมายังฝ่ายลูกค้าสัมพันธ์ของ Youpik ได้ผ่านแอปพลิเคชั่น โดยติดต่อผ่านทางแอปแชท เพียงกดไปที่หน้าบัญชี และ ข้อความ',
        no_stock_good: 'ไม่ต้องสต็อกของ',
        activity: 'กิจกรรม',
        activity_end_msg: 'คุณมาสาย. สิ้นสุดกิจกรรม',
        exceeds_product_limit: 'สินค้าเกินขีดจำกัด',
        cross_border: 'ข้ามพรมแดน',
        cross_ship: 'ส่งจากต่างประเทศ',
        share_to_earn: 'แบ่งปันเพื่อรับ',
        end_time: 'จะสิ้นสุดใน',
        Real_time_leaderboar: 'กระดานคะแนนปัจจุบัน',
        Campaign_leaderboard: 'กระดานคะแนนแคมเปญ',
        Estimated_rewards: 'รางวัลที่คาดว่าจะได้',
        min_spend: 'ใช้จ่ายขั้นต่ำ',
        valid_till: 'ใช้ได้จนถึง',
        youpik_birthday: 'Youpik ฉลองวันเกิดครบรอบ 2 ปี',
        acTab1: 'คอม 11%',
        acTab2: 'คอม 111',
        acTab3: 'ดีล 1',
        acTab4: 'ดีล 111',
        acTab121: 'คอม 12%',
        acTab122: 'คอม 112.',
        acTab123: 'YPC 222.- คอม 40%',
        acTab124: ' ดีล 112.- ',
        acTab125: 'ดีล 12.-',
        coming_soon: 'Coming Soon',
        ac_no_token: 'โปรดอัปเกรดเป็นเวอร์ชันล่าสุดที่หน้าการตั้งค่าเพื่อรับ',
        city: 'เมือง',
        no_city: 'ไม่มีเมือง',
        for_shop: 'สำหรับการช้อปปิ้งที่ Youpik',
        cancel_reason: 'เหตุผลในการยกเลิก',
        cancel_item: 'รายการยกเลิก',
        track_progress: 'ติดตามความคืบหน้า',
        cancel_info: 'ข้อมูลการยกเลิก',
        order_num: 'เลขที่ใบสั่งซื้อ',
        request_on: 'ขอเมื่อ',
        supplied_by: 'มอบให้โดย',
        chat: 'แชท',
        refund_on: 'คืนเงินในทางของมัน!',
        refunded: 'คืนเงินแล้ว',
        refund_reject: 'ปฏิเสธการคืนเงิน',
        refund_msg1:
          'คำขอคืนเงินของคุณได้รับการอนุมัติแล้ว โปรดอดทนรอในขณะที่ระบบของเรากำลังดำเนินการคืนเงินของคุณ',
        refund_msg2: 'คำขอคืนเงินของคุณเสร็จสิ้น',
        refund_msg3: 'หากมีข้อสงสัยกรุณาติดต่อฝ่ายบริการลูกค้า',
        refund_reason: 'เหตุผลในการคืนเงิน',
        refund_time: 'เวลาคืนเงิน',
        refund_amount: 'จำนวนเงินคืน',
        refund_method: 'วิธีการคืนเงิน',
        related_product: 'สินค้าที่เกี่ยวข้อง',
        view_history: 'ดูประวัติการสื่อสาร',
        returned_on: 'กลับมาเมื่อ',
        refund_on_its: 'คืนเงินในทางของมัน',
        thank_you: 'ขอขอบคุณ!',
        for_our: 'สำหรับการยกเลิกของเรา',
        returns_refund: 'การคืนสินค้า/การคืนเงิน',
        sold_by_redmart: 'จำหน่ายโดย ',
        live_title1: 'ไลฟ์โปรโมท คืออะไร?',
        live_title2: 'วิธีการใช้ ไลฟ์โปรโมท',
        live_ms1:
          'ไลฟ์โปรโมท คือฟีเจอร์สำหรับ Youpikker ขายสินค้าในแอป Youpik ผ่านช่องทางการไลฟ์สด คุณสามารถจัดการรายการสินค้า รวมถึงเชื่อมต่อไลฟ์ ใน Facebook โดยระบบจะทำการเชื่อมต่อกิจกรรมต่างๆ ใน Facebook โดยอัตโนมัติ ฟีเจอร์นี้จะช่วยให้ Youpikker เพิ่มยอดขายและค่าคอมมิชชั่นได้อย่างง่ายดาย!',
        live_ms2: '1、คลิกไอคอน ไลฟ์โปรโมท แล้วสร้างรายการสินค้า',
        live_ms3: '2、เลือกสินค้าที่ต้องการ (มากสุด 200 ชิ้น) แล้วสร้างรายการสินค้า',
        live_ms4: '3、เชื่อมต่อรายการสินค้าที่สร้างไว้เข้ากับไลฟ์ใน Facebook',
        live_ms5: '4、เมื่อเชื่อมต่อสำเร็จก็เริ่มไลฟ์ขายสินค้าได้เลย',
        cancelled: 'ยกเลิก',
        your: 'ของคุณ',
        item_has: 'รายการถูกยกเลิก',
        select: 'เลือก',
        order_has: 'ยกเลิกคำสั่งซื้อแล้ว ดูรายการอื่นๆ',
        home_page: 'หน้าแรก',
        cancel_detail: 'รายละเอียดการยกเลิก',
        refund_detail: 'รายละเอียดการคืนเงิน',
        your_refund_ms:
          'คำขอคืนเงินของคุณจะได้รับการจัดการใน 1-2 วันธรรมดา คุณสามารถดูความคืบหน้าในการคืนเงินได้ในรายการคืนสินค้า/การคืนเงิน',
        unpaid: 'ค้างชำระ',
        refund: 'คืนเงิน',
        number: 'ตัวเลข',
        refund_type: 'วิธีการคืนเงิน',
        i_accept: 'ฉันยอมรับ youpik',
        refund_agree: 'ข้อตกลงการคืนสินค้า',
        bank_name: 'ชื่อธนาคาร',
        bank_code: 'รหัสธนาคาร',
        refund_isMsg: 'จำนวนเงินที่ชำระจริง ณ เวลาที่สั่งซื้อ',
        progress1: 'ส่งใบสมัคร',
        progress2: 'การประมวลผลของผู้ค้า',
        progress3: 'กำลังประมวลผล',
        progress4: 'เสร็จสิ้น',
        flash_express: 'แฟลช เอ็กซ์เพรส',
        thailand_post: 'ไปรษณีย์ไทย',
        scg_express: 'เอสซีจี เอ็กซ์เพรส',
        alpha_fast: 'อัลฟาฟาส',
        lalamove: 'ลาลามูฟ',
        kerry_express: 'เคอร์รี่ เอ็กซ์เพรส',
        line_man: 'ไลน์แมน',
        grab_express: 'แกร๊ป เอ็กซ์เพรส',
        tnt_express: 'ทีเอ็นที เอ็กซ์เพรส',
        ltd: 'ไอทีทรานสปอร์ต',
        niko: 'นิโกส์ โลจิสติคส์',
        nim_express: 'นิ่ม เอ็กเพรส',
        dhl_express: 'ดีเอชแอล เอ็กซ์เพรส',
        ninjavan: 'นินจาแวน',
        skootar: 'สกู๊ตตาร์',
        jt_express: 'เจแอนด์ที เอ็กซ์เพรส',
        speed_d: 'สปีด-ดี',
        best_express: 'เบสท์ เอ็กซ์เพรส',
        applying: 'คําขอคืนเงิน',
        processing: 'กำลังประมวลผล',
        processed: 'สำเร็จ',
        date: 'วันที่',
        you_can_before: 'สามารถสมัครหลังการขายได้ก่อน',
        as_alone_as: 'ภายใน 7 วันหลังการขาย สมัครใหม่ได้',
        logistics_name: 'ชื่อโลจิสติกส์',
        tracking_number: 'หมายเลขพัสดุ',
        please_enter_tracking_number: 'กรุณาใส่หมายเลขติดตาม',
        sender_phone: 'โทรศัพท์ผู้ส่ง',
        please_enter: 'กรุณาป้อน',
        courier_fees: 'ค่าธรรมเนียมการจัดส่ง',
        bank_msg1: 'กรุณาระบุข้อมูลบัญชีธนาคาร',
        bank_msg2: 'สามารถ​ใส่ได้​เฉพาะ​ตัว​อักษร​หรือ​ช่อง​ว่าง',
        bank_msg3: 'กรุณา​ใส่​ตัว​เลขเท่านั้น​โดย​ไม่​เกิน​15หลัก',
        refund_failed: 'คำขอคืนเงินเกิดข้อผิดพลาด',
        refund_expired: 'คำขอคืนเงินหมดอายุ',
        in_shipping_process: 'กำลังอยู่ในระหว่างการคืนสินค้า',
        please_waiting_for_seller_operation: 'กรุณารอผู้ขายดำเนินการ',
        please_return_item: 'กรุณาคืนสินค้า',
        seller_confirm_receiving_item: 'ผู้ขายยืนยันการได้รับสินค้า คุณจะได้รับเงินคืนภายใน 7 วัน',
        please_wait_seller_confirm_receive_item_after_seller_confirm_you_will_get_the_refund:
          'รอผู้ขายยืนยันการรับสินค้า หากยืนยันแล้วคุณจะได้รับเงินคืน',
        request_has_been_cancelled_contact_cs: 'คำร้องถูกยกเลิก กรุณาติดต่อแผนกลูกค้าสัมพันธ์',
        you_after_sale:
          'ส่งใบสมัครหลังการขายของคุณเรียบร้อยแล้ว และร้านค้าจะดำเนินการตรวจสอบให้เสร็จสิ้นภายใน 7 วัน',
        please_push_the:
          'กรุณาดันสินค้าไปยังที่อยู่สำหรับคืนสินค้าที่กำหนดภายใน 10 วัน คาดว่าการขอคืนเงินจะถูกปิด',
        done: 'สำเร็จ',
        seller_is_operating: 'ผู้ขายกำลังดำเนินการ',
        submit_request: 'ยื่นคำร้อง',
        return_address: 'ที่อยู่จัดส่งคืนสินค้า',
        shipping_label: 'ใบปะหน้าการส่งสินค้า',
        shipping_bill: 'บิลการส่งสินค้า',
        return_method: 'วิธีการคืนสินค้า',
        select_shipping_company: 'เลือกบริษัทส่งสินค้า',
        request_created_time: 'เวลาที่ยื่นคำร้อง',
        estimated_payment_amount: 'จำนวนเงินที่ชำระโดยประมาณ',
        actual_payment_amount: 'จำนวนเงินที่ชำระ',
        after_the_merchant:
          'หลังจากที่ผู้ค้าตกลงที่จะคืนสินค้า พวกเขาจะแบกรับค่าขนส่งอย่างมากที่สุด',
        please_upload_ship_label: 'โปรดอัปโหลดป้ายกำกับการจัดส่ง',
        please_upload_ship_bill: 'กรุณาอัพโหลดใบเรียกเก็บเงินค่าขนส่ง',
        i_agree_that:
          'ฉันยอมรับว่า Youpik อาจรวบรวม บันทึก และประมวลผลข้อมูลบัญชีธนาคารของฉันเพื่อวัตถุประสงค์ในการดำเนินการคืนเงินการชำระเงิน',
        are_you_confirm: 'คุณยืนยันการส่งหรือไม่',
        yes: 'ใช่',
        no: 'ไม่',
        please_confirm: 'กรุณายืนยัน',
        donot_edit: 'ไม่สามารถแก้ไขข้อมูลการส่งคืนได้หลังจากส่งแล้ว',
        mem_title: 'สิทธิประโยชน์และเกณฑ์การจัดลำดับของผู้ใช้งาน',
        mem_small_title: 'Youpik สิทธิประโยชน์และเกณฑ์การจัดลำดับของผู้ใช้งาน',
        mem_h3_1: '1. เคล็ดลับการอัปเกรดเป็นยูพิคเกอร์',
        mem_h4_1_a_t: '（A）อัปเกรดเป็นเบสิคยูพิคเกอร์',
        mem_h4_1_a_t1:
          'ผู้ใช้งานทั่วไปสามารถอัปเกรดเป็นเบสิคยูพิคเกอร์ได้ โดยการซื้อสินค้า Youpik Choice',
        mem_h4_1_a_t2:
          'วิธีการอัปเกรด : ไปที่หน้า Youpikker —> ค้นหาสินค้าที่มีสัญลักษณ์ Youpik Choice —> เลือกสินค้า —> ช้อป',
        mem_h4_1_b_t: '（B）อัปเกรดเป็นซูเปอร์ยูพิคเกอร์',
        mem_h4_1_b_t1:
          'โดยปกติแล้ว ผู้ใช้งานทั่วไป จะต้องอัปเกรดเป็นเบสิคยูพิคเกอร์ก่อน ซึ่งเบสิคยูพิคเกอร์จะสามารถเชิญผู้ใช้งานทั่วไป 20 คน จึงจะถูกอัปเกรดเป็นซูเปอร์ยูพิคเกอร์',
        mem_h4_1_b_t2: 'วิธีการอัปเกรด :',
        mem_h4_1_b_t3:
          'ไปที่หน้า Youpikker —> ค้นหาสินค้าที่มีสัญลักษณ์ Youpik Choice —> เลือกสินค้า —> แชร์',
        mem_h4_1_b_t4: 'หรือ',
        mem_h4_1_b_t5:
          'ชวนเพื่อนดาวน์โหลดแอปผ่านรหัสคำเชิญของคุณ แล้วให้เพื่อนเลือกช้อปสินค้า Youpik Choice ได้เลย',
        mem_h4_1_c_t: '（C）อัปเกรดเป็นอัลติเมทยูพิคเกอร์r',
        mem_h4_1_c_t1:
          'หลังจากอัปเกรดเป็นซูเปอร์ยูพิคเกอร์ และมีจำนวนสมาชิกร่วมทีม จำนวน 500 คน ซูเปอร์ยูพิคเกอร์ท่านนั้นจะได้อัปเกรดเป็นอัลติเมทยูพิคเกอร์',
        mem_h4_1_c_t2: 'วิธีการอัปเกรด:',
        mem_h4_1_c_t3:
          ' ไปที่หน้า Youpikker —> ค้นหาสินค้าที่มีสัญลักษณ์ Youpik Choice —>เลือกสินค้า —> แชร์',
        mem_h4_1_c_t4: 'หรือ',
        mem_h4_1_c_t5:
          'ชวนเพื่อนดาวน์โหลดแอปผ่านรหัสคำเชิญของคุณ แล้วให้เพื่อนเลือกช้อปสินค้า Youpik Choice ได้เลย',
        mem_h3_2: '2. สิทธิประโยชน์ของยูพิคเกอร์',
        mem_h3_2_header1: 'สมาชิก',
        mem_h3_2_header2: 'ลูกค้า',
        mem_h3_2_header3: 'Basic',
        mem_h3_2_header4: 'Super',
        mem_h3_2_header5: 'Ultimate',
        mem_h3_2_td1: 'สิทธิประโยชน์ในการช้อป',
        mem_h3_2_td2: 'คอมมิชชั่นที่จะได้รับ เมื่อแชร์สินค้าทั่วไปสำเร็จ',
        mem_h3_2_td3: 'คอมมิชชั่นที่จะได้รับ เมื่อแชร์สินค้า Youpik Choice สำเร็จ',
        mem_h3_2_td4: 'คอมมิชชั่นที่จะได้รับ เมื่อ Youpikker ในทีมแชร์สินค้าทั่วไปสำเร็จ',
        mem_h3_2_td5: 'คอมมิชชั่นที่จะได้รับ เมื่อ Youpikker ในทีมแชร์สินค้า Youpik Choice สำเร็จ',
        mem_h3_2_td6: 'รางวัลภารกิจยูพิคเกอร์',
        mem_h3_2_td7: 'ฟรี ระบบไลฟ์สตรีมมิ่ง',
        mem_h3_2_td8: 'รางวัลสถานะชนกัน',
        mem_h3_2_td9: 'กิจกรรมยูพิค',
        mem_h3_3: '3. การคำนวณค่าคอมมิชชั่น',
        mem_h3_3_a: '(A) ค่าคอมมิชชั่นจากสินค้า Youpik Choice',
        mem_h3_3_a_t1: '-เปอร์เซนต์ค่าคอมมิชชั่นจากสินค้า Youpik Choice',
        mem_h3_3_a_t1_header1: 'Youpik Choice Type',
        mem_h3_3_a_t1_header2: 'คอมมิชชั่น',
        mem_h3_3_a_t2: '-วิธีคำนวณค่าคอมมิชชั่น',
        mem_h3_3_a_t2_header1: 'ชนิดของ Youpik Choice',
        mem_h3_3_a_t2_header2: 'เบสิคยูพิคเกอร์',
        mem_h3_3_a_t2_header3: 'Super Youpikker',
        mem_h3_3_a_t2_header4: 'Ultimate Youpikker',
        mem_h3_3_a_t2_td1: '฿999*60% *42%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿239.16',
        mem_h3_3_a_t2_td2: '฿999*60% *42%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿239.16',
        mem_h3_3_a_t2_td3: '฿999*60% *16%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿91.11',
        mem_h3_3_a_t2_td4: '฿999*60% *42%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿239.16',
        mem_h3_3_a_t2_td5: '฿999*60% *58%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿330.27',
        mem_h3_3_a_t2_td6: '฿999*60% *42%*2*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿478.32',
        mem_h3_3_a_t2_td7: '฿999*60% *8%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿45.55',
        mem_h3_3_a_t2_td8: '฿999*60% *100%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿569.43',
        mem_h3_3_b: '(B) ค่าคอมมิชชั่นของสินค้าปกติ',
        mem_h3_3_b_t1: '-คำนวณค่าคอมมิชชั่นอย่างไร?',
        mem_h3_3_b_t2: 'โดยปกติ เปอร์เซนคอมมิชชั่นของสินค้าปกติคือ 5%',
        mem_h3_3_b_t2_header1: 'สินค้าปกติ',
        mem_h3_3_b_t2_header2: 'Basic Youpikker',
        mem_h3_3_b_t2_header3: 'Super Youpikker',
        mem_h3_3_b_t2_header4: 'Ultimate Youpikker',
        mem_h3_3_b_t2_td1: '฿1,000*5% *77%*(1-5%WHT) มูลค่าสุทธธิของสินค้า  ฿36.58',
        mem_h3_3_b_t2_td2: '฿1,000*5% *15%*(1-5%WHT) มูลค่าสุทธธิของสินค้า ฿7.13',
        mem_h3_3_b_t2_td3: '฿1,000*5% *8%*(1-5%WHT) มูลค่าสุทธธิของสินค้า  ฿3.80',
        mem_h3_3_b_t2_td4: '฿1,000*5% *77%*(1-5%WHT) มูลค่าสุทธธิของสินค้า  ฿36.58',
        mem_h3_3_b_t2_td5: '฿1,000*5% *8%*(1-5%WHT) มูลค่าสุทธธิของสินค้า  ฿3.80',
        mem_h3_3_b_t2_td6: '฿1,000*5% *77%*(1-5%WHT) มูลค่าสุทธธิของสินค้า  ฿36.58',
        mem_bot_1: '**WHT (Witholding Tax) คือ ภาษี ณ ที่จ่าย**',
        mem_bot_2: '**รายได้จากคอมมิชชั่นจะถูกหักภาษี ณ ที่จ่าย เป็นจำนวน 5%**',
        mem_bot_3: 'อัตราตามโครงสร้างการให้ค่าคอมมิชชั่นนี้เป็นลิขสิทธิ์ภายใต้ Youpik',
        just_for_you: 'คัดมาเพื่อคุณ',
        hot_items: 'สินค้ามาแรง',
        youpik_plus: 'YOUPIK PLUS',
        electric_devices: 'เครื่องใช้ไฟฟ้า',
        gm: 'ของใช้ในบ้าน',
        men_clothes: 'เสื้อผ้าผู้ชาย',
        women_clothes: 'เสื้อผ้าผู้หญิง',
        beauty: 'ความงาม',
        mother_baby: 'แม่และเด็ก',
        grocery: 'ของกินของใช้',
        fashion_accessories: 'แฟชั่นและเครื่องประดับ',
        health: 'สุขภาพ',
        others: 'สินค้าอื่นๆ',
        novice_must_learn: 'รู้จักยูพิค',
        become_member: 'เป็นสมาชิกเลย',
        limited_time_purchas: 'ดีลด่วน',
        live_sales: 'ไลฟ์โปรโมท',
        tab_sales: 'ยอดขาย',
        tab_new: 'ใหม่ล่าสุด',
        tab_earn: 'คอมมิชชั่น',
        tab_search: 'คัดเลือก',
        home_All: 'ทั้งหมด',
        home_Thailand: 'ในประเทศ',
        home_China: 'ต่างประเทศ',
        quick_filter: 'คัดเลือก',
        shipping_from: 'ส่งจาก',
        price_area: 'ราคา(₫)',
        highest_price: 'ราคาสูงสุด',
        lowest_price: 'ราคาต่ำสุด',
        home_confirm: 'ยืนยัน',
        home_reset: 'ล้าง',
        swipe_left_to_see_more: 'ปัดซ้ายเพื่อดูเพิ่มเติม',
        recommended_today: 'ดีลแนะนำประจำวัน',
        delete: 'ลบ',
        confirm_delete_address_tip: 'คุณแน่ใจว่าจะลบที่อยู่หรือไม่',
        confirm_text: 'ใช่',
        cancel_text: 'ไม่',
        If_the_variation_name_does_not_match_the_picture:
          'หากรายละเอียดสินค้าที่เลือกไม่ตรงกับภาพ กรุณายึดรายละเอียดสินค้าเป็นหลัก',
        kingkong_lazada: 'ค่าคอมมิชชั่นสูง',
        'Search product': 'ค้นหาสินค้า',
        'View before buying': 'แนะนำการใช้งาน',
        'Products from Lazada': 'หน้าสินค้า Lazada',
        'invites you to be a Youpikker': 'เชิญคุณมาเป็น Youpikker',
        'Download Youpik APP': 'ดาวน์โหลด Youpik App',
        'Apple users enter APP Store to search for Youpik, click to download.Android users click the link below to download':
          'ผู้ใช้ Apple สามารถค้นหาและดาวน์โหลดได้ใน App Store สำหรับผู้ใช้ Android คลี๊กที่ลิงค์เพื่อดาวน์โหลด',
        'Sign up/sign in': 'เข้าสู่ระบบ',
        'Enter the invitation code after registration':
          'ใส่รหัสเชิญชวน หลังจากลงทะเบียนเรียบร้อยแล้ว',
        'Become a Youpikker': 'มาเป็น Youpiker',
        'Go to the Youpikker page, buy any Youpik Plus product to become a Youpikker and start sharing and earning now':
          'ซื้อสินค้าที่หน้า Youpikker เพื่ออัพเกรดเป็น Youpikker สามารถเริ่มแชร์เพื่อรับรายได้',
        'Become a Youpikker as soon as payment is made':
          'หลังจากชำระเงินสำเร็จเลื่อนระดับเป็น Youpikker',
        'Become a Youpkker and save':
          'มาเป็น Youpiker รับไปเลยส่วนลด #replaceme#₫ บาทสำหรับสินค้าชิ้นนี้',
        Guide: 'เงื่อนไขกิจกรรม',
        CONTINUE: 'ดำเนินการต่อ',
        'The product information has changed please place an order again':
          'ข้อมูลสินค้ามีการเปลี่ยนแปลง โปรดทำการสั่งซื้อใหม่',
        'Network request exception Please try again later':
          'คำขอเครือข่ายล้มเหลว โปรดลองอีกครั้งในภายหลัง',
        'Shipping address cannot be empty': 'ที่อยู่จัดส่งต้องไม่เว้นว่าง',
        'User Privacy Policy': 'นโยบายความเป็นส่วนตัว',
        'Youpik terms of use': 'เงื่อนไขการใช้งาน Youpik',
        'uChoice terms of use': 'เงื่อนไขการใช้งาน uChoice',
        'Terms Agree': 'ข้อตกลง',
        Agree: 'ยินยอม',
        'If you have any questions, please contact us: 02-3295929':
          'หากมีข้อสงสัย กรุณาติดต่อ ：02-3295929',
        Withdraw: 'ปิดการอนุญาตเข้าถึง',
        'Withdraw the agreement will affect the use of Youpik APP':
          'หากปิดการอนุญาตเข้าถึงจะกระทบต่อการใช้งาน APP',
        'Agree Privacy Policy': 'ยอมรับนโยบายความเป็นส่วนตัว',
        'Due to the requirements of national laws and regulations, updated':
          'เนื่องจากเป็นข้อกำหนดของกฎหมายและข้อบังคับของประเทศ จึงมีการปรับปรุง',
        and: 'และ',
        kingkong_category: 'หมวดหมู่',
        'how can we help': 'คู่มือสำหรับนักช้อป',
        'Home Page': 'หน้าแรก',
        'Help Center': 'ศูนย์ช่วยเหลือ',
        'Top Tutorials': 'หัวข้อที่ควรรู้',
        Reviews: 'ความคิดเห็น',
        ViewAll: 'ดูทั้งหมด',
        Review: 'รีวิว',
        Sortbydefault: 'เรียงตามค่าเริ่มต้น',
        Sortbylatest: 'เรียงตามล่าสุด',
        Therearenoitemstoreview: 'ยังไม่มีการรีวิว',
        Nocontent: 'ไม่มีเนื้อหา',
        'Network error, failed to save address information, please fill in again':
          'อินเตอร์เน็ตขัดข้อง ที่อยู่บันทึกไม่สำเร็จ กรุณากรอกอีกครั้ง',
        Days: 'วัน',
        standard_delivery_days: 'กาจัดส่งแบบมาตรฐาน (3-8 วัน)',
        standard_delivery_days_cross_border: 'กาจัดส่งแบบมาตรฐาน (25-30 วัน)',
        standard_delivery_days_cross_border_dbs: 'กาจัดส่งแบบมาตรฐาน (10-20 วัน)',
        优惠券购买成功: 'สั่งซื้อคูปองสำเร็จ',
        '可在【Account-My Voucher】查看': 'คุณสามารถตรวจสอบได้ใน [บัญชี-บัตรกำนัลของฉัน]',
        '请在“Youpik APP”中查看并使用': 'โปรดตรวจสอบและใช้บนแอป Youpik ',
        立即查看: 'ดูตอนนี้',
        Estimated: 'คำสั่งซื้อโดยประมาณ',
        'GO TO APP': 'GO TO APP',
        'Ship from overseas': 'ส่งจากต่างประเทศ (25-30 วัน)',
        'Ship from overseas dbs': 'ส่งจากต่างประเทศ (10-20 วัน)',
        'Economy Delivery': 'การจัดส่งแบบประหยัด',
        'please read before ordering': 'โปรดอ่านก่อนสั่งซื้อ',
        'When you view the product please order immediately within 24 hours':
          'เมื่อเข้าดูสินค้าโปรดสั่งซื้อ ทันทีภายใน 24 ชม.',
        'Every order must be pressed through youpik app only to receive the commission':
          'ทุกคำสั่งซื้อจะต้องกดผ่าน แอปยูพิคเท่านั้นเพื่อรับค่าคอมมิชชั่น',
        'Refund Rules': 'กฎการคืนสินค้า',
        'Refunds are not supported': 'ไม่สามารถคืนเงินได้',
        'Voucher Rules': 'กฎการใช้งาน',
        'one voucher can be used once only It also can be used in combination with shipping vouchers':
          'ใช้คูปองได้ครั้งละ 1 ใบ ใช้ร่วมกับคูปองลดค่าจัดส่งได้',
        'one voucher can be used at a time It also can be used in combination with discount vouchers':
          'ใช้คูปองได้ครั้งละ 1 ใบ ใช้ร่วมกับคูปองส่วนลดได้',
        'Buy Now': 'ช้อปเลย',
        canceled_on: 'ยกเลิกเมื่อ :',
        'Delivery Failed': 'จัดส่งไม่สำเร็จ',
        'Logistics exception': 'ข้อยกเว้นด้านขนส่ง',
        'Delivery failed and returned to the merchant':
          'การจัดส่งพัสดุไม่สำเร็จ อยู่ในระหว่างการคืนสินค้าให้กับร้านค้า',
        'Logistics lost packages': 'พัสดุสูญหายระหว่างขนส่ง',
        reason_sc_ops_1: 'ไม่มีสต็อก',
        reason_sc_ops_2: 'ราคาไม่ถูกต้อง',
        reason_sc_ops_3: 'เหตุผลอื่นๆ',
        reason_sc_ops_9: 'ข้อมูลที่อยู่การจัดส่งไม่เหมาะสมกับฟอร์แมต',
        reason_sc_ops_80: 'ลูกค้ายกเลิกงาน',
        reason_sc_ops_83: 'ติดต่อลูกค้าไม่ได้',
        reason_sc_ops_85: 'ไม่พบเบอร์โทรศัพท์ของผู้ส่ง',
        reason_sc_ops_90: 'สินค้าไม่พร้อมจำหน่าย',
        reason_sc_ops_91: 'แพคเกจไม่เหมาะสมกับมาตรฐานการขนส่ง',
        reason_sc_ops_95: 'สินค้าเข้าระบบล่าช้าเนื่องจากรถขนส่ง/พนักงานไม่เพียงพอ',
        reason_sc_ops_98: 'ที่อยู่จัดส่งผิด',
        reason_sc_ops_99: 'แพคเกจไม่เหมาะสมกับเงื่อนไขการจัดส่ง: สินค้าเกินขนาด',
        reason_sc_ops_100: 'แพคเกจไม่เหมาะสมกับเงื่อนไขการจัดส่ง :สินค้าผิดกฎหมาย',
        reason_sc_ops_105: 'แพคเกจเสียหายภายนอก',
        reason_app_1: 'เปลี่ยนวิธีการชำระเงิน',
        reason_app_2: 'ระยะเวลาในการจัดส่งนานเกินไป',
        reason_app_3: 'คำสั่งซื้อซ้ำ',
        reason_app_4: 'เปลี่ยนที่อยู่จัดส่ง',
        reason_app_5: 'เปลี่ยนใจ',
        reason_app_6: 'เลือกสินค้าอื่น',
        reason_app_7: 'ค่าส่งแพงเกินไป',
        reason_app_8: 'เจอสินค้าที่ถูกกว่าในแพลตฟอร์มอื่น',
        Orders: 'ออเดอร์',
        'Merchant delivery time out You can buy again':
          'ร้านค้าจัดส่งไม่ทันเวลา กรุณากดสั่งอีกครั้ง',
        receive_wrong_item_wrong_size_wrong_color: 'ได้รับสินค้าไม่ตรงตามที่สั่ง',
        missing_item_accessory_freebie:
          'ได้รับสินค้าไม่ครบ/ขาดชิ้นส่วนในการประกอบสินค้า/ไม่ได้รับของแถม',
        damange_faulty_item: 'สินค้าเสียหาย/ไม่สามารถใช้งานได้',
        dont_want_the_item_or_it_does_not_suit_me:
          'ไม่ต้องการสินค้านี้แล้ว หรือ สินค้าไม่เหมาะกับฉัน',
        item_doesnot_match_description_pictures: 'สินค้าไม่ตรงตามรูปภาพ/รายละเอียดที่โฆษณาไว้',
        counterfeit_item: 'สันนิษฐานว่าเป็นสินค้าลอกเลียนแบบ',
        product_expired: 'สินค้าหมดอายุ',
        All: 'ทั้งหมด',
        Beauty: 'ความงาม',
        'Digital Goods': 'สินค้าดิจิทัล',
        'Electronic Devices': 'เครื่องใช้ไฟฟ้า',
        Fashion: 'เสื้อผ้าและแฟชั่น',
        'General Merchandise': 'ของใช้ในบ้าน',
        Groceries: 'ของกินของใช้',
        Health: 'สุขภาพ',
        'Mother & Baby': 'แม่และเด็ก',
        Others: 'สินค้าอื่นๆ',
        'Please youpikker click here to log in': 'Please youpikker click here to log in',
        'The commission is only an estimated commission, the actual commission is subject to the order information':
          'ค่าคอมมิชชั่นที่แสดงเป็นเพียงค่าคอมมิชชั่นโดยประมาณ ค่าคอมมิชชั่นที่ได้รับจะขึ้นอยู่กับราคาสินค้า และรายละเอียดที่สั่งซื้อ ณ เวลานั้น',
        reason_app_9: 'การสั่งซื้อของคุณถูกตัดสินว่าเป็นคำสั่งซื้อไม่ถูกต้องโดยระบบ',
        refund_on_its_way: 'กำลังทำการคืนเงิน',
        refund_on_its_way2: 'การดำเนินการคืนเงิน',
        add_bank_account: 'เพิ่มบัญชีธนาคาร',
        Utilities: 'จ่ายบิล',
        'Top-ups': 'เติมเงินมือถือ',
        'At least 2 commission': 'รับค่าคอมมิชชั่นสูงสุด 2%',
        'Only Youpikker can get commission': 'ค่าคอมมิชชั่นสำหรับยูพิคเกอร์ เท่านั้น',
        'Click the button below to enter Lazada Top-up page':
          'คลิกที่ปุ่ม “จ่ายบิล” ด้านล่าง เพื่อเข้าสู่ Lazada',
        'For each click, only the first order can get the commission. If you need to spend multiple times, please re-enter Youpik product detail page and click the button below to jump again.':
          'เข้าผ่าน Youpik Ultimate ใหม่ทุกครั้ง เมื่อต้องการจ่ายบิล (หากคุณต้องการจ่ายบิลเพิ่ม กรุณาไปที่หน้า “จ่ายบิล” บน Youpik Ultimate อีกครั้งเพื่อรับเงินคืนสำหรับบิลถัดไป)',
        'Orders will be displayed in Youpikker-Sale History within 48 hours.':
          'งินคืนจากการจ่ายบิลจะแสดงในหน้าบัญชี หัวข้อ   “รอดำเนินการ” ภายใน 48 ชั่วโมง ',
        'The confirmed commission amount is subject to the display in the order.':
          'เงินคืนจะได้รับหลังจากสถานะคำสั่งซื้อสำเร็จ ',
        'Top-up Now': 'เติมเงินมือถือ',
        'You can get a minimum 2 commission for all payment on Lazada from Youpik.':
          'คุณจะได้รับเงินคืนสำหรับทุก ๆ การจ่ายบิลบนแอป Lazada ที่เข้าผ่านแอป Youpik ',
        'Guide Steps:': 'ขั้นตอนการจ่ายบิล',
        'Click the Top-up Now button': 'คลิ๊กที่เติมเงิน',
        'Select Top-up&E-voucher': 'เลือกเติมเงิน & ดีลออนไลน์',
        'Checkout Now': 'จ่ายบิล',
        'Upgrade Now': 'อัพเกรด',
        'Login Now': 'เข้าสู่ระบบ',
        'Select Utilities': 'ชำระเงิน',
        'Click the Checkout Now button': 'คลิกที่สัญลักษณ์ จ่ายบิล & ดีลออนไลน์',
        'FAQ & Customer Service': 'คำถามที่พบบ่อยและบริการลูกค้า',
        'best seller in': 'สินค้าขายดีในหมวดหมู่',
        Categories: 'หมวดหมู่',
        'Whole Bestsellers': 'สินค้าขายดี',
        'The product you searched for does not exist': 'ไม่มีสินค้าที่คุณค้นหา',
        no_data: 'ไม่มีข้อมูล',
        h5_download_app: 'ดาวน์โหลด Youpik รับเงินคืนสูงสุด 37% ',
        'Download uChoice Pro': 'ดาวน์โหลด uChoice Pro เลย!',
        'You will be directed to the App Store or Google Play Store. Please download uChoice Pro now.':
          'เราจะพาคุณไปยัง App Store หรือ Google Play Store เพื่อดาวน์โหลด uChoice Pro',
        'Comm.': 'ค่าคอม',
        'Request Sample': 'ขอสินค้าตัวอย่าง',
        'No more products': 'ไม่พบสินค้า',
        Commm: `ค่าคอม`,
        'Com. Rate': 'ค่าคอม',
        DOs: 'แนะนำ',
        DONTs: `ข้อห้าม`,
        Disagree: 'ไม่ยอมรับ',
        'It will affect the usage of Youpik Ultimate APP after you confirm to disagree the document':
          'หากคุณไม่ยอมรับนโยบายความเป็นส่วนตัว จะส่งผลต่อการใช้งาน Youpik Ultimate',
        'If you have any questions, please contact: 02-3295929':
          'หากมีข้อสงสัย กรุณาติดต่อ ：02-3295929'
      },
      ...venueTh,
      ...promptPayTh,
      ...resultTh,
      ...orderTh,
      ...itemTh,
      ...activityTh,
      ...loginTh,
      ...lazadaTh,
      ...tiktokTh,
      ...rechargeTh,
      ...toolsGalTh,
      ...bypTh,
      ...toolsHomeTh,
      ...toolsLazadaTh,
      ...couponTh,
      ...toolsGlobalTh,
      ...toolsCommonTh,
      ...toolsTemplateTh,
      ...toolsRevisionTh,
      ...selectionTh,
      ...distTh,
      ...commonTh,
    },
    vi: {
      ...{
        'remote area': 'Khu vực xa',
        'Note The range of remote areas is provided by the logistics partner Flash':
          'Lưu ý: *Phạm vi khu vực xa được cung cấp bởi đối tác logistics Flash',
        'Some goods and activities do not include free shipping in remote areas':
          'Một số hàng hóa và hoạt động không bao gồm vận chuyển miễn phí đến khu vực xa.',
        search: 'Tìm kiếm',
        inviteCode_toast: 'Vui lòng điền mã mời hợp lệ',
        success: 'Thành công!',
        please_enter_account: 'vui lòng nhập tài khoản',
        please_enter_password: 'vui lòng nhập mật khẩu',
        please_enter_mobile: 'vui lòng nhập số điện thoại',
        please_enter_code: 'vui lòng nhập mã',
        loading: 'Đang tải',
        password: 'Mật khẩu',
        sms: 'SMS',
        account_number: 'Số tài khoản',
        login: 'Đăng nhập',
        mobile_number: 'Số điện thoại',
        sms_code: 'Mã SMS',
        code: 'Mã',
        send: 'GỬI',
        next: 'Tiếp theo',
        forgot_password: 'Quên mật khẩu?',
        sign_up_now: 'Đăng ký ngay',
        for_you: 'Dành cho bạn',
        sales: 'Khuyến mãi',
        earn: 'Kiếm tiền',
        earn_l: 'Kiếm tiền',
        price: 'Giá',
        add_to_cart_to_shop_on: 'Thêm vào giỏ hàng để mua sắm trên',
        box_content: 'Nội dung hộp',
        item_id: 'Mã sản phẩm',
        get_voucher: 'Nhận voucher',
        collect: 'Lưu',
        delivery_options: 'Tùy chọn giao hàng',
        delevery_service: 'Dịch vụ giao hàng',
        cash_on_delivery_available: 'Có sẵn thanh toán khi nhận hàng',
        delevery_fee: 'Phí giao hàng',
        days: 'ngày',
        hi_my_firend_more:
          'Chào bạn, nhận voucher độc quyền cho đơn hàng đầu tiên của bạn! Nhận thêm voucher 30.- trên ứng dụng Youpik!',
        all_items_are_available: 'Tất cả sản phẩm có sẵn!',
        continue_with_facebook: 'Tiếp tục với Facebook',
        login_with_phone: 'Đăng nhập bằng điện thoại',
        login_with_line: 'Đăng nhập bằng Line',
        privacy_policy: 'Chính sách bảo mật',
        change_language: 'Thay đổi ngôn ngữ',
        english: 'Tiếng Anh',
        thai: 'Tiếng Thái',
        country_vi: 'Việt Nam',
        need_help: 'Cần giúp đỡ!',
        quantity: 'Số lượng',
        buy_now: 'Mua ngay',
        inviteCode: 'Mã mời',
        search_records: 'Lịch sử tìm kiếm',
        no_products_found: 'Không tìm thấy sản phẩm',
        please_try_other_keywords: 'Vui lòng thử các từ khóa khác',
        pick_for_you: 'Lựa chọn cho bạn',
        add_address: 'Thêm địa chỉ',
        edit: 'Chỉnh sửa',
        default_shipping_address: 'Địa chỉ giao hàng mặc định',
        default_billing_address: 'Địa chỉ thanh toán mặc định',
        no_provice: 'không tỉnh',
        no_district: 'không quận',
        no_postcode: 'không mã bưu điện',
        no_address: 'không địa chỉ',
        no_fullname: 'không họ tên',
        no_phone: 'không số điện thoại',
        the_length_of_the_phone_number_cannot_be_less_than_9:
          'Độ dài số điện thoại không được ngắn hơn 10 ký tự',
        select_a_label_for_effective_delivery: 'Chọn nhãn để giao hàng hiệu quả',
        for_me: 'CHO TÔI',
        for_client: 'CHO KHÁCH HÀNG',
        province: 'Tỉnh',
        district: 'Quận',
        postCode: 'Mã bưu điện',
        make_default_shipping_address: 'Đặt làm địa chỉ giao hàng mặc định',
        make_default_billing_address: 'Đặt làm địa chỉ thanh toán mặc định',
        save: 'Lưu',
        save_more: 'Tiết kiệm hơn',
        earn_more: 'Kiếm nhiều hơn',
        top_picks: 'Lựa chọn hàng đầu',
        seven_days_easy_return: 'Hoàn trả dễ dàng trong 7 ngày',
        please_select: 'Vui lòng chọn',
        no_stock: 'Hết hàng',
        product_options: 'Tùy chọn sản phẩm',
        delevery: 'Giao hàng',
        specifications: 'Thông số kỹ thuật',
        brand: 'Thương hiệu',
        products: 'Sản phẩm',
        all_products: 'Tất cả sản phẩm',
        people_who_viewed_this_item_also_viewed: 'Những người đã xem mục này cũng đã xem',
        youpiks_promise: 'Cam kết của Youpik',
        highlights: 'Điểm nổi bật',
        minishop_editors_choice: 'Lựa chọn của biên tập viên Minishop',
        please_enter_invitation_code: 'Vui lòng nhập mã mời',
        wrong_invitation_code: 'Mã mời không chính xác',
        bind_success: 'Liên kết thành công',
        is_this_your_inviter: 'Đây có phải là người mời bạn không?',
        invitation_code: 'Mã mời',
        cancel: 'Hủy',
        confirm: 'Xác nhận',
        welcome: 'CHÀO MỪNG',
        you_are_invited_by_a_friend_to_join_youpik_community_please_enter_your_friend_invitation_code:
          'Bạn được một người bạn mời tham gia cộng đồng Youpik. Vui lòng nhập mã mời của bạn bè bạn',
        no_invitation_from_a_friend_see: 'Không có lời mời từ bạn bè? Xem',
        default_code: 'Mã mặc định',
        the_two_passwords_are_inconsistent: 'Hai mật khẩu không khớp',
        please_set_password: 'Vui lòng đặt mật khẩu',
        we_will_send_a_one_time_SMS_code_to_your_mobile_number:
          'Chúng tôi sẽ gửi mã SMS một lần đến số điện thoại của bạn',
        please_enter_your_new_password_below: 'Vui lòng nhập mật khẩu mới của bạn dưới đây',
        new_password: 'Mật khẩu mới',
        retype_password: 'Nhập lại mật khẩu',
        verify_code: 'Mã xác minh',
        reset_password: 'Đặt lại mật khẩu',
        to_pay: 'Để thanh toán',
        to_ship: 'Để giao hàng',
        to_receive: 'Để nhận hàng',
        to_review: 'Để đánh giá',
        hi_welcome_to_youpik: 'Chào bạn, chào mừng đến với Youpik',
        signup: 'Đăng ký',
        my_purchase_history: 'Lịch sử mua hàng của tôi',
        view_all: 'Xem tất cả',
        logout: 'Đăng xuất',
        no_select_item: 'Không chọn sản phẩm',
        no_select_reason: 'Không chọn lý do',
        please_select_reason: 'Vui lòng chọn lý do',
        cancellation_reason: 'Lý do hủy',
        detailed_explanation_of_selected_reason: 'Giải thích chi tiết lý do đã chọn',
        submit: 'Gửi',
        package_one_of_one: 'Gói 1 của 1',
        shipped_by_seller_name: 'Giao hàng bởi người bán',
        choose_your_delivery_option: 'Chọn tùy chọn giao hàng của bạn',
        promo_saved: 'Khuyến mãi đã lưu',
        one_item_total: 'Sản phẩm, Tổng cộng',
        subtotal_one_item: 'Tổng cộng',
        shipping_fee: 'Phí giao hàng',
        shipping_fee_discount: 'Giảm phí giao hàng',
        total: 'Tổng cộng',
        GST_applied_where_applicable: 'Áp dụng GST khi có thể',
        place_order: 'Đặt hàng',
        bought_by: 'Mua bởi',
        shipping_address: 'Địa chỉ giao hàng',
        billing_address: 'Địa chỉ thanh toán',
        order_no: 'Số đơn hàng',
        package: 'Gói hàng',
        subtotal: 'Tổng phụ',
        item: 'sản phẩm',
        confirm_cancel: 'Xác nhận hủy?',
        order: 'Đơn hàng',
        placed_on: 'Đặt vào',
        items: 'Sản phẩm',
        pay_now: 'Thanh toán ngay',
        pay_code_tip:
          'Bảo vệ thanh toán của Lazada bao gồm các giao dịch không được phép sử dụng thông tin tài khoản Lazada của bạn (Điều khoản & Điều kiện áp dụng)',
        recommended_method: 'Phương pháp được đề xuất',
        credit_debit_card: 'Thẻ tín dụng/Ghi nợ',
        trueMoney_wallet: 'Ví TrueMoney',
        payment_method: 'Phương thức thanh toán',
        cash_on_delivery: 'Thanh toán khi nhận hàng',
        total_amount: 'Tổng số tiền',
        please_wait: 'Vui lòng chờ',
        you_can_pay_in_cash_to_our_courier_when_you_receive_the_goods_at_your_doorstep:
          'Bạn có thể thanh toán bằng tiền mặt cho nhân viên giao hàng khi nhận hàng tại cửa.',
        pay_with_your_trueMoney_account_make_sure_you_have_enough_balance_in_your_account:
          'Thanh toán bằng tài khoản TrueMoney của bạn. Đảm bảo bạn có đủ số dư trong tài khoản của mình',
        confirm_order: 'Xác nhận đơn hàng',
        save_money: 'Tiết kiệm tiền',
        earn_money: 'Kiếm tiền',
        no_capital: 'Không có vốn',
        effortless_earning: 'Kiếm tiền dễ dàng',
        payment_success: 'Thanh toán thành công',
        your_order_number_is: 'Số đơn hàng của bạn là',
        payment_failed: 'Thanh toán thất bại',
        for_more_details_track_your_delivery_status_under: 'Để biết thêm chi tiết, theo dõi trạng thái giao hàng của bạn tại',
        my_account_my_order: 'Tài khoản của tôi > Đơn hàng của tôi',
        view_order: 'Xem đơn hàng',
        we_ve_sent_a_confirmation_email_to: 'Chúng tôi đã gửi email xác nhận đến',
        join_as_youpikker_and_start_to_earn_today: 'Tham gia làm Youpikker và bắt đầu kiếm tiền ngay hôm nay!',
        manage_selections: 'Quản lý lựa chọn',
        special_for_you_shop_now: 'Đặc biệt dành cho bạn - Mua sắm ngay!',
        download_APP_to_get_30_30_vouchers: 'Tải ứng dụng để nhận voucher 30+30',
        best_selling_products: 'Sản phẩm bán chạy nhất',
        enjoy_cash_back_when_you_buy: 'Nhận tiền hoàn lại khi bạn mua',
        earn_incentives_when_you_share_successfully: 'Kiếm thưởng khi bạn chia sẻ thành công',
        more_benefits_await_as_you_grow_your_membership_level_from_basic_to_super_and_partner:
          'Nhiều lợi ích hơn đang chờ bạn khi bạn nâng cấp cấp độ thành viên từ Basic, lên Super và Partner',
        buy_vip_products: 'Mua sản phẩm VIP',
        download_or_open_youpik_application: 'Tải xuống hoặc mở ứng dụng Youpik',
        start_sharing_and_earning_now: 'Bắt đầu chia sẻ và kiếm tiền ngay!',
        vip_products: 'Sản phẩm VIP',
        purchase_these_exclusively_curated_products_to_be_a_member_today:
          'Mua những sản phẩm được chọn lọc độc quyền này để trở thành thành viên hôm nay',
        discount_fee: 'Phí giảm giá',
        order_details: 'Chi tiết đơn hàng',
        receiver: 'Người nhận',
        track_num: 'Số theo dõi',
        delivery_partner: 'Đối tác giao hàng',
        return_reason: 'Lý do trả hàng',
        reason: 'Lý do',
        return_to: 'Trả lại cho',
        return_request_title: 'Chính sách trả hàng/Hoàn tiền',
        return_request_tx:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis',
        return_request_tx2: '1. Quyết định trả hàng/hoàn tiền sẽ dựa trên các bình luận và hình ảnh bạn cung cấp',
        return_request_tx3: '2. In nhãn/biểu mẫu trả hàng trước. Công ty giao hàng có thể không chấp nhận gói trả hàng của bạn nếu không có nhãn',
        return_request_tx4: '3. "Thay đổi ý định" chỉ áp dụng cho các sản phẩm được chọn. Những sản phẩm này phải còn nguyên seal',
        got_it: 'Đã hiểu',
        upload_image: 'Tải lên hình ảnh',
        by_submit: 'Bằng cách gửi biểu mẫu này, tôi chấp nhận Chính sách trả hàng/hoàn tiền của Youpik.',
        total_refund: 'Tổng hoàn tiền',
        return_request_1: 'Yêu cầu trả hàng (1/2)',
        return_request_2: 'Yêu cầu trả hàng (2/2)',
        return_request2_title: 'Xác nhận giao hàng',
        return_request2_tx: 'Yêu cầu trả hàng chỉ có thể thực hiện sau khi bạn nhận được hàng. Bạn đã nhận được hàng chưa?',
        continue: 'Tiếp tục',
        change: 'Thay đổi',
        no_bank: 'Hoàn tiền qua chuyển khoản ngân hàng',
        item_price: 'Giá sản phẩm',
        expected_total: 'Tổng hoàn tiền dự kiến',
        bank_transfer: 'Chuyển khoản ngân hàng',
        select_a_bank: 'Chọn ngân hàng',
        account_name: 'Tên tài khoản',
        account_name_pl: 'Nhập tên tài khoản ngân hàng của bạn',
        account_number_pl: 'VD. 12344567890983',
        my_returens: 'Trả hàng/Hoàn tiền của tôi',
        my_cancel: 'Hủy của tôi',
        account: 'Tài khoản',
        setting: 'Cài đặt',
        returns: 'Trả hàng',
        cancellations: 'Hủy',
        store: 'Cửa hàng',
        benefits: 'Lợi ích khi trở thành VIP',
        how_to_vip: 'Cách trở thành VIP',
        order_list: 'Danh sách đơn hàng',
        all: 'Tất cả',
        return: 'TRẢ HÀNG',
        track_package: 'Theo dõi gói hàng',
        cancel_request: 'Yêu cầu hủy',
        order_confirm: 'Xác nhận đơn hàng',
        select_payment_method: 'Chọn phương thức thanh toán',
        order_received: 'Đơn hàng đã nhận',
        address_list: 'Danh sách địa chỉ',
        address_manage: 'Quản lý địa chỉ',
        full_name: 'Họ và tên',
        address: 'Địa chỉ',
        phone_number: 'Số điện thoại',
        added: 'Đã thêm',
        add_to_my_shop: 'Thêm vào cửa hàng của tôi',
        home: 'Trang chủ',
        my_account: 'Tài khoản của tôi',
        my_orders: 'Đơn hàng của tôi',
        set_password: 'Đặt mật khẩu',
        seven_days_easy_return2: 'Trả hàng dễ dàng trong 7 ngày',
        order_empty: 'Chưa có đơn hàng nào được đặt.',
        order_empty_btn: 'Tiếp tục mua sắm',
        voucher_for_you: 'Voucher dành cho bạn!',
        sorry: 'Xin lỗi!',
        noItem_text: 'Sản phẩm này hiện không còn có sẵn',
        back_to_homepage: 'Quay lại trang chủ',
        dps: 'dbs',
        standard_delivery2: 'Giao hàng tiêu chuẩn',
        days2: '(3-8 ngày)',
        days3: '(10-15 ngày)',
        dpsDays: 'Giao hàng bởi người bán',
        no_pdf_msg: 'Trình duyệt này không hỗ trợ tệp PDF, vui lòng nhấp để xem:',
        download_pdf: 'Tải PDF',
        daily_deals: 'Ưu đãi hàng ngày',
        more: 'Xem thêm',
        sold: 'Đã bán',
        ProductID: 'ID sản phẩm',
        goods_sold_out: 'Hàng hóa đã hết',
        start_in: 'Bắt đầu trong',
        end_in: 'Kết thúc trong',
        or_continue_with: 'hoặc tiếp tục với',
        chat_now: 'Nhắn tin ngay',
        packing_time: 'Thời gian đóng gói',
        pay_time: 'Thời gian thanh toán',
        ends_in: 'Kết thúc trong',
        left: 'còn lại',
        sold_out: 'Hết hàng',
        app_title: 'Ứng dụng Youpik',
        uchoice_title: 'Ứng dụng uChoice',
        app_tx1: 'Trải nghiệm mới với Thương mại xã hội',
        app_tx2: 'Mua để tiết kiệm, Chia sẻ để kiếm tiền',
        uchoice_tx1: 'Công cụ chọn sản phẩm TikTok Creator',
        app_tx3: 'Nhấp để tải xuống và mua sắm ngay!',
        app_dowload: 'Tải xuống tại',
        app_android: 'Android',
        app_appStore: 'App Store',
        app_tx4: `Vui lòng mở liên kết này bằng trình duyệt mặc định của điện thoại và nhấp vào nút tải xuống`,
        copy_success: 'Sao chép thành công!',
        notice: 'Thông báo',
        notice_msg:
          'Do tình hình dịch bệnh COVID-19, việc vận chuyển hàng hóa ở nhiều khu vực bị chậm trễ. Chúng tôi xin lỗi vì sự bất tiện này. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với bộ phận chăm sóc khách hàng của Youpik qua ứng dụng, bằng cách nhấp vào trang tài khoản và tin nhắn.',
        no_stock_good: 'Hàng không có sẵn',
        activity: 'Hoạt động',
        activity_end_msg: 'Bạn đã trễ. Sự kiện đã kết thúc',
        exceeds_product_limit: 'Vượt quá giới hạn sản phẩm',
        cross_border: 'Xuyên biên giới',
        cross_ship: 'Giao hàng từ nước ngoài',
        share_to_earn: 'Chia sẻ để kiếm tiền',
        end_time: 'Kết thúc trong',
        Real_time_leaderboar: 'Bảng xếp hạng thời gian thực',
        Campaign_leaderboard: 'Bảng xếp hạng chiến dịch',
        Estimated_rewards: 'Phần thưởng ước tính',
        min_spend: 'Chi tiêu tối thiểu',
        valid_till: 'Có giá trị đến',
        youpik_birthday: 'Lễ kỷ niệm sinh nhật lần thứ 2 của Youpik',
        acTab1: '11% Hoa hồng',
        acTab2: '111 Hoa hồng',
        acTab3: '1 Ưu đãi',
        acTab4: '111 Ưu đãi',
        acTab121: '12% Hoa hồng',
        acTab122: '112 Hoa hồng',
        acTab123: 'YPC 222 đặc biệt',
        acTab124: '112.- Ưu đãi',
        acTab125: '12 Ưu đãi',
        coming_soon: 'Sắp có',
        ac_no_token: 'Vui lòng truy cập trang cài đặt để nâng cấp lên phiên bản mới nhất để nhận',
        city: 'Thành phố',
        no_city: 'không có thành phố',
        for_shop: 'Để mua sắm tại Youpik',
        cancel_reason: 'Lý do hủy',
        cancel_item: 'Sản phẩm bị hủy',
        track_progress: 'Theo dõi tiến độ',
        cancel_info: 'Thông tin hủy',
        order_num: 'Số đơn hàng',
        request_on: 'Yêu cầu vào ngày',
        supplied_by: 'Cung cấp bởi',
        chat: 'Nhắn tin',
        refund_on: 'Hoàn tiền đang trên đường!',
        refunded: 'Đã hoàn tiền',
        refund_reject: 'Hoàn tiền bị từ chối',
        refund_msg1: 'Yêu cầu hoàn tiền của bạn đã được phê duyệt, vui lòng chờ trong khi hệ thống của chúng tôi đang xử lý yêu cầu hoàn tiền của bạn.',
        refund_msg2: 'Yêu cầu hoàn tiền của bạn đã hoàn tất',
        refund_msg3: 'Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng',
        refund_reason: 'Lý do hoàn tiền',
        refund_time: 'Thời gian hoàn tiền',
        refund_amount: 'Số tiền hoàn tiền',
        refund_method: 'Phương thức hoàn tiền',
        related_product: 'Sản phẩm liên quan',
        view_history: 'Xem lịch sử liên lạc',
        returned_on: 'Trả lại vào ngày',
        refund_on_its: 'Hoàn tiền đang trên đường',
        thank_you: 'Cảm ơn bạn!',
        for_our: 'Đối với việc hủy của chúng tôi',
        returns_refund: 'Trả hàng/Hoàn tiền',
        sold_by_redmart: 'Bán bởi',
        live_title1: 'Live Sales là gì?',
        live_title2: 'Cách sử dụng Live Sales',
        live_ms1: 'Live Sales là tính năng dành cho Youpikker hỗ trợ quảng bá sản phẩm trong ứng dụng Youpik thông qua kênh phát trực tiếp. Youpikker có thể tạo danh sách sản phẩm của riêng mình và kết nối với Facebook Live, sau đó hệ thống Youpik sẽ tự động liên kết sự kiện với Facebook. Tính năng này sẽ giúp Youpikker dễ dàng kiếm được nhiều hoa hồng hơn!',
        live_ms2: '1. Nhấp vào biểu tượng Live Sales và tạo danh sách sản phẩm',
        live_ms3: '2. Chọn sản phẩm (Tối đa 200 sản phẩm) và tạo danh sách sản phẩm',
        live_ms4: '3. Kết nối danh sách sản phẩm với Facebook Live',
        live_ms5: '4. Khi kết nối thành công, Youpikker có thể bắt đầu phát trực tiếp ngay lập tức',
        cancelled: 'Đã hủy',
        your: 'Của bạn',
        item_has: 'Sản phẩm đã bị hủy',
        select: 'Chọn',
        order_has: 'Đơn hàng đã bị hủy, xem các đơn hàng khác',
        home_page: 'Trang Chủ',
        cancel_detail: 'Chi tiết hủy',
        refund_detail: 'Chi tiết hoàn tiền',
        your_refund_ms: 'Yêu cầu hoàn tiền của bạn sẽ được xử lý trong 1-2 ngày làm việc. Bạn có thể theo dõi tiến độ hoàn tiền trong danh sách Trả hàng/Hoàn tiền.',
        unpaid: 'Chưa thanh toán',
        refund: 'HOÀN TIỀN',
        number: 'Số',
        refund_type: 'Phương thức hoàn tiền',
        i_accept: 'Tôi chấp nhận',
        refund_agree: 'thỏa thuận hoàn tiền',
        bank_name: 'Tên Ngân Hàng',
        bank_code: 'Mã Ngân Hàng',
        refund_isMsg: 'Số tiền thực tế thanh toán tại thời điểm đặt hàng',
        progress1: 'Nộp Đơn',
        progress2: 'Người bán xử lý',
        progress3: 'Đang xử lý',
        progress4: 'Hoàn tất',
        flash_express: 'Flash Express',
        thailand_post: 'Bưu Điện Thái Lan',
        scg_express: 'SCG EXPRESS',
        alpha_fast: 'Alpha Fast',
        lalamove: 'Lalamove',
        kerry_express: 'Kerry Express',
        line_man: 'Line Man',
        grab_express: 'Grab Express',
        tnt_express: 'TNT Express',
        ltd: 'I.T. TRANSPORT.CO.,LTD',
        niko: 'Niko’s Logistics',
        nim_express: 'Nim Express',
        dhl_express: 'DHL Express',
        ninjavan: 'ninjavan',
        skootar: 'Skootar',
        jt_express: 'J&T Express',
        speed_d: 'Speed - D',
        best_express: 'Best Express',
        applying: 'Yêu cầu hoàn tiền',
        processing: 'Đang xử lý',
        processed: 'Hoàn tất',
        date: 'Ngày',
        you_can_before: 'Bạn có thể yêu cầu sau bán hàng trước',
        as_alone_as: 'Chỉ cần trong vòng 7 ngày sau khi bán, bạn có thể yêu cầu lại',
        logistics_name: 'Tên dịch vụ vận chuyển',
        tracking_number: 'Số theo dõi',
        please_enter_tracking_number: 'Vui lòng nhập số theo dõi',
        sender_phone: 'Số điện thoại người gửi',
        please_enter: 'Vui lòng nhập',
        courier_fees: 'Phí giao hàng',
        bank_msg1: 'Vui lòng cung cấp thông tin tài khoản ngân hàng',
        bank_msg2: 'Chỉ cho phép chữ cái và khoảng trắng',
        bank_msg3: 'Chỉ cho phép số, tối đa 15 chữ số',
        refund_failed: 'Hoàn tiền thất bại',
        refund_expired: 'Hoàn tiền đã hết hạn',
        in_shipping_process: 'Đang trong quá trình vận chuyển',
        please_waiting_for_seller_operation: 'Vui lòng chờ người bán thực hiện',
        please_return_item: 'Vui lòng trả lại sản phẩm',
        seller_confirm_receiving_item: 'Người bán xác nhận nhận hàng, hoàn tiền sẽ được xử lý trong vòng 7 ngày',
        please_wait_seller_confirm_receive_item_after_seller_confirm_you_will_get_the_refund: 'Vui lòng chờ người bán xác nhận nhận hàng, sau khi người bán xác nhận, bạn sẽ nhận được hoàn tiền',
        request_has_been_cancelled_contact_cs: 'Yêu cầu đã bị hủy, vui lòng liên hệ với dịch vụ khách hàng nếu có vấn đề',
        you_after_sale: 'Đơn yêu cầu sau bán hàng của bạn đã được gửi thành công, và người bán sẽ hoàn tất xem xét trong vòng 7 ngày',
        please_push_the: 'Vui lòng gửi hàng đến địa chỉ trả lại được chỉ định trong vòng 10 ngày, dự kiến yêu cầu hoàn tiền sẽ được đóng',
        done: 'Hoàn tất',
        seller_is_operating: 'Người bán đang xử lý',
        submit_request: 'Gửi yêu cầu',
        return_address: 'Địa chỉ trả hàng',
        shipping_label: 'Nhãn vận chuyển',
        shipping_bill: 'Hóa đơn vận chuyển',
        return_method: 'Phương thức trả hàng',
        select_shipping_company: 'Chọn công ty vận chuyển',
        request_created_time: 'Thời gian tạo yêu cầu',
        estimated_payment_amount: 'Số tiền thanh toán ước tính',
        actual_payment_amount: 'Số tiền thanh toán thực tế',
        after_the_merchant: 'Sau khi người bán đồng ý trả lại hàng, họ sẽ chịu chi phí vận chuyển tối đa',
        please_upload_ship_label: 'Vui lòng tải lên nhãn vận chuyển',
        please_upload_ship_bill: 'Vui lòng tải lên hóa đơn vận chuyển',
        i_agree_that: 'Tôi đồng ý rằng Youpik có thể thu thập, lưu trữ và xử lý thông tin tài khoản ngân hàng của tôi nhằm mục đích xử lý hoàn tiền',
        are_you_confirm: 'Bạn có xác nhận việc gửi không?',
        yes: 'Có',
        no: 'Không',
        please_confirm: 'Vui lòng xác nhận',
        donot_edit: 'Thông tin trả hàng không thể sửa đổi sau khi gửi',
        mem_title: 'Quy tắc và Thành viên Nâng cấp',
        mem_small_title: 'Quy tắc và Thành viên Nâng cấp của Youpik',
        mem_h3_1: '1. Cách Nâng cấp',
        mem_h4_1_a_t: '(a) Nâng cấp lên Youpikker Cơ bản',
        mem_h4_1_a_t1: 'Khách hàng có thể mua Youpik Choice để nâng cấp thành Youpikker Cơ bản.',
        mem_h4_1_a_t2: 'Thực hiện theo các bước dưới đây để nâng cấp: Quay lại Trang Youpikker -> Tìm các sản phẩm có nhãn Youpik Choice -> Chọn một Youpik Choice -> Mua Ngay',
        mem_h4_1_b_t: '(b) Nâng cấp lên Youpikker Cao cấp',
        mem_h4_1_b_t1: 'Khách hàng cần trở thành Youpikker Cơ bản. Các Youpikker Cơ bản cần mời 20 khách hàng trở thành Youpikker để nâng cấp lên Youpikker Cao cấp',
        mem_h4_1_b_t2: 'Thực hiện theo các bước dưới đây để nâng cấp:',
        mem_h4_1_b_t3: 'Quay lại Trang Youpikker -> Tìm các sản phẩm có nhãn Youpik Choice -> Chọn một Youpik Choice -> Chia sẻ Ngay',
        mem_h4_1_b_t4: 'HOẶC',
        mem_h4_1_b_t5: 'Chia sẻ mã mời của bạn -> Mời tải xuống ứng dụng Youpik -> Sử dụng mã mời của bạn để đăng ký -> Mua một Youpik Choice bằng chính bạn',
        mem_h4_1_c_t: '(c) Nâng cấp lên Youpikker Tối ưu',
        mem_h4_1_c_t1: 'Khách hàng cần trở thành Youpikker Cao cấp. Tổng số người của Youpikker Cao cấp và đội nhóm, bao gồm các Youpikker được mời và các cấp dưới của họ, là 500, Youpikker Cao cấp sẽ được nâng cấp lên Youpikker Tối ưu',
        mem_h4_1_c_t2: 'Thực hiện theo các bước dưới đây để nâng cấp:',
        mem_h4_1_c_t3: 'Quay lại Trang Youpikker -> Tìm các sản phẩm có nhãn Youpik Choice -> Chọn một Youpik Choice -> Chia sẻ Ngay',
        mem_h4_1_c_t4: 'HOẶC',
        mem_h4_1_c_t5: 'Chia sẻ mã mời của bạn -> Mời tải xuống ứng dụng Youpik -> Sử dụng mã mời của bạn để đăng ký -> Mua một Youpik Choice bằng chính bạn',
        mem_h3_2: '2. Thành viên Youpikker',
        mem_h3_2_header1: 'Thành viên',
        mem_h3_2_header2: 'Khách hàng',
        mem_h3_2_header3: 'Cơ bản',
        mem_h3_2_header4: 'Cao cấp',
        mem_h3_2_header5: 'Tối ưu',
        mem_h3_2_td1: 'Quyền lợi Mall Cơ bản',
        mem_h3_2_td2: 'Hoa hồng% - Bán hàng trực tiếp (Sản phẩm bình thường)',
        mem_h3_2_td3: 'Hoa hồng% - Bán hàng trực tiếp (Youpik Choice)',
        mem_h3_2_td4: 'Hoa hồng% - Bán hàng gián tiếp (Sản phẩm bình thường)',
        mem_h3_2_td5: 'Hoa hồng% - Bán hàng gián tiếp (Youpik Choice)',
        mem_h3_2_td6: 'Phần thưởng Nhiệm vụ Youpikker',
        mem_h3_2_td7: 'Công cụ Live miễn phí',
        mem_h3_2_td8: 'Phần thưởng cùng cấp',
        mem_h3_2_td9: 'Hoạt động Youpik',
        mem_h3_3: '3. Quy tắc Tính toán Hoa hồng',
        mem_h3_3_a: '(a) Hoa hồng Youpik Choice',
        mem_h3_3_a_t1: '- Hoa hồng Youpik Choice%',
        mem_h3_3_a_t1_header1: 'Loại Youpik Choice',
        mem_h3_3_a_t1_header2: 'Hoa hồng%',
        mem_h3_3_a_t2: '- Cách tính hoa hồng?',
        mem_h3_3_a_t2_header1: 'Loại Youpik Choice',
        mem_h3_3_a_t2_header2: 'Youpikker Cơ bản',
        mem_h3_3_a_t2_header3: 'Youpikker Cao cấp',
        mem_h3_3_a_t2_header4: 'Youpikker Tối ưu',
        mem_h3_3_a_t2_td1: '₫999*60% *42%*(1-5%WHT) Thu nhập đã xác nhận ₫239.16',
        mem_h3_3_a_t2_td2: '₫999*60% *42%*(1-5%WHT) Thu nhập đã xác nhận ₫239.16',
        mem_h3_3_a_t2_td3: '₫999*60% *16%*(1-5%WHT) Thu nhập đã xác nhận ₫91.11',
        mem_h3_3_a_t2_td4: '₫999*60% *42%*(1-5%WHT) Thu nhập đã xác nhận ₫239.16',
        mem_h3_3_a_t2_td5: '₫999*60% *58%*(1-5%WHT) Thu nhập đã xác nhận ₫330.27',
        mem_h3_3_a_t2_td6: '₫999*60% *42%*2*(1-5%WHT) Thu nhập đã xác nhận ₫478.32',
        mem_h3_3_a_t2_td7: '₫999*60% *8%*(1-5%WHT) Thu nhập đã xác nhận ₫45.55',
        mem_h3_3_a_t2_td8: '₫999*60% *100%*(1-5%WHT) Thu nhập đã xác nhận ₫569.43',
        mem_h3_3_b: '(b) Hoa hồng Sản phẩm Bình thường',
        mem_h3_3_b_t1: '- Cách tính hoa hồng?',
        mem_h3_3_b_t2: 'Hoa hồng% của sản phẩm bình thường là 5% như thường lệ',
        mem_h3_3_b_t2_header1: 'Sản phẩm Bình thường',
        mem_h3_3_b_t2_header2: 'Youpikker Cơ bản',
        mem_h3_3_b_t2_header3: 'Youpikker Cao cấp',
        mem_h3_3_b_t2_header4: 'Youpikker Tối ưu',
        mem_h3_3_b_t2_td1: '₫1,000*5% *77%*(1-5%WHT) Thu nhập đã xác nhận ₫36.58',
        mem_h3_3_b_t2_td2: '₫1,000*5% *15%*(1-5%WHT) Thu nhập đã xác nhận ₫7.13',
        mem_h3_3_b_t2_td3: '₫1,000*5% *8%*(1-5%WHT) Thu nhập đã xác nhận ₫3.80',
        mem_h3_3_b_t2_td4: '₫1,000*5% *77%*(1-5%WHT) Thu nhập đã xác nhận ₫36.58',
        mem_h3_3_b_t2_td5: '₫1,000*5% *8%*(1-5%WHT) Thu nhập đã xác nhận ₫3.80',
        mem_h3_3_b_t2_td6: '₫1,000*5% *77%*(1-5%WHT) Thu nhập đã xác nhận ₫36.58',
        mem_bot_1: '**WHT là Thuế Thu Nhập Tạm Thời (Withholding Tax)**',
        mem_bot_2: '**Thu nhập hoa hồng sẽ bị thuế thu nhập tạm thời 5%**',
        mem_bot_3: 'Tất cả quyền giải thích thuộc về Youpik',
        just_for_you: 'Chọn Cho Bạn',
        hot_items: 'Sản Phẩm Hot',
        youpik_plus: 'YOUPIK PLUS',
        electric_devices: 'Thiết Bị Điện Tử',
        gm: 'Hàng Hóa Tổng Hợp',
        men_clothes: 'Thời Trang Nam',
        women_clothes: 'Thời Trang Nữ',
        beauty: 'Làm Đẹp',
        mother_baby: 'Mẹ & Bé',
        grocery: 'Thực Phẩm',
        fashion_accessories: 'Phụ Kiện Thời Trang',
        health: 'Sức Khỏe',
        others: 'Khác',
        novice_must_learn: 'Về Youpik',
        become_member: 'Tham Gia Làm Youpikker',
        limited_time_purchas: 'Khuyến Mãi Giới Hạn Thời Gian',
        live_sales: 'Bán Hàng Trực Tiếp',
        tab_sales: 'Bán Hàng',
        tab_new: 'Mới Nhất',
        tab_earn: 'Kiếm Tiền',
        tab_search: 'Lọc Nhanh',
        home_All: 'Tất Cả',
        home_Thailand: 'Địa Phương',
        home_China: 'Xuyên Biên Giới',
        quick_filter: 'Lọc Nhanh',
        shipping_from: 'Vận Chuyển Từ',
        price_area: 'Giá (₫)',
        highest_price: 'Giá Cao Nhất',
        lowest_price: 'Giá Thấp Nhất',
        home_confirm: 'Xác Nhận',
        home_reset: 'Đặt Lại',
        swipe_left_to_see_more: 'Vuốt sang trái để xem thêm',
        recommended_today: 'Đề Xuất Hôm Nay',
        delete: 'Xóa',
        confirm_delete_address_tip: 'Bạn có chắc chắn muốn xóa địa chỉ không?',
        confirm_text: 'Xác Nhận',
        cancel_text: 'Hủy',
        If_the_variation_name_does_not_match_the_picture: 'Nếu tên biến thể không khớp với hình ảnh, vui lòng tham khảo tên biến thể.',
        kingkong_lazada: 'High Com',
        'Search product': 'Tìm kiếm sản phẩm',
        'View before buying': 'Cách mua',
        'Products from Lazada': 'Sản phẩm từ Lazada',
        'invites you to be a Youpikker': 'mời bạn trở thành Youpikker',
        'Download Youpik APP': 'Tải xuống ứng dụng Youpik',
        'Apple users enter APP Store to search for Youpik, click to download.Android users click the link below to download': 'Người dùng Apple vào APP Store để tìm kiếm Youpik, nhấp để tải xuống. Người dùng Android nhấp vào liên kết dưới đây để tải xuống.',
        'Sign up/sign in': 'Đăng ký/Đăng nhập',
        'Enter the invitation code after registration': 'Nhập mã mời sau khi đăng ký',
        'Become a Youpikker': 'Trở thành Youpikker',
        'Go to the Youpikker page, buy any Youpik Plus product to become a Youpikker and start sharing and earning now~': 'Truy cập trang Youpikker, mua bất kỳ sản phẩm Youpik Plus nào để trở thành Youpikker và bắt đầu chia sẻ và kiếm tiền ngay bây giờ~',
        'Become a Youpikker as soon as payment is made': 'Trở thành Youpikker ngay sau khi thanh toán.',
        'Become a Youpkker and save': 'Trở thành Youpikker và tiết kiệm #replaceme#₫',
        Guide: 'Hướng Dẫn',
        CONTINUE: 'TIẾP TỤC',
        'The product information has changed please place an order again': 'Thông tin sản phẩm đã thay đổi, vui lòng đặt hàng lại',
        'Network request exception Please try again later': 'Ngoại lệ yêu cầu mạng. Vui lòng thử lại sau',
        'Shipping address cannot be empty': 'Địa chỉ giao hàng không thể trống',
        'User Privacy Policy': 'Chính sách Bảo mật Người dùng',
        'Youpik terms of use': 'Điều khoản sử dụng Youpik',
        'uChoice terms of use': 'Điều khoản sử dụng uChoice',
        'Terms Agree': 'Đồng ý Điều khoản',
        Agree: 'Đồng ý',
        'If you have any questions, please contact us: 02-3295929': 'Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với chúng tôi: 02-3295929',
        Withdraw: 'Rút',
        'Withdraw the agreement will affect the use of Youpik APP': 'Rút thỏa thuận sẽ ảnh hưởng đến việc sử dụng ứng dụng Youpik',
        'Agree Privacy Policy': 'Đồng ý Chính sách Bảo mật',
        'Due to the requirements of national laws and regulations, updated': 'Do yêu cầu của luật pháp và quy định quốc gia, cập nhật',
        and: 'và',
        kingkong_category: 'Danh Mục',
        'how can we help': 'Chúng tôi có thể giúp gì?',
        'Home Page': 'Trang Chủ',
        'Help Center': 'Trung Tâm Trợ Giúp',
        'Top Tutorials': 'Hướng Dẫn Hàng Đầu',
        Reviews: 'Đánh Giá',
        ViewAll: 'Xem Tất Cả',
        Review: 'Đánh Giá',
        Sortbydefault: 'Sắp xếp theo mặc định',
        Sortbylatest: 'Sắp xếp theo mới nhất',
        Therearenoitemstoreview: 'Chưa có đánh giá',
        Nocontent: 'Không có nội dung',
        'Network error, failed to save address information, please fill in again': 'Lỗi mạng, không thể lưu thông tin địa chỉ, vui lòng điền lại',
        Days: 'Ngày',
        standard_delivery_days: 'Giao hàng tiêu chuẩn (3-8 ngày)',
        standard_delivery_days_cross_border: 'Giao hàng tiêu chuẩn (25-30 ngày)',
        standard_delivery_days_cross_border_dbs: 'Giao hàng tiêu chuẩn (10-20 ngày)',
        优惠券购买成功: 'Bạn đã mua thành công voucher',
        '可在【Account-My Voucher】查看': 'Bạn có thể kiểm tra trong [Tài khoản - Voucher của tôi]',
        '请在“Youpik APP”中查看并使用': 'Vui lòng kiểm tra và sử dụng trong “Ứng dụng Youpik”',
        立即查看: 'Xem Ngay',
        Estimated: 'Dự Đoán Đơn Hàng',
        'GO TO APP': 'ĐI ĐẾN ỨNG DỤNG',
        'Ship from overseas': 'Vận chuyển từ nước ngoài (25-30 ngày)',
        'Ship from overseas dbs': 'Vận chuyển từ nước ngoài (10-20 ngày)',
        'Economy Delivery': 'Giao hàng tiết kiệm',
        'please read before ordering': 'vui lòng đọc trước khi đặt hàng',
        'When you view the product please order immediately within 24 hours': 'Khi bạn xem sản phẩm, vui lòng đặt hàng ngay trong vòng 24 giờ',
        'Every order must be pressed through youpik app only to receive the commission': 'Mỗi đơn hàng phải được thực hiện qua ứng dụng Youpik mới nhận được hoa hồng',
        'Refund Rules': 'Quy Tắc Hoàn Tiền',
        'Refunds are not supported': 'Hoàn tiền không được hỗ trợ',
        'Voucher Rules': 'Quy Tắc Voucher',
        'one voucher can be used once only It also can be used in combination with shipping vouchers': 'Một voucher chỉ có thể được sử dụng một lần. Nó cũng có thể được kết hợp với voucher vận chuyển',
        'one voucher can be used at a time It also can be used in combination with discount vouchers': 'Một voucher chỉ có thể được sử dụng một lần tại một thời điểm. Nó cũng có thể được kết hợp với voucher giảm giá',
        'Buy Now': 'Mua Ngay',
        canceled_on: 'hủy vào:',
        'Delivery Failed': 'Giao hàng Thất bại',
        'Logistics exception': 'Ngoại lệ Logistics',
        'Delivery failed and returned to the merchant': 'Giao hàng thất bại và được trả lại cho người bán',
        'Logistics lost packages': 'Logistics mất gói hàng',
        reason_sc_ops_1: 'Hết hàng',
        reason_sc_ops_2: 'Giá sai',
        reason_sc_ops_3: 'Lý do khác',
        reason_sc_ops_9: 'Thông tin địa chỉ giao hàng không phù hợp với định dạng',
        reason_sc_ops_80: 'Khách hàng hủy nhiệm vụ',
        reason_sc_ops_83: 'Không thể liên hệ với khách hàng',
        reason_sc_ops_85: 'Số điện thoại của người gửi trống',
        reason_sc_ops_90: 'Gói hàng chưa sẵn sàng để thu thập',
        reason_sc_ops_91: 'Gói hàng không đáp ứng tiêu chuẩn vận chuyển',
        reason_sc_ops_95: 'Chậm thu thập do thiếu phương tiện/người',
        reason_sc_ops_98: 'Địa chỉ sai',
        reason_sc_ops_99: 'Gói hàng không đáp ứng điều kiện thu thập: quá cỡ',
        reason_sc_ops_100: 'Gói hàng không đáp ứng điều kiện thu thập: hàng cấm',
        reason_sc_ops_105: 'Bao bì bên ngoài bị hư hỏng',
        reason_app_1: 'Thay đổi phương thức thanh toán',
        reason_app_2: 'Thời gian giao hàng quá lâu',
        reason_app_3: 'Đơn hàng trùng lặp',
        reason_app_4: 'Thay đổi địa chỉ giao hàng',
        reason_app_5: 'Thay đổi ý định',
        reason_app_6: 'Quyết định chọn sản phẩm khác',
        reason_app_7: 'Chi phí vận chuyển quá cao',
        reason_app_8: 'Tìm thấy giá tốt hơn ở nơi khác',
        "Orders": "Đơn Hàng",
        "Merchant delivery time out You can buy again": "Thời gian giao hàng của người bán đã hết, bạn có thể mua lại",
        "receive_wrong_item_wrong_size_wrong_color": "Nhận hàng sai/Sai kích thước/Sai màu",
        "missing_item_accessory_freebie": "Thiếu hàng/Phụ kiện/Quà tặng",
        "damange_faulty_item": "Hàng hư hỏng/Hàng lỗi",
        "dont_want_the_item_or_it_does_not_suit_me": "Không muốn hàng hoặc hàng không phù hợp với tôi",
        "item_doesnot_match_description_pictures": "Hàng không khớp với mô tả/hình ảnh",
        "counterfeit_item": "Hàng giả",
        "product_expired": "Hàng hết hạn",
        "Please youpikker click here to log in": "Vui lòng bạn Youpikker nhấp vào đây để đăng nhập",
        "The commission is only an estimated commission, the actual commission is subject to the order information": "Hoa hồng chỉ là hoa hồng ước tính, hoa hồng thực tế phụ thuộc vào thông tin đơn hàng",
        "reason_app_9": "Mua hàng của bạn đã bị hệ thống đánh giá là đơn hàng giả",
        "refund_on_its_way": "Hoàn tiền đang trên đường!",
        "refund_on_its_way2": "Hoàn tiền đang trên đường",
        "add_bank_account": "Thêm tài khoản ngân hàng",
        "Utilities": "Dịch Vụ",
        "Top-ups": "Nạp Tiền",
        "At least 2 commission": "Ít nhất 2% hoa hồng",
        "Only Youpikker can get commission": "Chỉ Youpikker mới có thể nhận hoa hồng",
        "Click the button below to enter Lazada Top-up page": "Nhấp vào nút dưới đây để vào trang nạp tiền của Lazada",
        "For each click, only the first order can get the commission. If you need to spend multiple times, please re-enter Youpik product detail page and click the button below to jump again.": "Đối với mỗi lần nhấp, chỉ đơn hàng đầu tiên mới có thể nhận hoa hồng. Nếu bạn cần chi tiêu nhiều lần, vui lòng quay lại trang chi tiết sản phẩm Youpik và nhấp vào nút dưới đây để chuyển hướng lại.",
        "Orders will be displayed in Youpikker-Sale History within 48 hours.": "Đơn hàng sẽ được hiển thị trong Lịch sử Bán hàng của Youpikker trong vòng 48 giờ.",
        "The confirmed commission amount is subject to the display in the order.": "Số tiền hoa hồng đã xác nhận phụ thuộc vào hiển thị trong đơn hàng.",
        "Top-up Now": "Nạp Tiền Ngay",
        "You can get a minimum 2 commission for all payment on Lazada from Youpik.": "Bạn có thể nhận ít nhất 2% hoa hồng cho tất cả các thanh toán trên Lazada từ Youpik.",
        "Guide Steps:": "Các Bước Hướng Dẫn:",
        "Click the Top-up Now button": "Nhấp vào nút Nạp Tiền Ngay",
        "Select Top-up&E-voucher": "Chọn Nạp Tiền & E-voucher",
        "Checkout Now": "Thanh Toán Ngay",
        "Upgrade Now": "Nâng Cấp Ngay",
        "Login Now": "Đăng Nhập Ngay",
        "Select Utilities": "Chọn Dịch Vụ",
        "Click the Checkout Now button": "Nhấp vào nút Thanh Toán Ngay",
        "FAQ & Customer Service": "Câu Hỏi Thường Gặp & Dịch Vụ Khách Hàng",
        "best seller in": "bán chạy nhất ở",
        "Categories": "Danh Mục",
        "Whole Bestsellers": "Toàn Bộ Sản Phẩm Bán Chạy",
        "The product you searched for does not exist": "Sản phẩm bạn tìm kiếm không tồn tại",
        "no_data": "Không Có Dữ Liệu",
        "h5_download_app": "Tiết kiệm lên đến 37%! Tải Ngay",
        "Download uChoice Pro": "Tải uChoice Pro",
        "You will be directed to the App Store or Google Play Store. Please download uChoice Pro now.": "Bạn sẽ được chuyển hướng đến App Store hoặc Google Play Store. Vui lòng tải xuống uChoice Pro ngay.",
        "Comm.": "Hoa Hồng",
        "Request Sample": "Yêu Cầu Mẫu",
        "No more products": "Không còn sản phẩm",
        "Commm": "Hoa Hồng",
        "Com. Rate": "Tỷ Lệ Hoa Hồng",
        "DOs": "Những Điều Cần Làm",
        "DONTs": "Những Điều Không Nên Làm",
        "Privacy Policy": "Chính Sách Bảo Mật",
        "Disagree": "Không Đồng Ý",
        "It will affect the usage of Youpik Ultimate APP after you confirm to disagree the document": "Nó sẽ ảnh hưởng đến việc sử dụng ứng dụng Youpik Ultimate sau khi bạn xác nhận không đồng ý tài liệu",
        "If you have any questions, please contact: 02-3295929": "Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ: 02-3295929",
      },
      ...venueVi,
      ...promptPayVi,
      ...resultVi,
      ...orderVi,
      ...itemVi,
      ...activityVi,
      ...loginVi,
      ...lazadaVi,
      ...tiktokVi,
      ...rechargeVi,
      ...bypVi,
      ...toolsGalVi,
      ...toolsHomeVi,
      ...couponVi,
      ...toolsLazadaVi,
      ...toolsGlobalVi,
      ...toolsCommonVi,
      ...toolsTemplateVi,
      ...toolsRevisionVi,
      ...selectionVi,
      ...distVi,
      ...commonVi,
    },
  }
}
