export const loginEn = {
  already_registered: 'Have you already registered？',
  open_app: 'Open App >>',
  you_are_inviter_by: 'you are invited by',
  ask_invite_code: 'Please ask your inviter for invitation code',
  login_with_sms: 'Login with SMS',
  login_with_password: 'Login with password',
  please_add_your_password: 'Please Add Your Password',
  minnum_6: 'Minimum 6 characters with a number and a letter',
  get_the_app: 'Get the App',
  'Congratulations on your successful registration':
    'Congratulations on your successful registration',
  'Click to download': 'Click to download',
  'We will send a one time SMS code your phone': 'We will send a one time SMS code your phone',
  'You Pick You Save You Share You Earn': 'You Pick You Save You Share You Earn',
  '绑定后不能修改，请再次确认': 'It cannot be modified after binding, please confirm again',
  Migration_success: 'Account migrate successfully! '
}

export const loginTh = {
  already_registered: 'ลงทะเบียนสำเร็จ ',
  open_app: '>>เปิดแอปพลิเคชัน',
  you_are_inviter_by: 'คุณถูกเชิญโดย',
  ask_invite_code: 'กรุณาขอรหัสคำเชิญ',
  login_with_sms: 'เข้าสู่ระบบด้วย SMS',
  login_with_password: 'เข้าสู่ระบบด้วยรหัสผ่าน',
  please_add_your_password: 'กรุณาใส่รหัสผ่าน',
  minnum_6: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวประกอบด้วยตัวเลขและตัวอักษร',
  get_the_app: 'ดาวน์โหลดเลย',
  'Congratulations on your successful registration': 'ขอแสดงความยินดีที่คุณลงทะเบียนสำเร็จ',
  'Click to download': 'คลิกเพื่อดาวน์โหลด',
  'We will send a one time SMS code your phone': 'เราจะส่งรหัส SMS ครั้งเดียวในโทรศัพท์ของคุณ',
  'You Pick You Save You Share You Earn': 'ยิ่งช้อป ยิ่งแชร์ ยิ่งชวน ยิ่งได้',
  '绑定后不能修改，请再次确认': 'ไม่สามารถแก้ไขได้หลังจากยืนยัน โปรดยืนยันอีกครั้ง',
  Migration_success: 'โอนข้อมูลสำเร็จ'
}

export const loginVi = {
  already_registered: 'Bạn đã đăng ký chưa?',
  open_app: 'Mở ứng dụng >>',
  you_are_inviter_by: 'bạn được mời bởi',
  ask_invite_code: 'Vui lòng hỏi người mời của bạn để lấy mã mời',
  login_with_sms: 'Đăng nhập bằng SMS',
  login_with_password: 'Đăng nhập bằng mật khẩu',
  please_add_your_password: 'Vui lòng thêm mật khẩu của bạn',
  minnum_6: 'Tối thiểu 6 ký tự với một số và một chữ cái',
  get_the_app: 'Tải ứng dụng',
  'Congratulations on your successful registration': 'Chúc mừng bạn đã đăng ký thành công',
  'Click to download': 'Nhấp để tải xuống',
  'We will send a one time SMS code your phone': 'Chúng tôi sẽ gửi mã SMS một lần đến điện thoại của bạn',
  'You Pick You Save You Share You Earn': 'Bạn Chọn Bạn Tiết Kiệm Bạn Chia Sẻ Bạn Kiếm Tiền',
  '绑定后不能修改，请再次确认': 'Không thể thay đổi sau khi liên kết, vui lòng xác nhận lại',
  Migration_success: 'Di chuyển tài khoản thành công!'
};
