import { useEffect } from 'react'
import { EventName, EventType } from '../const'
import { statistic } from '..'
import { watchClick } from './watchClick'
import { watchRouteChange } from './watchRouteChange'
import React from 'react'

export const StatisticWatcher = () => {
  useEffect(() => {
    watchClick(data => statistic(JSON.parse(data)))

    watchRouteChange(path => {
      // 暂时先这样过滤youpiklink的埋点
      if (path.includes('/pages/tools/')) {
        return
      }
      statistic({
        eventType: EventType.View,
        path: window.location.origin + path
      })
    })

    document.addEventListener('visibilitychange', () => {
      statistic({
        eventName:
          document.visibilityState === 'hidden'
            ? EventName.leave
            : EventName.return
      })
    })

    // TODO: 跳turemoney的时候会无限调用
    // window.onbeforeunload = () => {
    //   statistic({ eventName: EventName.close })
    // }
  }, [])

  return <div youpik-event-watcher="👀"></div>
}
