export const toolsGalEn = {
  noMoreThan50: `The link name cannot be longer than 50 characters`,
  domianStart: `The link name only contains letters, numbers horizontal line "-" and underscores "_", but cannot start and end with "-" and "_"`,
  wait30days: `If you want to change the link name, you will have to wait 24 hours for the next change.`,
  Customdomainname: `Customize link name`,
  alreadyexists: `This domain name is already exists, please choose another one`,
  Customdomain: `Custom domain`,
  Yourmodifications: `Your modifications will not be saved.`,
  Areyousure: `Are you sure you want to exit?`,
  UploadImage: `Upload Image`,
  Step1UploadImage: `Step 1: Upload Image`,
  Step2UploadImage: `Step 2: Add Image Description (Optional)`,
  Step3UploadImage: `Step 3: Add link for Images (Optional)`,
  SupportImage: `Support: JPG, JPEG, GIF, PNG, BMP, WebP, up to 20MB`,
  SupportImg: `Support: JPG, JPEG, GIF, PNG, BMP, WebP`,
  SupportMb: `up to 30MB`,
  Previous: `Previous`,
  NextStep: `Next(skip this step)`,
  Next: `Next`,
  skip: 'Skip',
  Done: `Done`,
  EnterURL: `Enter URL`,
  EnterPhoneNumber: `Enter phone number`,
  Enteremail: `Enter email`,
  Doneskipstep: `Done (skip this step)`,
  Clicktoaddimage: `Click to add image description (optional)`,
  ImageGallery: `Image Gallery`,
  Imageblocktitle: `Image block title(optional)`,
  Imageblockdescription: `Image block description(optional)`,
  ImageList: `Image List`,
  confirmDelete: `Confirm to delete this image?`,
  Theimagelist: `The image list is empty.`,
  ToAddImage: `To add image(s), click the "Add" button above.`,
  ImageDetailsPage: `Image Details Page`,
  Image: `Image`,
  // ImageDescription: `Image Description`,
  Link: `Link`,
  AddImg: `+ Add `,
  Preview: `Preview`,
  Style: `Style`,
  Grid: `Grid`,
  Slide: `Slide`,
  Imagesper: `Number of Images per Row`,
  SliderType: `Slider Type`,
  PartialVisible: `Partial Visible`,
  Panorama: `Panorama`,
  ManualSlide: `Manual Slide`,
  Autoplay: `Auto play`,
  AutoplayInterval: `Auto play Interval`,
  Thecontentyou: `The content you edit and the images uploaded will not be saved.Are you sure you want to leave?`,
  ImageBlock: 'Image block title',
  ImageDescription: 'Image block description...',
  correctLink: 'Please enter correct link',
  correctEmail: 'Please enter correct E-mail',
  correctNumber: 'Enter phone number',
  saveImg: 'The content you edit and the images uploaded will not be saved.',
  areYouSure: 'Are you sure you want to leave?',
  AddImage: 'Add Image',
  youcanMore: 'You can add no more than',
  pictures: 'pictures',
  ThisPreviewState: 'Note: This preview state is what fans will see.',
  more50Words: 'The link name cannot be longer than 50 characters',
  GetStarted: 'Get Started',
  //guide
  WelcomeToYoupikShow: 'Welcome to Youpik.show',
  StartByFollowing1:
    ' Start by following our tutuorial to experience the functions of Youpik.show !',
  //添加一个新的模块
  guide_step1_addBlock: 'Add a new block',
  guide_step1_Several:
    'Several Block types ready to add to your site, help you create unique bio page.',
  guide_step1_Bio: 'Bio',
  guide_step1_Image: 'Image Gallery',
  guide_step1_lazada: 'Lazada Affiliate',
  guide_step1_Donation: 'Donation',
  // ---第二步
  guide_step2_title: 'Edit the block',
  guide_step2_content1: `There is an edit button in the upper right corner of every block, click to "Move up" "Move down" "Delete" blocks , and enter into the edit page to improve more details.`,
  guide_step2_content2: ' ',
  // 第三步
  guide_step3_title: 'Quick Action',
  guide_step3_content:
    'You can also drag to adjust the order by long press the white area in every block .',
  // 第四步
  guide_step4_title: 'Restore editting',
  guide_step4_content:
    'If you are not satisfied with the changes you made, you can use this function. The system will restore your design back to the content that was published last time.',
  //第五步
  guide_step5_title: 'User Manual',
  guide_step5_content: 'It will show how to create your special bio page from 0 to 1.',
  // 第六步
  guide_step6_title: 'User Guide',
  guide_step6_content: 'It will show how to create your special bio page from 0 to 1.',
  //第七步
  guide_step7_title: 'Preview the page',
  guide_step7_content: 'Preview to see the current changes of the page.',
  // 第八步
  guide_step8_title: 'Publish the design',
  guide_step8_content:
    'Design edits are always in “Draft Mode”. The changes will be visible to your fans once you decide to “publish”.',
  // 第九步
  guide_step9_title: 'Using template',
  guide_step9_content: 'Replace your entire page design with the temple design.',
  // 第十步
  guide_step10_title: 'Overal settings',
  guide_step10_content1: '1. You can set up your personal domain name for your site here.',
  guide_step10_content2_1:
    '2. You can also click to customize the theme settings here. These settings will be applied to your entire site.'
}

export const toolsGalTh = {
  // ImageDescription: `คำบรรยายรูปภาพ`,
  Previous: `ย้อนกลับ`,
  NextStep: `ถัดไป (ข้ามขั้นตอนนี้)`,
  noMoreThan50: `ชื่อลิงก์ไม่สามารถยาวเกิน 50 ตัวอักษร`,
  domianStart: `ชื่อลิงก์จะต้องขึ้นต้นด้วยตัวอักษร ตัวเลข เครื่องหมาย "-" และ "_" ชื่อลิงก์ไม่สามารถเริ่มและลงท้ายด้วยเครื่องหมาย "-" และ "_"`,
  wait30days: `หากคุณเปลี่ยนชื่อลิงก์ คุณจะต้องรอ 7 วันสำหรับการเปลี่ยนแปลงครั้งถัดไป`,
  Customdomainname: `กำหนดชื่อลิงก์`,
  alreadyexists: `ชื่อโดเมนนี้มีอยู่แล้ว โปรดใช้ชื่ออื่น`,
  Customdomain: `กำหนดโดเมน`,
  Yourmodifications: `การแก้ไขของคุณจะไม่ถูกบันทึก`,
  Areyousure: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  UploadImage: `อัปโหลดรูปภาพ`,
  Step1UploadImage: `ขั้นตอนที่ 1: อัปโหลดรูปภาพ`,
  Step2UploadImage: `ขั้นตอนที่ 2: เพิ่มคำอธิบายรูปภาพ (ไม่บังคับ)`,
  Step3UploadImage: `ขั้นตอนที่ 3: เพิ่มลิงก์สำหรับรูปภาพ (ไม่บังคับ)`,
  SupportImage: `รองรับ: JPG, JPEG, GIF, PNG, BMP, WebP ขนาดไม่เกิน 20MB`,
  Next: `ถัดไป`,
  Done: `เสร็จ`,
  skip: 'ข้าม',
  EnterURL: `ใส่ URL`,
  EnterPhoneNumber: `ใส่หมายเลขโทรศัพท์`,
  Enteremail: `ใส่อีเมล`,
  Doneskipstep: `เสร็จสิ้น (ข้ามขั้นตอนนี้)`,
  Clicktoaddimage: `คลิกเพื่อเพิ่มคำบรรยายรูปภาพ (ไม่บังคับ)`,
  Imageblocktitle: `หัวข้อ (ไม่บังคับ)`,
  Imageblockdescription: `คำบรรยาย (ไม่บังคับ)`,
  ImageList: `รูปภาพ`,
  confirmDelete: `ยืนยันที่จะลบรูปภาพ`,
  Theimagelist: `ยังไม่มีรูปภาพ`,
  ToAddImage: `คลิกปุ่มด้านบนเพื่อเพิ่มรูปภาพ`,
  ImageDetailsPage: `หน้ารายละเอียดของรูปภาพ`,
  Image: `รูปภาพ`,
  AddImg: `เพิ่ม `,
  Preview: `แสดงตัวอย่าง`,
  Style: `สไตล์`,
  Grid: `ตาราง`,
  Slide: `สไลด์`,
  Imagesper: `จำนวนรูปภาพต่อแถว`,
  SliderType: `ประเภทของสไลด์`,
  PartialVisible: `มองเห็นบางส่วน`,
  Panorama: `พาโนรามา`,
  ManualSlide: `สไลด์ด้วยตนเอง`,
  Autoplay: `เลือกอัตโนมัติ`,
  AutoplayInterval: `ระยะเวลาเล่นอัตโนมัติ`,
  SupportImg: `รองรับ: JPG, JPEG, GIF, PNG, BMP, WebP`,
  SupportMb: `ขนาดไม่เกิน 30MB`,
  ImageGallery: `รูปภาพ`,
  Thecontentyou: `เนื้อหาที่คุณแก้ไขและรูปภาพที่อัปโหลดจะไม่ถูกบันทึก คุณแน่ใจหรือไม่ว่าต้องการออกจากหน้านี้`,
  AddImage: 'เพิ่มรูปภาพ',
  youcanMore: 'สามารเพิ่มรูปภาพได้ไม่เกิน',
  pictures: 'รูป',
  ImageBlock: 'ชื่อรูปภาพ',
  ImageDescription: 'คำบรรยายรูปภาพ',
  correctLink: 'กรุณาใส่ลิงก์ที่ถูกต้อง',
  correctEmail: 'กรุณากรอกอีเมลที่ถูกต้อง',
  correctNumber: 'ใส่หมายเลขโทรศัพท์',
  saveImg: 'เนื้อหาที่คุณแก้ไขและรูปภาพที่อัปโหลดจะไม่ถูกบันทึก',
  areYouSure: 'คุณแน่ใจหรือไม่ว่าต้องการออกจากหน้านี้',
  WelcomeToYoupikShow: 'ยินดีต้อนรับสู่ Youpik.show',
  StartByFollowing1: 'ทำตามคำแนะนำการใช้งาน เพื่อการใช้ที่ง่ายขึ้น ',
  StartByFollowing2: 'เพื่อการใช้ที่ง่ายขึ้น ',
  ThisPreviewState: 'หมายเหตุ: ตัวอย่างที่ผู้ติดตามคุณจะเห็น',
  more50Words: 'ชื่อลิงก์ไม่สามารถยาวเกิน 50 ตัวอักษร',
  GetStarted: 'เริ่มเลย!',
  //添加一个新的模块
  guide_step1_addBlock: 'เพิ่มเพจใหม่',
  guide_step1_Several: 'สร้างหน้า Bio สำหรับเว็บไซต์ของคุณ',
  guide_step1_Bio: 'Bio',
  guide_step1_Image: 'รูปภาพ',
  guide_step1_lazada: 'Lazada Affiliate',
  guide_step1_Donation: 'รางวัล',
  // 上下移动拖拽
  guide_step2_title: 'แก้ไขบล็อค',
  guide_step2_content1:
    'มีปุ่มแก้ไขที่มุมขวาบนของทุกบล็อก คลิกเพื่อ “เลื่อนขึ้น" “เลื่อนลง" "ลบ" บล็อก ',
  guide_step2_content2: 'และเข้าสู่หน้าแก้ไขเพื่อปรับปรุงรายละเอียดต่างๆเพิ่มเติม',

  // 编辑
  guide_step3_title: 'การแก้ไขแบบรวดเร็ว',
  guide_step3_content:
    'คุณยังสามารถปรับลำดับบล็อกได้โดยการกดพื้นที่สีขาวค้างไว้แล้วลากไปในบล็อกอื่นๆ',
  //个人网址设置
  guide_step4_title: 'ยกเลิกการแก้ไข',
  guide_step4_content:
    'หากคุณไม่พอใจกับการเปลี่ยนแปลง คุณสามารถใช้ฟังก์ชันนี้ได้ ระบบจะแก้ไขการออกแบบของคุณกลับไปเป็นการออกแบบที่เผยแพร่ครั้งล่าสุด',
  // 全局设置
  guide_step5_title: 'คู่มือการใช้',
  guide_step5_content: 'คุณสามารถดูวิธีสร้างหน้า Bio ของคุณได้ที่นี่',
  // 重新编辑
  guide_step6_title: 'คู่มือผู้ใช้',
  guide_step6_content: 'คุณสามารถดูคู่มือผู้ใช้ได้ทุกเมื่อ โดยคลิกที่ปุ่มนี้',
  //使用模版
  guide_step7_title: 'ดูหน้าเพจ',
  guide_step7_content: 'ดูการเปลี่ยนแปลงของหน้าเพจ',
  // 帮助
  guide_step8_title: 'เผยแพร่เทมเพลต',
  guide_step8_content:
    'การแก้ไขรูปแบบเทมเพลตจะอยู่ใน "โหมดร่าง" เสมอ ผู้ติดตามของคุณจะเห็นการเปลี่ยนแปลงเมื่อคุณคลิกเพื่อ "เผยแพร่"',
  // 预览
  guide_step9_title: 'ใช้เทมเพลต',
  guide_step9_content: 'ออกแบบหน้าเพจทั้งหมดด้วยเทมแพลต',
  // 发布
  guide_step10_title: 'การตั้งค่าโดยรวม',
  guide_step10_content1: '1. คุณสามารถตั้งชื่อลิงก์สำหรับเว็บไซต์ของคุณได้ที่นี่',
  guide_step10_content2_1: '2. คุณสามารถคลิกเพื่อปรับแก้ไขการตั้งค่าบล็อกได้ที่นี่',
  guide_step10_content2_2: 'การตั้งค่าเหล่านี้จะแสดงบนหน้าเพจของคุณ'
}

export const toolsGalVi = {
  noMoreThan50: `Tên liên kết không được dài hơn 50 ký tự`,
  domianStart: `Tên liên kết chỉ chứa chữ cái, số, dấu gạch ngang "-" và dấu gạch dưới "_", nhưng không thể bắt đầu và kết thúc bằng "-" và "_"`,
  wait30days: `Nếu bạn muốn thay đổi tên liên kết, bạn sẽ phải chờ 24 giờ để thay đổi tiếp theo.`,
  Customdomainname: `Tùy chỉnh tên liên kết`,
  alreadyexists: `Tên miền này đã tồn tại, vui lòng chọn tên khác`,
  Customdomain: `Tên miền tùy chỉnh`,
  Yourmodifications: `Những thay đổi của bạn sẽ không được lưu.`,
  Areyousure: `Bạn có chắc chắn muốn rời khỏi không?`,
  UploadImage: `Tải lên hình ảnh`,
  Step1UploadImage: `Bước 1: Tải lên hình ảnh`,
  Step2UploadImage: `Bước 2: Thêm mô tả hình ảnh (Tùy chọn)`,
  Step3UploadImage: `Bước 3: Thêm liên kết cho hình ảnh (Tùy chọn)`,
  SupportImage: `Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, tối đa 20MB`,
  SupportImg: `Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP`,
  SupportMb: `tối đa 30MB`,
  Previous: `Trước`,
  NextStep: `Tiếp theo (bỏ qua bước này)`,
  Next: `Tiếp theo`,
  skip: 'Bỏ qua',
  Done: `Hoàn tất`,
  EnterURL: `Nhập URL`,
  EnterPhoneNumber: `Nhập số điện thoại`,
  Enteremail: `Nhập email`,
  Doneskipstep: `Hoàn tất (bỏ qua bước này)`,
  Clicktoaddimage: `Nhấp để thêm mô tả hình ảnh (tùy chọn)`,
  ImageGallery: `Thư viện hình ảnh`,
  Imageblocktitle: `Tiêu đề khối hình ảnh (tùy chọn)`,
  Imageblockdescription: `Mô tả khối hình ảnh (tùy chọn)`,
  ImageList: `Danh sách hình ảnh`,
  confirmDelete: `Xác nhận xóa hình ảnh này?`,
  Theimagelist: `Danh sách hình ảnh trống.`,
  ToAddImage: `Để thêm hình ảnh, nhấp vào nút "Thêm" ở trên.`,
  ImageDetailsPage: `Trang chi tiết hình ảnh`,
  Image: `Hình ảnh`,
  Link: `Liên kết`,
  AddImg: `+ Thêm `,
  Preview: `Xem trước`,
  Style: `Phong cách`,
  Grid: `Lưới`,
  Slide: `Trượt`,
  Imagesper: `Số lượng hình ảnh mỗi hàng`,
  SliderType: `Loại trượt`,
  PartialVisible: `Hiển thị một phần`,
  Panorama: `Toàn cảnh`,
  ManualSlide: `Trượt thủ công`,
  Autoplay: `Tự động phát`,
  AutoplayInterval: `Khoảng thời gian tự động phát`,
  Thecontentyou: `Nội dung bạn chỉnh sửa và hình ảnh đã tải lên sẽ không được lưu. Bạn có chắc chắn muốn rời khỏi không?`,
  ImageBlock: 'Tiêu đề khối hình ảnh',
  ImageDescription: 'Mô tả khối hình ảnh...',
  correctLink: 'Vui lòng nhập liên kết chính xác',
  correctEmail: 'Vui lòng nhập email chính xác',
  correctNumber: 'Nhập số điện thoại',
  saveImg: 'Nội dung bạn chỉnh sửa và hình ảnh đã tải lên sẽ không được lưu.',
  areYouSure: 'Bạn có chắc chắn muốn rời khỏi không?',
  AddImage: 'Thêm hình ảnh',
  youcanMore: 'Bạn có thể thêm không quá',
  pictures: 'hình ảnh',
  ThisPreviewState: 'Ghi chú: Trạng thái xem trước này là những gì người hâm mộ sẽ thấy.',
  more50Words: 'Tên liên kết không được dài hơn 50 ký tự',
  GetStarted: 'Bắt đầu',
  // guide
  WelcomeToYoupikShow: 'Chào mừng đến với Youpik.show',
  StartByFollowing1: 'Bắt đầu bằng cách theo dõi hướng dẫn của chúng tôi để trải nghiệm các chức năng của Youpik.show!',
  // 添加一个新的模块
  guide_step1_addBlock: 'Thêm một khối mới',
  guide_step1_Several: 'Nhiều loại khối sẵn có để thêm vào trang của bạn, giúp bạn tạo trang tiểu sử độc đáo.',
  guide_step1_Bio: 'Tiểu sử',
  guide_step1_Image: 'Thư viện hình ảnh',
  guide_step1_lazada: 'Lazada Affiliate',
  guide_step1_Donation: 'Quyên góp',
  // --- 第二步
  guide_step2_title: 'Chỉnh sửa khối',
  guide_step2_content1: `Có một nút chỉnh sửa ở góc trên bên phải của mỗi khối, nhấp để "Di chuyển lên" "Di chuyển xuống" "Xóa" khối, và vào trang chỉnh sửa để cải thiện thêm chi tiết.`,
  guide_step2_content2: ' ',
  // 第三步
  guide_step3_title: 'Hành động nhanh',
  guide_step3_content: 'Bạn cũng có thể kéo để điều chỉnh thứ tự bằng cách giữ lâu vào khu vực trắng trong mỗi khối.',
  // 第四步
  guide_step4_title: 'Khôi phục chỉnh sửa',
  guide_step4_content: 'Nếu bạn không hài lòng với các thay đổi bạn đã thực hiện, bạn có thể sử dụng chức năng này. Hệ thống sẽ khôi phục thiết kế của bạn về nội dung đã xuất bản lần trước.',
  // 第五步
  guide_step5_title: 'Hướng dẫn sử dụng',
  guide_step5_content: 'Nó sẽ hướng dẫn cách tạo trang tiểu sử đặc biệt của bạn từ 0 đến 1.',
  // 第六步
  guide_step6_title: 'Hướng dẫn người dùng',
  guide_step6_content: 'Nó sẽ hướng dẫn cách tạo trang tiểu sử đặc biệt của bạn từ 0 đến 1.',
  // 第七步
  guide_step7_title: 'Xem trước trang',
  guide_step7_content: 'Xem trước để xem các thay đổi hiện tại của trang.',
  // 第八步
  guide_step8_title: 'Công bố thiết kế',
  guide_step8_content: 'Các chỉnh sửa thiết kế luôn ở chế độ “Nháp”. Các thay đổi sẽ hiển thị cho người hâm mộ của bạn khi bạn quyết định “công bố”.',
  // 第九步
  guide_step9_title: 'Sử dụng mẫu',
  guide_step9_content: 'Thay thế toàn bộ thiết kế trang của bạn bằng thiết kế mẫu.',
  // 第十步
  guide_step10_title: 'Cài đặt tổng thể',
  guide_step10_content1: '1. Bạn có thể thiết lập tên miền cá nhân của bạn cho trang của bạn tại đây.',
  guide_step10_content2_1: '2. Bạn cũng có thể nhấp để tùy chỉnh cài đặt chủ đề tại đây. Các cài đặt này sẽ được áp dụng cho toàn bộ trang web của bạn.'
}
