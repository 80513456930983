import utils from "../utils";

const commonEnObj = {
  'No data': 'No data',
  Loading: 'Loading',
}

const commonThObj = {
  'No data': 'No data',
  Loading: 'Loading',
}

const commonViObj = {
  'No data': 'No data',
  Loading: 'Loading',
};

export const commonEn = utils.localeFormat(commonEnObj, 'Common')
export const commonTh = utils.localeFormat(commonThObj, 'Common')
export const commonVi = utils.localeFormat(commonViObj, 'Common')