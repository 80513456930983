import Taro from '@tarojs/taro'
import qs from 'qs'
import i18n from 'taro-i18n'
import user from '../api/user'

/**
 * 核心参数，跳转路由的时候，都携带过去
 */
function getUrlCoreQueryParams() {
  // token只有在app内置浏览器里才会有，分享出来是没有token的
  const {
    token,
    language,
    sharer_member_id,
    inviteCode,
    youpiker,
    channel,
    isVisitorLogin,
    source,
    memberLevel
  } = getRouteParams()

  const userInfo = Taro.getStorageSync('userInfo') && JSON.parse(Taro.getStorageSync('userInfo'))

  let uri = window.ReactNativeWebView ? '' : '&isH5=true'

  if (token) {
    uri = `${uri}&token=${token}`
  }

  if (youpiker) {
    uri = `${uri}&youpiker=${youpiker}`
  }

  // 用来根据用户等级不同做差异化翻译
  if (memberLevel) {
    uri = `${uri}&memberLevel=${memberLevel}`
  }

  const lang = window.ReactNativeWebView ? language : i18n.t.locale
  if (lang) {
    uri = `${uri}&language=${lang}`
  }

  if (channel) {
    uri = `${uri}&channel=${channel}`
  }

  if (source) {
    uri = `${uri}&source=${source}`
  }

  // 邀请人相关参数，优先用本地用户信息里的，其次才用链接里的
  if (userInfo) {
    uri = `${uri}&sharer_member_id=${userInfo.id}&inviteCode=${userInfo.inviteCode}`
  } else {
    if (sharer_member_id) {
      uri = `${uri}&sharer_member_id=${sharer_member_id}`
    }

    if (inviteCode) {
      uri = `${uri}&inviteCode=${inviteCode}`
    }

    if (isVisitorLogin) {
      uri = `${uri}&isVisitorLogin=${isVisitorLogin}`
    }
  }

  return uri
}

/**
 * 跳转路由
 * @param {*} url 链接
 * @param {*} withCoreQueryParams 是否需要携带核心参数
 */
function navigateTo(url, withCoreQueryParams = true, needReplace = false) {
  let coreQueryParams = ''
  if (withCoreQueryParams) {
    coreQueryParams = getUrlCoreQueryParams()
    if (url.indexOf('?') === -1 && coreQueryParams.length > 0) {
      coreQueryParams = `?${coreQueryParams.substr(1, coreQueryParams.length - 1)}`
    }
  }
  if (window.ReactNativeWebView) {
    // 因为在app内置的webview中，部分安卓手机，无法监控通过Taro.navigateTo方式切换路由，只有用location.href重定向才能监控
    let domin = `${window.location.protocol}//${window.location.host}`
    if (url.indexOf('http') === 0) {
      domin = ''
    }
    if (needReplace) {
      window.location.replace(`${domin}${url}${coreQueryParams}`)
    } else {
      window.location.href = `${domin}${url}${coreQueryParams}`
    }
  } else {
    if (url.indexOf('http') === 0) {
      if (needReplace) {
        window.location.replace(`${url}${coreQueryParams}`)
      } else {
        window.location.href = `${url}${coreQueryParams}`
      }
    } else {
      Taro.navigateTo({
        url: `${url}${coreQueryParams}`
      })
    }
  }
}

/**
 * replace路由
 */
function replaceTo(url, withCoreQueryParams = true) {
  navigateTo(url, withCoreQueryParams, true)
}

/**
 * 打开链接，比如在app内置浏览器中打开链接的时候，会调起系统浏览器
 */
function openURL(url) {
  if (window.ReactNativeWebView) {
    const param = JSON.stringify({
      payFlag: 'openBrowser',
      url
    })
    window.ReactNativeWebView.postMessage(param)
  } else {
    // 这里没有使用window.open()是因为iPhone的Safari，出于安全考虑，禁止调用这个命令
    window.location.href = url
  }
}

/**
 * 跳转到登录页
 */
function navigateToLogin() {
  const parse = qs.parse(
    window.location.href.indexOf('?') != -1
      ? window.location.href.split('?')[1]
      : window.location.href
  )

  const language = i18n.t.locale || parse.language

  navigateTo(
    `/pages/login/login?language=${language}&inviteCode=${parse.inviteCode}&channel=${parse.channel}`,
    false
  )
}

/**
 * 跳转到tiktok登录页
 */
function navigateToTiktokLogin() {
  const parse = qs.parse(
    window.location.href.indexOf('?') != -1
      ? window.location.href.split('?')[1]
      : window.location.href
  )

  const language = i18n.t.locale || parse.language

  navigateTo(
    `/pages/tiktokLogin/login?language=${language}&inviteCode=${parse.inviteCode}&channel=${parse.channel}`,
    false
  )
}

/**
 * 跳转到注册页
 */
function navigateToReg() {
  const parse = qs.parse(
    window.location.href.indexOf('?') != -1
      ? window.location.href.split('?')[1]
      : window.location.href
  )

  const language = i18n.t.locale || parse.language

  navigateTo(
    `/pages/login/login?signUp=true&language=${language}&inviteCode=${parse.inviteCode}&channel=${parse.channel}`,
    false
  )
}

/**
 * 返回到上个页面
 */
function back() {
  if (window.ReactNativeWebView) {
    // postMessage是RN给JS提供的交互事件，可以在RN项目的webview.js里看到定义的那些交互事件
    window.ReactNativeWebView.postMessage(JSON.stringify({ payFlag: 'goback' }))
  } else {
    window.history.back()
  }
}

/**
 * 获取路由参数，为什么不用Taro自带的，因为Taro有时候来回切换页面会获取不到参数
 */
function getRouteParams() {
  const parse = qs.parse(
    window.location.href.indexOf('?') != -1
      ? window.location.href.split('?')[1]
      : window.location.href
  )

  // 如果同一个key传了多次参数，这个parse会长这样:
  /**
   * xx.com?a=1&b=1&b=2
   * {
   *  a: 1,
   *  b: [1,2]
   * }
   */

  // 取数组最后一个作为value
  for (const key of Object.keys(parse)) {
    const value = parse[key]
    if (Array.isArray(value)) {
      parse[key] = value[value.length - 1]
    }
  }

  return parse
}

/**
 * 当前url，拼接上language
 */
function currentUrlWithLanguage() {
  const parse = qs.parse(
    window.location.href.indexOf('?') != -1
      ? window.location.href.split('?')[1]
      : window.location.href
  )

  const language = i18n.t.locale || parse.language

  let url = window.location.href
  if (url.includes('language=')) {
    url.replace(/language=/g, 'language_deprecated=')
  }

  let prefix = '?'
  if (url.includes('?')) {
    prefix = '&'
  }

  return `${url}${prefix}language=${language}`
}

/**
 * 登录了才执行func，否则会跳登录页
 */
const performOrLogin = (func) => {
  if (!window.ReactNativeWebView && !user.getToken()) {
    user.removeToken()
  } else {
    func && func()
  }
}

/**
 * 登录了才执行func，如果没登录，也不会自动跳登录页
 */
const performIfLogined = (func) => {
  if (window.ReactNativeWebView || user.getToken()) {
    func && func()
  }
}

const postMessage = (data) => {
  if (window.ReactNativeWebView && data) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data))
  }
}

export default {
  getUrlCoreQueryParams,
  navigateTo,
  replaceTo,
  navigateToLogin,
  navigateToTiktokLogin,
  navigateToReg,
  openURL,
  getRouteParams,
  back,
  currentUrlWithLanguage,
  performOrLogin,
  performIfLogined,
  postMessage
}
