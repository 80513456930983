import React, { useEffect } from 'react'
import { View, Text, Image } from '@tarojs/components'
import PopUp from '@/components/modal/baseModal'
import { auth } from '@/utils/tiktok'
import i18n from 'taro-i18n'
import './ttAuth.scss'
import greenLogo from '../../../images/tiktok/tt_authorized.png'
// import api from '@/api/uchoiceApi'

function TtAuth({ onClose }) {
  useEffect(() => {}, [])

  return (
    <PopUp
      onClose={onClose}
      buttonText={i18n.t._('Authorize_now')}
      rightText={i18n.t._('Cancel')}
      title={i18n.t._('Authorization')}
      headType="auth"
      onGo={auth}
      canClick
    >
      <View className="popup_contents_ttAuth">
        <Text className="auth_benifit_title">{i18n.t._('Benefits_with_authorization')}</Text>
        <View className="auth_benifit_contents">
          <View className="benifit_single">
            <Image className="green_logo" src={greenLogo}></Image>
            <Text className="benifit_text">
              {i18n.t._('Copy_the_High_Commission_product_link')}
            </Text>
          </View>
          <View className="benifit_single">
            <Image className="green_logo" src={greenLogo}></Image>
            <Text className="benifit_text">
              {i18n.t._('AI_product_selection_with_analysis_fans_portrait')}
            </Text>
          </View>
          <View className="benifit_single">
            <Image className="green_logo" src={greenLogo}></Image>
            <Text className="benifit_text">
              {i18n.t._('Millions_of_Tiktok_high_commission_product')}
            </Text>
          </View>
          <View className="benifit_single">
            <Image className="green_logo" src={greenLogo}></Image>
            <Text className="benifit_text">{i18n.t._('Exclusive_1v1_advisor')}</Text>
          </View>
          <View className="benifit_single">
            <Image className="green_logo" src={greenLogo}></Image>
            <Text className="benifit_text">{i18n.t._('Free_Tiktok_selling_training_video')}</Text>
          </View>
          <View className="benifit_single">
            <Image className="green_logo" src={greenLogo}></Image>
            <Text className="benifit_text">{i18n.t._('Priority_quota_for_free_samples')}</Text>
          </View>
        </View>
        {/* <Text className="auth_hint_text">
          {i18n.t._('Remind')}
          {i18n.t._(
            'Before_authorizing__please_switch_and_log_in_to_the_target_account_on_Tiktok_'
          )}
        </Text> */}
      </View>
    </PopUp>
  )
}

export default TtAuth
