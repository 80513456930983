import React, { useEffect } from 'react'
// import Taro from '@tarojs/taro'
import { View, Text, Image } from '@tarojs/components'
import './baseModal.scss'
import AuthLogo from '../../images/tiktok/Auth_logo.png'
import BindLogo from '../../images/tiktok/uchoice_tiktok_logo2.png'

export default function BaseModal({
  onClose,
  onLeftClose,
  title,
  showLeftClose = false,
  children,
  buttonText,
  leftText,
  rightText,
  headType,
  onGo,
  canClick
}) {
  useEffect(() => {}, [])
  const closePopUp = () => {
    onClose()
  }
  const goWhere = () => {
    onGo()
  }

  const headerLogo = (() => {
    return {
      className: {
        auth: 'authLogo',
        bind: 'bindLogo'
      }[headType],
      image: {
        auth: AuthLogo,
        bind: BindLogo
      }[headType]
    }
  })()

  return (
    <View className="baseModal_mask">
      <View className="baseModal_main">
        <View className="title">
          <View className="title_wrap">
            <Image className={headerLogo.className} src={headerLogo.image}></Image>
            <Text className="baseModal_title_text">{title}</Text>
          </View>

          <View className="title_line"></View>
        </View>
        <View className="baseContent">{children}</View>

        <View className={canClick ? 'button' : 'button_gray'} onClick={canClick ? goWhere : null}>
          <Text className="button_text">{buttonText}</Text>
        </View>
        <View className={showLeftClose ? 'close_two' : 'close_one'}>
          {showLeftClose && (
            <View className="close_left">
              <Text className="close_left_text" onClick={onLeftClose}>
                {leftText}
              </Text>
            </View>
          )}

          <View className="close_right">
            <Text className="close_right_text" onClick={closePopUp}>
              {rightText}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
