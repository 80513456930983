export const selectionEn = {
  All: `All`,
  Sales: `Sales`,
  'Total Sales': `Total Sales`,
  'Last 30 Days': `Last 30 Days`,
  '30-Day Sales': `30-Day Sales`,
  Price: `Price`,
  Commission: `Comm`,
  'Commission%': `Comm%`,
  Creators: `Creators`,
  '#Subsidy': `Subsidy`,
  '#Free Samples': `Free Samples`,
  '#Promotion Reward': `Promotion Reward`,
  Comm: `Comm`,
  Earn: `Earn`,
  'Sales Growth %': `Sales Growth%`,
  Cancel: `Cancel`,
  'View Details': 'Get Free Sample',
  'No More': 'No More Products~',
  全部商品: `All`,
  xxx起: `Min. {price}`,
  批量添加橱窗: `Add all to showcase`
}

export const selectionTh = {
  All: `ทั้งหมด`,
  Sales: `ยอดขาย`,
  'Total Sales': `ยอดขายรวม`,
  'Last 30 Days': `30 วันที่ผ่านมา`,
  '30-Day Sales': `ยอดขาย 30 วัน`,
  Price: `ราคา`,
  Commission: `ค่าคอม`,
  'Commission%': `ค่าคอม%`,
  Creators: `ครีเอเตอร์`,
  '#Subsidy': `เงินอุดหนุน`,
  '#Free Samples': `สินค้าตัวอย่าง`,
  '#Promotion Reward': `รางวัลโปรโมท`,
  Comm: `ค่าคอม`,
  Earn: `รายได้`,
  'Sales Growth %': `ยอดขายเติบโต %`,
  Cancel: `ยกเลิก`,
  'View Details': 'ขอสินค้าตัวอย่าง',
  'No More': 'สินค้าทั้งหมดได้แสดงดังกล่าว',
  全部商品: `สินค้าทั้งหมด`,
  xxx起: `ราคาเริ่มต้น {price}`,
  批量添加橱窗: `เพิ่มทั้งหมดลงในโชว์เคส`
}
export const selectionVi = {
  All: 'Tất cả',
  Sales: 'Doanh số',
  'Total Sales': 'Tổng doanh số',
  'Last 30 Days': '30 ngày qua',
  '30-Day Sales': 'Doanh số 30 ngày',
  Price: 'Giá',
  Commission: 'Hoa hồng',
  'Commission%': 'Hoa hồng%',
  Creators: 'Người sáng tạo',
  '#Subsidy': 'Trợ cấp',
  '#Free Samples': 'Mẫu miễn phí',
  '#Promotion Reward': 'Thưởng khuyến mại',
  Comm: 'Hoa hồng',
  Earn: 'Kiếm',
  'Sales Growth %': 'Tăng trưởng doanh số%',
  Cancel: 'Hủy',
  'View Details': 'Nhận mẫu miễn phí',
  'No More': 'Không còn sản phẩm nào~',
  全部商品: 'Tất cả',
  xxx起: 'Tối thiểu {price}',
  批量添加橱窗: 'Thêm tất cả vào gian hàng'
};
