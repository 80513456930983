let lastPushAt = 0
let lastPath
let lastPopTimer

export const watchRouteChange = (change: (path: string) => void) => {
  const onChange = (path) => {
    // 在RN里会出现重复push
    if (path !== lastPath) {
        change?.(path)
    }
    lastPath = path
  }

  onChange(window.location.href)

  const oldPushState = window.history.pushState
  window.history.pushState = function () {
      onChange(arguments[2])
      lastPushAt = Date.now()
      return oldPushState.apply(this, arguments)
  }

  const oldReplaceState = window.history.replaceState
  window.history.replaceState = function () {
      // 调用Taro.navigateTo时，内部会先调用history.pushState去，再调用history.replaceState去替换倒数第二个路由，具体原因不得而知。
      // 但是这个replaceState操作不需要去统计，所以忽略掉。
      if (Date.now() - lastPushAt > 10) {
          onChange(arguments[2])
      }
      lastPushAt = Date.now()
      return oldReplaceState.apply(this, arguments)
  }

  window.onpopstate = function () {
      // history.block会连续触发两次onpopstate，这两次需要忽略掉
      if (lastPopTimer) {
          clearTimeout(lastPopTimer)
          lastPopTimer = null
      } else {
          lastPopTimer = setTimeout(() => {
              lastPopTimer = null
              onChange(window.location.href)
          }, 100);
      }
  }
}