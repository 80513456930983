import Taro from '@tarojs/taro'
import mergeBaseUrl from '../api/baseUrl'
import i18n from 'taro-i18n'
import user from '../api/user'

/**
 * 获取指定页面路径
 * @param {number} reverseIndex 倒数第几个，从1开始
 */
function getSpecifyPageUrl(reverseIndex) {
  const pages = Taro.getCurrentPages()
  if (reverseIndex > pages.length) {
    // 此页面不存在
    return null
  }
  const specifyPage = pages[pages.length - reverseIndex]
  return specifyPage.route
}

/*获取当前页url*/
function getCurrentPageUrl() {
  return getSpecifyPageUrl(1)
}

/**
 * 获取上一页路径
 */
function getPreviousPageUrl() {
  return getSpecifyPageUrl(2)
}

/*获取当前页带参数的url*/
function getCurrentPageUrlWithArgs() {
  var pages = Taro.getCurrentPages() //获取加载的页面
  var currentPage = pages[pages.length - 1] //获取当前页面的对象
  var url = currentPage.route //当前页面url
  var options = currentPage.options //如果要获取url中所带的参数可以查看options

  //拼接url的参数
  var urlWithArgs = url + '?'
  for (var key in options) {
    var value = options[key]
    urlWithArgs += key + '=' + value + '&'
  }
  urlWithArgs = urlWithArgs.substring(0, urlWithArgs.length - 1)

  return urlWithArgs
}

/**
 * 当前页面栈数量
 */
function getCurrentPagesCount() {
  return Taro.getCurrentPages().length
}

const showToast = (title) => {
  Taro.showToast({
    title: title,
    icon: 'none',
    duration: 1500
  })
}

/**
 * 上传图片
 */
async function chooseAndUploadImage() {
  try {
    const { tempFilePaths } = await Taro.chooseImage({
      count: 1
    })
    Taro.showLoading({
      title: 'Uploading...'
    })
    Taro.uploadFile({
      url: mergeBaseUrl('api-base/upload/uploadFile'),
      filePath: tempFilePaths[0],
      name: 'file',
      header: {
        'device-os-type': window.isProApp ? window.platform : '',
        'Accept-Charset': 'utf-8',
        'Accept-Encoding': 'identity',
        Authorization: 'Bearer ' + user.getToken(),
        'x-version': 'dev'
      }
    })
    Taro.hideLoading()
  } catch (error) {
    Taro.hideLoading()
    throw error
  }
}

/**
 * 获取url参数
 * @param {string} name
 * @param {string} url
 */
function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  console.log('results', results)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * 获取导航栏高度
 */
function navigatorHeight() {
  const statusBarHeight = Taro.getSystemInfoSync().statusBarHeight
  return statusBarHeight + 44 + 'px'
}

const localeFormat = (obj, name) => {
  const formatObj = {}
  Object.keys(obj).forEach((field) => {
    formatObj[`${name}.${field}`] = obj[field]
  })
  return formatObj
}

export function formatPrice(val = 0, withUnit = false) {
  const num = Number(val).toFixed(2).toString()
  let result = num
  const [zheng, decimals] = num.split('.')

  if (getRegion() === 'vn') {
    result = `${zheng.replace(/(\d)(?=(\d{3})+$)/g, '$1.')}` // 不包含小数点和小数点后面的内容
  } else {
    if (num.length > 5) {
      result = `${zheng.replace(/(\d)(?=(\d{3})+$)/g, '$1,')}.${decimals}`
    }
  }

  if (withUnit) {
    if (getRegion() === 'vn') {
      result = `${result}₫`
    } else {
      result = `฿${result}`
    }
  }

  return result
}

export const getRegion = () => {
  return localStorage.getItem('region') || 'th'
}

export const handleUrlWithRegion = (url) => {
  if (url.indexOf('region=') !== -1) {
    return url
  }
  if (url.indexOf('?') === -1) {
    return `${url}?region=${getRegion().toUpperCase()}`
  } else {
    return `${url}&region=${getRegion().toUpperCase()}`
  }
}

export default {
  getSpecifyPageUrl,
  getCurrentPageUrl,
  getPreviousPageUrl,
  getCurrentPageUrlWithArgs,
  getCurrentPagesCount,
  showToast,
  chooseAndUploadImage,
  getParameterByName,
  navigatorHeight,
  localeFormat,
  formatPrice,
  getRegion,
  handleUrlWithRegion,
}
