import utils from '@/utils/utils'

const toolsRevisionEnObj = {
  title: 'Setting',
  domainNameSettings: 'Customize link name',
  globalSettings: 'Block Settings',
  helpContactUs: 'Help & Contact Us',
  usersManual: 'User Manual',
  contcatService: 'Contact Service'
}
const toolsRevisionThObj = {
  title: 'การตั้งค่า',
  domainNameSettings: 'แก้ไขชื่อลิงก์',
  globalSettings: 'ตั้งค่าบล็อก',
  helpContactUs: 'ต้องการความช่วยเหลือหรือติดต่อเรา',
  usersManual: 'คู่มือการใช้',
  contcatService: 'ติดต่อฝ่ายบริการ'
}

export const toolsRevisionViObj = {
  title: 'Cài đặt',
  domainNameSettings: 'Tùy chỉnh tên liên kết',
  globalSettings: 'Cài đặt khối',
  helpContactUs: 'Trợ giúp & Liên hệ với chúng tôi',
  usersManual: 'Hướng dẫn sử dụng',
  contcatService: 'Liên hệ dịch vụ'
}

export const toolsRevisionEn = utils.localeFormat(toolsRevisionEnObj, 'toolsRevision')
export const toolsRevisionTh = utils.localeFormat(toolsRevisionThObj, 'toolsRevision')
export const toolsRevisionVi = utils.localeFormat(toolsRevisionViObj, 'toolsRevision')
