const platform = () => {
  const ua = navigator.userAgent
  if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'ios'
  } else {
    return 'android'
  }
}

export default platform
