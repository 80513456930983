import React, { useEffect } from 'react'
import { View, Text, ScrollView } from '@tarojs/components'
import Taro from '@tarojs/taro'
import PopUp from '@/components/modal/baseModal'
import i18n from 'taro-i18n'
import './combine.scss'
import api from '../../../api/uchoiceApi'

function Combine({ onCancel, onSucceed, users = [], memberId, combineForward, trigger }) {
  useEffect(() => {}, [])

  const handleCombine = () => {
    const apiMethod = combineForward ? api.mergeToCurrentAccount : api.mergeToOtherAccount

    Taro.showLoading({
      title: i18n.t._('loading')
    })

    apiMethod({
      memberId
    })
      .then((res) => {
        Taro.hideLoading()

        if (res.code == 200) {
          onSucceed && onSucceed(res.result)
        }
        console.log(res, '合并成功')
      })
      .catch((error) => {
        // TODO: 后面有hideLoading，失败的message，看看会不会显示
        Taro.hideLoading()
        console.log(error, '合并失败')
      })
  }
  //合并文案
  const handleCombineText = () => {
    if (combineForward) {
      if (users) {
        return i18n.t._(
          'Uchoice_has_detected_that_the_tiktok_account_authorized_by_you_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_this_account_'
        )
      } else {
        return i18n.t._(
          'Uchoice_has_detected_that_the_tiktok_account_authorized_by_you_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_this_account_'
        )
      }
    } else {
      if (trigger == 'fromAuth' && users) {
        return i18n.t._(
          'Uchoice_has_detected_that_the_tiktok_account_authorized_by_you_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_this_account_'
        )
      } else if (trigger == 'fromAuth' && !users) {
        return i18n.t._(
          'Uchoice_has_detected_that_the_tiktok_account_authorized_by_you_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_this_account_'
        )
      } else if (trigger == 'fromBind && users') {
        return i18n.t._(
          'Uchoice_has_detected_that_the_mobile_phone_number_you_bound_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_one_account_'
        )
      } else {
        return i18n.t._(
          'Uchoice_has_detected_that_the_mobile_phone_number_you_bound_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_one_account_'
        )
      }
    }
  }

  return (
    <PopUp
      onClose={onCancel}
      buttonText={i18n.t._('Merge_accounts')}
      rightText={i18n.t._('Cancel')}
      title={i18n.t._('Merge')}
      onGo={handleCombine}
      canClick
      headType="auth"
    >
      <View className="popup_contents_combine">
        <Text className="auth_combine_title">{handleCombineText()}</Text>
        <ScrollView className="auth_combine_contents" scrollY>
          {users &&
            users.map((it, index) => (
              <View className="combine_single" key={index}>
                <Text className="combine_text">{it.nickname}</Text>
              </View>
            ))}
        </ScrollView>
      </View>
    </PopUp>
  )
}

export default Combine
