import utils from '@/utils/utils'

const toolsGlobalEnObj = {
  模块样式设置: 'Block Style Settings',
  方块: 'Square',
  圆角: 'Rounded',
  自定义: 'Custom',
  模块填充: `Block's padding`,
  半径: 'Radius',
  模块是否不靠边: `Block's margin`,
  模块之间的间隔: `Spacing between blocks`,
  模块背景颜色设置: 'Block Background color',
  模块边界设置: 'Block Border',
  边界颜色设置: 'Block Border Color',
  边界线段样式: 'Border Color Style',
  实线: 'Solid',
  圆点: 'Dot',
  长线段: 'Dash',
  边界线宽度: `Block Border width(px)`,
  页面背景设置: 'Site page background setting',
  选择颜色: 'Color',
  图片透明度: 'Blur',
  上传图片: 'Upload Image',
  '最大文件大小：20MB。 支持文件格式：jpg、jpeg、gif、png、bmp、webp':
    'Support: JPG, JPEG, GIF, PNG, BMP, WebP, up to 20MB',
  模块一的标题: 'Block 1',
  模块二的标题: 'Block 2',
  '这是模块区域的示例内容，您可以看到块中的字体和颜色如何显示':
    'Here is the example content of block, you can see how these settings are displayed in the block and page.',
  Like: 'Like',
  Buy: 'Buy',
  Copy: 'Copy'
}

const toolsGlobalThObj = {
  模块样式设置: 'ตั้งค่ารูปแบบเพจ',
  方块: 'สี่เหลี่ยม',
  圆角: 'มุมโค้ง',
  自定义: 'กำหนดเอง',
  模块填充: 'ช่องว่างภายในบล็อก',
  半径: 'รัศมี',
  模块是否不靠边: 'ขอบของบล็อก',
  模块之间的间隔: 'ระยะห่างระหว่างบล็อก',
  模块背景颜色设置: 'สีพื้นหลัง',
  模块边界设置: 'สีกรอบ',
  边界颜色设置: 'สีกรอบบล็อก',
  边界线段样式: 'สไตล์สีขอบ',
  实线: 'ขนาดเส้น',
  圆点: 'จุด',
  长线段: 'เส้นประ',
  边界线宽度: 'ความกว้างของเส้นขอบบล็อก (px)',
  页面背景设置: 'การตั้งค่าพื้นหลังของเพจ',
  选择颜色: 'สี',
  图片透明度: 'ความมัวของภาพ',
  上传图片: 'อัปโหลดรูปภาพ',
  '最大文件大小：20MB。 支持文件格式：jpg、jpeg、gif、png、bmp、webp':
    'รองรับ: JPG, JPEG, GIF, PNG, BMP, WebP ขนาดไม่เกิน 20MB',
  模块一的标题: 'บล็อกที่ 1',
  模块二的标题: 'บล็อกที่ 2',
  '这是模块区域的示例内容，您可以看到块中的字体和颜色如何显示':
    'นี่คือตัวอย่าง คุณสามารถดูได้ว่าการตั้งค่าจะแสดงในเพจอย่างไร',
  Like: 'ไลก์',
  Buy: 'ซื้อ',
  Copy: 'คัดลอก'
}

export const toolsGlobalViObj = {
  模块样式设置: 'Cài đặt kiểu khối',
  方块: 'Hình vuông',
  圆角: 'Góc bo tròn',
  自定义: 'Tùy chỉnh',
  模块填充: `Khoảng cách bên trong khối`,
  半径: 'Bán kính',
  模块是否不靠边: `Lề của khối`,
  模块之间的间隔: `Khoảng cách giữa các khối`,
  模块背景颜色设置: 'Màu nền của khối',
  模块边界设置: 'Đường viền khối',
  边界颜色设置: 'Màu đường viền khối',
  边界线段样式: 'Kiểu đường viền',
  实线: 'Đường liền',
  圆点: 'Chấm',
  长线段: 'Gạch ngang',
  边界线宽度: `Độ rộng đường viền khối (px)`,
  页面背景设置: 'Cài đặt nền trang',
  选择颜色: 'Màu sắc',
  图片透明度: 'Độ mờ',
  上传图片: 'Tải lên hình ảnh',
  '最大文件大小：20MB。 支持文件格式：jpg、jpeg、gif、png、bmp、webp':
    'Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, tối đa 20MB',
  模块一的标题: 'Khối 1',
  模块二的标题: 'Khối 2',
  '这是模块区域的示例内容，您可以看到块中的字体和颜色如何显示':
    'Đây là nội dung ví dụ của khối, bạn có thể thấy cách các cài đặt này hiển thị trong khối và trang.',
  Like: 'Thích',
  Buy: 'Mua',
  Copy: 'Sao chép'
}

export const toolsGlobalEn = utils.localeFormat(toolsGlobalEnObj, 'toolsGlobal')
export const toolsGlobalTh = utils.localeFormat(toolsGlobalThObj, 'toolsGlobal')
export const toolsGlobalVi = utils.localeFormat(toolsGlobalViObj, 'toolsGlobal')
