import { reportAnIncident, sendBeaconForReportAnIncident } from '@/api/statistic/reportAnIncident'
import { EventAttr, EventName, EventType } from './const'
import { ReactElement, Component } from 'react'
import { findDOMNode } from 'react-dom'
import { TraceId } from './traceId'

export interface StatisticData { 
  eventName?: string
  eventType?: number
  path?: string
  param?: object
}

export const statistic = ({
  eventName,
  eventType = EventType.Click,
  path = window.location.href,
  param
}: StatisticData) => {
  const data = {
    traceId: TraceId.getId(),
    eventName,
    eventType,
    path,
    param
  }

  TraceId.increase()

  console.log('statistic:', data)

  eventName === EventName.close
    ? sendBeaconForReportAnIncident(data)
    : reportAnIncident(data)

  return true
}

interface StatisticProps {
  children: ReactElement
  data: {
    eventName: Required<StatisticData['eventName']>
    param?: StatisticData['param']
  }
  // 要作用到哪个元素上，比如input、textarea，设置这个可以让点击范围更加精确
  effectTag?: keyof HTMLElementTagNameMap
}

export class Statistic extends Component<StatisticProps> {
  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    let dom = findDOMNode(this as any) as Element

    if (this.props.effectTag) {
      type Findout = (element: Element) => Element | undefined
      const findout: Findout = (element: Element) => {
        if (element.tagName.toLowerCase() === this.props.effectTag) {
          return element
        }

        for (let i = 0; i < element.children.length; i++) {
          const found: Element | undefined = findout(element.children[i])
          if (found) {
            return found
          }
        }

        return undefined
      }

      const found = findout(dom)
      if (found) {
        dom = found
      }
    }

    dom.setAttribute(EventAttr, JSON.stringify(this.props.data))
  }

  render() {
    return this.props.children
  }
}
