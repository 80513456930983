import { nanoid } from "../nanoid"

export class TraceId {
  private static randomId: string
  private static count: number

  static getId() {
    if (!this.randomId) {
      this.randomId = nanoid()
    }
    if (this.count === undefined) {
      this.count = 1
    }
    return `h5.${this.randomId}.${this.count}`
  }

  static increase() {
    this.count++
  }
}
