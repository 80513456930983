import React, { useState, useEffect } from 'react'
import { View, Text, Input, Image, Button } from '@tarojs/components'
import Taro from '@tarojs/taro'
import PopUp from '@/components/modal/baseModal'
import api from '@/api/uchoiceApi'
import i18n from 'taro-i18n'
import './phoneBinding.scss'
import checked from '../../../images/refund_s.png'
import unchecked from '../../../images/refund_us.png'

function MandatoryBinding(a) {
  const { onClose, mandatory, onNeedCombine } = a

  const [protocolReaded, setProtocolReaded] = useState(false)
  const [mobile, setMobile] = useState('')
  const [code, setCode] = useState('')
  const [disabled, setDisabled] = useState('')
  const [time, setTime] = useState(60)
  const [sendMsgDisabled, setSendMsgDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    console.log('hayley', mandatory)
  }, [])
  const changeMobile = (e) => {
    setMobile(e.detail.value)
  }
  const changeCode = (e) => {
    setCode(e.detail.value)
  }
  //发送验证码
  const handleSend = () => {
    if (disabled) {
      return
    }
    setLoading(true)
    const params = {
      mobile: mobile,
      type: 7
    }
    api
      .sendCode(params)
      .then(() => {
        Taro.showToast({
          title: i18n.t._('success'),
          icon: 'success',
          duration: 2000
        })
        let t = time
        setLoading(false)
        setSendMsgDisabled(false)
        setDisabled(true)
        let timer = setInterval(() => {
          setTime(t--)
          if (t === 0) {
            clearInterval(timer)
            setSendMsgDisabled(true)
            setDisabled(false)
            setTime(60)
          }
        }, 1000)
      })
      .catch((error) => {
        console.log(error)
        setSendMsgDisabled(true)
        setDisabled(false)
        setLoading(false)
      })
  }
  //绑定手机号
  const handleBinding = () => {
    Taro.showLoading({
      title: i18n.t._('loading')
    })
    const params = {
      mobile: mobile,
      prefix: 66,
      code: code
    }
    api
      .bindMobile(params)
      .then((res) => {
        if (res.code == 200) {
          if (res.result.bindOk == true) {
            console.log(res, '绑定成功')
            onClose()
          } else {
            // 保存该手机号下已授权tt账号列表信息与可合并至的用户id
            onNeedCombine && onNeedCombine(res.result)
          }
        }
        console.log(res, '正在绑定')
        Taro.hideLoading()
      })
      .catch((error) => {
        Taro.hideLoading()
        console.log(error, '绑定失败')
      })
  }
  return (
    <PopUp
      onClose={onClose}
      buttonText={
        mandatory ? i18n.t._('Confirmed_and_Authorize_Now') : i18n.t._('Register_or_bind_account')
      }
      rightText={i18n.t._('Later')}
      title={i18n.t._('Bind_mobile_phone_number')}
      headType="bind"
      onGo={handleBinding}
      canClick={protocolReaded && mobile && code}
    >
      <View className="popup_contents">
        <Text className="bind_warning">
          {mandatory == 1
            ? i18n.t._(
                'Multiple_Tiktok_accounts_can_only_be_authorized_after_binding_phone_number_'
              )
            : mandatory == 2
            ? i18n.t._('You_should_bind_the_phone_number_first')
            : i18n.t._('After_binding__there_is_a_chance_to_get_free_sample_qualification_')}
        </Text>
        <Text className="phone_title">{i18n.t._('Phone_number')}</Text>
        <View className="phone_wrap">
          <Text className="phone_contry">🇹🇭 +66</Text>

          <Input
            className="phone_input"
            maxlength={11}
            onInput={changeMobile.bind(this)}
            onBlur={(e) => {
              setMobile(e.detail.value)
            }}
            value={mobile}
            type="number"
          ></Input>
        </View>
        <View className="sms_wrap">
          <View className="sms_input_wrap">
            <Input
              className="sms_input"
              placeholder={i18n.t._('sms_code')}
              onInput={changeCode.bind(this)}
              value={code}
              type="number"
            ></Input>
          </View>

          <Button
            className={disabled ? 'sms_send_wrap clearBtn disabled' : 'sms_send_wrap clearBtn'}
            loading={loading}
            onClick={handleSend.bind(this)}
          >
            <Text className="sms_send_text">{sendMsgDisabled ? i18n.t._('send') : time + 's'}</Text>
          </Button>
        </View>
        {/* 协议 */}
        <View className="protocol">
          <View
            className="protocol_checkbox_wrap"
            onClick={() => {
              setProtocolReaded(!protocolReaded)
            }}
          >
            <Image
              className="protocol_checkbox_icon"
              src={protocolReaded ? checked : unchecked}
            ></Image>
          </View>
          <View className="protocol_text">
            <Text className="protocol_txt">{i18n.t._('Agree')}</Text>
            <Text
              className="protocol_link"
              onClick={() => {
                Taro.navigateTo({
                  url: '/pages/protocol/privacyPolicy'
                })
              }}
            >
              &nbsp;{i18n.t._('User Privacy Policy')}&nbsp;
            </Text>
            <Text className="protocol_txt">{i18n.t._('and')}</Text>
            <Text
              className="protocol_link"
              onClick={() => {
                Taro.navigateTo({
                  url: '/pages/protocol/termOfUse'
                })
              }}
            >
              &nbsp;{i18n.t._('Youpik terms of use')}&nbsp;
            </Text>
          </View>
        </View>
        {/* {mandatory && (
          <Text className="bind_hint_text">
            {i18n.t._('Remind')}
            {i18n.t._(
              'Before_authorizing__please_switch_and_log_in_to_the_target_account_on_Tiktok_'
            )}
          </Text>
        )} */}
      </View>
    </PopUp>
  )
}

export default MandatoryBinding
