import Taro from '@tarojs/taro'
import _isEmpty from 'lodash/isEmpty'
import mergeBaseUrl from './baseUrl'
import downloadBaseUrl from './downloadUrl'
import interceptors from './interceptors'
import user from './user'
import route from '../utils/route'
// eslint-disable-next-line import/first
import i18n from 'taro-i18n'
import { getRegion } from '@/utils/utils'

interceptors.forEach((i) => Taro.addInterceptor(i))

class httpRequest {
  async baseOptions(params, method = 'GET', headers = {}) {
    try {
      let { url, data } = params
      // const showmethemoney = user.getUserId()
      // 自定义用户ID
      // url = url + '?showmethemoney=201120'
      let URL = mergeBaseUrl(url)

      // 不校验token
      // if (!this.getIsUnCheck(url) && !Taro.getCurrentInstance().router.params.token) {
      //   token = await this.checkToken();
      // }
      let contentType = 'application/json'

      // contentType
      if (
        url.includes('api-mall/order/member/pay/cod') ||
        url.includes('api-mall/order/ops/cancel') ||
        url.includes('api-member/member/shop/changePickStatus') ||
        url.includes('api-mall/item/shop/homeSearch') ||
        url.includes('api-mall/order/member/pay/online') ||
        url.includes('api-mall/item/shop/getViewedItem') ||
        url.includes('api-mall/order/member/toConfirmPay') ||
        url.includes('api-user/seller/getSellerInfoById') ||
        url.includes('api-mall/item/shop/getItemListBySellerId') ||
        url.includes('api-member/member/getByInviteCode') ||
        url.includes('api-member/member/account/bindMember') ||
        url.includes('api-mall/item/shop/listPickItem') ||
        url.includes('api-mall/item/calculateShippingFee') ||
        url.includes('api-base/logistic/queryLogisticRoutes') ||
        url.includes('api-member/member/bank/account/info') ||
        url.includes('api-mall/order/reverse/list') ||
        url.includes('api-member/member/account/registerOpenId') ||
        url.includes('api-member/member/account/authByLine') ||
        url.includes('api-mall/waterfall/getCategoryGoods') ||
        url.includes('api-mall/order/pay/promptPay') ||
        url.includes('api-member/member/address/removeById') ||
        url.includes('api-member/member/account/registerMember') ||
        url.includes('api-base/sms/sendCode') ||
        url.includes('api-base/sms/validCode') ||
        url.includes('api-member/member/account/updatePassword') ||
        url.includes('api-base/banner/getBanner') ||
        url.includes('api-mall/activity/app/getActivityItemPage') ||
        url.includes('api-mall/activity/app/getActivityItemInfo') ||
        url.includes('api-mall/spike/item/getHomeSpike') ||
        url.includes('api-mall/spike/item/getSpike') ||
        url.includes('api-mall/spike/item/getSpikeInfo') ||
        url.includes('api-mall/spike/item/getSpikeItemList') ||
        url.includes('api-base/home/getHomeList') ||
        url.includes('api-mall/activity/app/getBirthdayActivityPageVo') ||
        url.includes('api-mall/waterfall/getCategoryGoods') ||
        url.includes('api-mall/activity/app/getHomeCategoryGoodsList/v1') ||
        url.includes('api-base/home/getAppHomeConfigList') ||
        url.includes('api-user/users/current') ||
        url.includes('api-uaa/oauth/token')
      ) {
        contentType = 'application/x-www-form-urlencoded;charset=UTF-8'
        // Authorization = "";
      }

      let language = 'en-US'
      if (i18n.t.locale === 'th') {
        language = 'th-TH'
      } else if (i18n.t.locale === 'vi') {
        language = 'vi-VN'
      }
      let region = getRegion().toUpperCase()

      if (url.includes('api-uchoice/tt/h5/downloadH5Data')) {
        if (language === 'en-US' || language == 'th-TH') {

          URL = downloadBaseUrl(url, 'TH')
        } else {
          URL = downloadBaseUrl(url, 'VN')
        }
      }
      const option = {
        url: URL,
        data: data,
        method: method,
        header: {
          language,
          region,
          'Content-Type': contentType,
          'x-version': 'dev',
          'device-os-type': 'h5',
          'Accept-Charset': 'utf-8',
          'Accept-Encoding': 'identity',
          ...headers
        }
      }

      if (url.includes('api-uaa/oauth/token') || headers.noToken) {
        option.header.Authorization = 'Basic ' + btoa('app:app')
      } else if (route.getRouteParams().token) {
        option.header.Authorization = 'Bearer ' + route.getRouteParams().token
      } else if (user.getToken()) {
        option.header.Authorization = 'Bearer ' + user.getToken()
      }

      return Taro.request(option)
    } catch (err) {
      console.log(err)
    }
  }

  get(url, data = '', headers = {}) {
    let option = { url, data }
    return this.baseOptions(option, 'GET', headers)
  }

  post(url, data = {}, contentType, headers = {}) {
    let params = { url, data, contentType }
    return this.baseOptions(params, 'POST', headers)
  }

  put(url, data = '') {
    let option = { url, data }
    return this.baseOptions(option, 'PUT')
  }

  delete(url, data = '') {
    let option = { url, data }
    return this.baseOptions(option, 'DELETE')
  }

  async checkToken() {
    try {
      if (_isEmpty(user.getToken())) {
        // 没有token
        console.log('checkToken removeToken')
        return user.removeToken()
      } else {
        return Promise.resolve(user.getToken())
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new httpRequest()
