import env from '../../env'
/**
 * 处理请求Url
 * @param {*} route 路由
 */
const downloadBaseUrl = (route, region) => {
    console.log('ENV:', env)

    if (route.startsWith('http')) {
        return route
    }

    let baseUrl =
        window.location.host === 'h5.uchoice.pro'
            ? region === 'VN'
                ? 'https://api.vn.uchoice.pro/'
                : 'https://api.th.uchoice.pro/'
            : 'https://api.ypkshop.com/'

    if (env === 'dev') {
        baseUrl = region === 'VN' ? 'https://api.dev.vn.youpik.dev/' : 'https://api.dev.th.youpik.dev/'
    }

    if (env === 'test') {
        // baseUrl = 'https://api.test.youpik.dev/'
        window.location.pathname == '/pages/lazadaVoucher/index'
            ? (baseUrl = 'https://youpik.test.catike.com/')
            : (baseUrl = region === 'VN' ? 'https://api.test.vn.youpik.dev/' : 'https://api.test.th.youpik.dev/')
    }

    if (env === 'pre') {
        baseUrl = region === 'VN' ? 'https://api.pre.vn.uchoice.pro' : 'https://api.pre.th.uchoice.pro'
    }

    let fullUrl = baseUrl + route
    return fullUrl
}

export default downloadBaseUrl
