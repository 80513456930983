export const bypEn = {
  No_tip_invoices_generated_at_the_moment_: `No tip invoices generated at the moment.`,
  Use_Template_: `Use Template `,
  Guide: `Guide`,
  Preview: `Preview`,
  Publish: `Publish`,
  No_block: `No block`,
  No_block_has_been_added_yet: `No block has been added yet`,
  Add_block: `Add block`,
  Custom_domain: `Custom domain`,
  Block_theme_Settings: `Block theme Settings`,
  Re_edit: `Re-edit`,
  Please_add_blocks_before_publishing_: `Please add blocks before publishing!`,
  You_currently_have_no_edited_content__After_publishing__fans_will_see_a_blank_page__It_is_recommended_to_add_and_edit_blocks_before_publishing_: `You currently have no edited content. After publishing, fans will see a blank page. It is recommended to add and edit blocks before publishing.`,
  Go_to_add_blocks: `Go to add blocks`,
  Custom_domain_name: `Custom domain name`,
  If_you_change_your_domain_name__you_ll_have_to_wait_30_days_for_the_next_change_: `If you change your domain name, you'll have to wait 30 days for the next change.`,
  The_domain_name_must_start_with_a_letter_and_only_contain_letters__numbers__and_underscores_____: `The domain name must start with a letter and only contain letters, numbers, and underscores "_".`,
  This_domain_name_is_already_exists__please_choose_another_one_: `The link name is already exists, please choose another one.`,
  Domain_name_cannot_be_longer_than_50_characters: `Domain name cannot be longer than 50 characters`,
  Continue_editting: `Continue editting`,
  Exit: `Exit`,
  Save: `Save`,
  Copy_successfully: `Copy successfully`,
  Save_successfully: `Save successfully`,
  Select_Block_Type: `Select Block Type`,
  Bio: `Bio`,
  Used_to_display_your_profile_picture__bio_name__bio_text_and_your_social_links_: `Show your profile picture, bio name, bio text and your social links.`,
  Image_Image_Gallery: `Image Gallery`,
  Add_single_multiple_images_to_your_site_: `Add single/multiple images to your bio page.`,
  Lazada_Affiliate: `Lazada Affiliate`,
  Recommend_affiliate_products_to_your_fans_: `Recommend affiliate products to your fans.`,
  Tip_from_fans: `Tip from fans`,
  Receive_tips_from_your_fans_: `Receive tips from your fans.`,
  Your_name: `Your name`,
  Edit_Bio_text: `Edit Bio text`,
  Grayed_logo_will_not_be_displayed_to_your_fans_Click_to_add_social_link_for_the_logo_: `Grayed logo will not be displayed to your fans.
Click to add social link for the logo.`,
  Edit_Name: `Edit Name`,
  Display_Name: `Display Name`,
  Please_enter: `Please enter`,
  No_more_than_50_characters: `No more than 50 characters`,
  Upload_Image: `Upload Image`,
  Support__JPG__JPEG__GIF__PNG__BMP__WebP__up_to_30MB: `Support: JPG, JPEG, GIF, PNG, BMP, WebP, up to 20MB`,
  Use_Image: `Use Image`,
  No_more_than_500_characters: `No more than 500 characters`,
  Edit_Bio_text: `Edit Bio text`,
  Are_you_sure_you_want_to_delete_this_social_network_: `Are you sure you want to delete this social network?`,
  Confirm: `Confirm`,
  Cancel: `Cancel`,
  Delete_successfully: `Delete successfully`,
  Edit_Social_Networks: `Edit Social Networks`,
  Select_an_icon: `Select an icon`,
  Select_link_type: `Select link type`,
  Enter_URL: `Enter URL`,
  Enter_phone_number: `Enter phone number`,
  Enter_email: `Enter email`,
  Add_Social_Network: `Add Social Network`,
  Add_Image_Image_Gallery: `Add Image/Image Gallery`,
  Step_1__Upload_Image: `Step 1: Upload Image`,
  __Add: `+ Add`,
  Next: `Next`,
  The_images_you_upload_will_not_be_saved_: `The images you upload will not be saved.`,
  Are_you_sure_you_want_to_leave_: `Are you sure you want to leave?`,
  Step_2__Add_Image_Description__Optional_: `Step 2: Add Image Description (Optional)`,
  Previous: `Previous`,
  Next_skip_this_step_: `Next(skip this step)`,
  The_content_you_edit_in_this_step_and_the_images_uploaded_in_the_previous_step_will_not_be_saved_: `The content you edit in this step and the images uploaded in the previous step will not be saved.`,
  Are_you_sure_you_want_to_leave_: `Are you sure you want to leave?`,
  Step_3__Add_link_for_Images__Optional_: `Step 3: Add link for Images (Optional)`,
  Done__skip_this_step_: `Done (skip this step)`,
  The_content_you_edit_in_this_step_and_the_data_from_the_previous_2_steps_will_not_be_saved_: `The content you edit in this step and the data from the previous 2 steps will not be saved.`,
  Are_you_sure_you_want_to_leave_: `Are you sure you want to leave?`,
  Image_block_title: `Image block title`,
  Image_block_description___: `Image block description...`,
  Click_to_add_image_description__optional_: `Click to add image description (optional)`,
  Image_descriptions_for_each_image_should_not_exceed_50_characters_: `Image descriptions for each image should not exceed 50 characters.`,
  Add_Affiliate_Products: `Add Affiliate Products`,
  Before_adding_affiliate_products_to_the_link__you_need_to_add_products_to_your_wishlist_first_: `Before adding affiliate products to the link, you need to add products to your wishlist first.`,
  The_wishlist_is_empty_now_: `The wishlist is empty now.`,
  Go_to_the_homepage_to_: `Go to the homepage to `,
  add_products_to_wishlist__: `add products to wishlist..`,
  Jump_to_the_homepage_shortly_: `Jump to the homepage shortly.`,
  Back_to_edit: `Back to edit`,
  Please_select_products_that_already_added_to_wishlist_: `Please select products that already added to wishlist.`,
  Product_List: `Product List`,
  Selected___products: `Selected * products`,
  Confirm: `Confirm`,
  Select: `Select`,
  Import_Products: `Import Products`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `Up to 50 products can be imported under each product group.`,
  Product_block_title: `Product block title`,
  Product_block_description: `Product block description`,
  Search_product_by_name_: `Search product by name…`,
  __Add: `+ Add`,
  Youpik_platform_will_deduct_3__service_fee__Ex_VAT__from_fan_tips__If_you_need_an_invoice_for_this_service_fee__you_can_download_it_in__Account___My_Service___Tip_History__after_getting_fan_tips_: `Youpik platform will deduct 3% service fee (Ex-VAT) from fan tips. If you need an invoice for this service fee, you can download it in "Account - My Service - Tip History" after getting fan tips.`,
  Confirm_to_add: `Confirm to add`,
  Added_successfully: `Added successfully`,
  The__User_Agreement__has_been_updated__: `The "User Agreement" has been updated. `,
  Click__Agree__to_continue_using_: `Click "Agree" to continue using.`,
  Agree: `Agree`,
  Do_it_later: `Do it later`,
  Update_successfully: `Update successfully`,
  Added: `Added`,
  Your_current_changes_will_not_be_saved_: `Your current changes will not be saved.`,
  Are_you_sure_you_want_to_re_edit_: `Are you sure you want to re-edit?`,
  Confirm_re_edit: `Confirm re-edit`,
  Cancel_re_edit: `Cancel re-edit`,
  You_are_about_to_exit_: `You are about to exit.`,
  It_s_recommended_to_save_your_current_edits_before_leaving_: `It's recommended to save your current edits before leaving.`,
  Save_and_Publish: `Save and Publish`,
  Still_leave: `Still leave`,
  You_have_successfully_published_your_site_You_can_access_it_through_the_following_url_: `You have successfully published your site.You can access it through the following url.`,
  Your_site_url: `Your site url`,
  Go_to_customize_the_site: `Go to customize the site`,
  View_site_in_browser: `View site in browser`,
  Share: `Share`,
  Comfirm_to_delete_this_block_: `Comfirm to delete this block?`,
  Confirm_delete_: `Confirm delete?`,
  Comfirm_to_delete_this_product_group_and_all_products_under_it_: `Comfirm to delete this product group and all products under it?`,
  Product_group_name: `Product group name`,
  Add_failed: `Add failed`,
  Give_tip_to____: `Give tip to ***`,
  Be_the_first_to_give_tip_to______: `Be the first to give tip to ****!`,
  the_person: `the person`,
  See_More: `See More`,
  _404___This_page_is_not_be_found_: `404 | This page is not be found.`,
  Preview_mode: `Preview mode`,
  Welcome_to_Youpik_show: `Welcome to Youpik.show`,
  Start_by_following_our_tutuorial_to_experience_the_functions_of_Youpik_show__: `Start by following our tutuorial to experience the functions of Youpik.show !`,
  Get_Started: `Get Started`,
  Add_a_new_block: `Add a new block`,
  Several_Block_types_ready_to_add_to_your_site__help_you_create_unique_bio_page__: `Several Block types ready to add to your site, help you create unique bio page. `,
  Skip: `Skip`,
  Quick_Actions: `Quick Actions`,
  Click_to__Move_up__Move_down__Delete__blocks_: `Click to “Move up”“Move down”“Delete” blocks.`,
  And_you_can_also_drag_to_adjust_the_order_by_long_press_the_up_left_button_in_every_block_: `And you can also drag to adjust the order by long press the up-left button in every block.`,
  Edit_the_block: `Edit the block`,
  There_is_an_edit_button_in_the_upper_right_corner_of_every_block__click_to_enter_the_edit_page_to_edit_more_details_: `There is an edit button in the upper right corner of every block, click to enter the edit page to edit more details.`,
  Site_Setting: `Site Setting`,
  You_can_set_up_your_personal_domain_name_for_your_site_here_: `You can set up your personal domain name for your site here.`,
  Block_theme_settings: `Block theme settings`,
  Click_to_customize_the_theme_settings_here__These_settings_will_apply_to_your_entire_site_: `Click to customize the theme settings here. These settings will apply to your entire site.`,
  Restore_editting: `Restore editting`,
  if_you_are_not_satisfied_with_the_changes_you_made__you_can_use_this_function__The_system_will_restore_your_Design_screen_back_to_the_site_version_that_was_publishing_: `if you are not satisfied with the changes you made, you can use this function. The system will restore your Design screen back to the site version that was publishing.`,
  Using_template: `Using template`,
  Replace_your_entire_page_design_with_the_template_design_: `Replace your entire page design with the template design.`,
  If_you_don_t_add_any_block__you_can_also_use_the_template_design_and_data_: `If you don’t add any block, you can also use the template design and data.`,
  User_Guide: `User Guide`,
  you_can_review_the_user_guide_at_any_time_by_clicking_this_button_: `you can review the user guide at any time by clicking this button.`,
  Preview_site: `Preview site`,
  Preview_the_site_to_see_the_current_changes_of_site_Publish_should_only_be_made_once_you_have_previewed_and_are_satisfied_with_the_changes_: `Preview the site to see the current changes of site.
Publish should only be made once you have previewed and are satisfied with the changes.`,
  Publish_design: `Publish design`,
  Design_edits_are_always_in__Draft__mode__The_changes_will_be_visible_to_your_fans_once_you_decide_to__Publish__: `Design edits are always in “Draft” mode. The changes will be visible to your fans once you decide to “Publish”.`,
  Block_Style_Settings: `Block Style Settings`,
  Square: `Square`,
  Rounded: `Rounded`,
  Custom: `Custom`,
  Block_s_padding: `Block's padding`,
  Radius: `Radius`,
  Block_s_margin: `Block's margin`,
  Spacing_between_blocks: `Spacing between blocks`,
  Block_Background_color: `Block Background color`,
  Block_Border: `Block Border`,
  Block_Border_Color: `Block Border Color`,
  Border_Color_Style: `Border Color Style`,
  Solid: `Solid`,
  Dot: `Dot`,
  Dash: `Dash`,
  Block_Border_width_px_: `Block Border width(px)`,
  Site_page_background_setting: `Site page background setting`,
  Color: `Color`,
  //第一期
  Blur_bg: `Blur`,
  View_Template: `View Template`,
  Preview_with_Template: `Preview with Template`,
  your_name: `your name`,
  your_bio_text: `your bio text`,
  Apply_Template_Design_and_Data: `Apply Template Design and Data`,
  Do_you_want_to_apply_the_template_: `Do you want to apply the template?`,
  Applying_the_template_will_replace_all_your_edited_designs_and_data_with_the_template_s_designs_and_data_: `Applying the template will replace all your edited designs and data with the template's designs and data.`,
  Confirm: `Confirm`,
  Applied_Successfully: `Applied Successfully`,
  Apply_Template_Design: `Apply Template Design`,
  Applying_the_template_will_replace_all_your_block_designs_with_the_template_s_designs__while_your_data_remains_unchanged_: `Applying the template will replace all your block designs with the template's designs, while your data remains unchanged.`,
  Profile_Picture: `Head portrait`,
  Bio_Name: `Bio Name`,
  Bio_text: `Bio text`,
  List_of_Social_Networks: `List of Social Networks`,
  Add: `Add`,
  Social_icons_without_setting_links_will_appear_grayed_out_in_the_design_homepage_and_not_displayed_in_the_preview_: `Social icons without setting links will appear grayed-out in the design homepage and not displayed in the preview.`,
  Confirm_to_delete_this_social_network_: `Confirm to delete this social network?`,
  Enter_your_social_network___: `Enter your social network...`,
  Layout: `Layout`,
  Layout_1: `Layout 1`,
  Layout_2: `Layout 2`,
  Layout_3: `Layout 3`,
  Layout_4: `Layout 4`,
  Cancel_editting_: `Cancel editting?`,
  Your_modifications_will_not_be_saved_: `Your modifications will not be saved.`,
  Are_you_sure_you_want_to_exit_: `Are you sure you want to exit?`,
  Note__This_preview_state_is_what_fans_will_see_: `Note: This preview state is what fans will see.`,
  The_preview_and_fan_side_will_not_see_grayed_out_social_icons_without_setting_social_networks_: `The preview and fan side will not see grayed-out social icons without setting social networks.`,
  Image_block_title_optional_: `Image block title(optional)`,
  Image_block_description_optional_: `Image block description(optional)`,
  Image_List: `Image List`,
  Confirm_to_delete_this_image_: `Confirm to delete this image?`,
  The_image_list_is_empty_: `The image list is empty.`,
  To_add_image_s___click_the__Add__button_above_: `To add image(s), click the "Add" button above.`,
  Image_Details_Page: `Image Details Page`,
  Image: `Image`,
  Image_Description: `Image Description`,
  Link: `Link`,
  Select_link_type: `Select link type`,
  Style: `Style`,
  Grid: `Grid`,
  Slide: `Slide`,
  Number_of_Images_per_Row: `Number of Images per Row`,
  Slider_Type: `Slider Type`,
  Partial_Visible: `Partial Visible`,
  Panorama: `Panorama`,
  Manual_Slide: `Manual Slide`,
  Auto_play: `Auto play`,
  Auto_play_Interval: `Auto play Interval`,
  Number_of_Products_per_Row: `Number of Products per Row`,
  Product_Group_List: `Product Group List`,
  Select: `Select`,
  products: `products`,
  Hide_rest_products_in_every_group: `Hide rest products in every group`,
  Hide: `Hide`,
  Expand_to_Show_All: `Expand to Show All`,
  Rename: `Rename`,
  Delete: `Delete`,
  Move_to_the_bottom: `Move to the bottom`,
  Move_to_the_top: `Move to the top`,
  Product_group_name: `Product group name`,
  Add_product_group: `Add product group`,
  All_products_from_your_wishlist_have_been_placed_in_other_product_groups_: `All products from your wishlist have been placed in other product groups.`,
  Go_to_the_homepage_to_: `Go to the homepage to `,
  add_products_to_wishlist__: `add products to wishlist..`,
  Product_Edit_Page: `Product Edit Page`,
  Select_product_group: `Select product group`,
  Product_Import_Page: `Product Import Page`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `Up to 50 products can be imported under each product group.`,
  Selected___product_groups: `Selected * product groups`,
  Confirm_to_delete_the_selected___product_groups_and_all_products_under_them_: `Confirm to delete the selected * product groups and all products under them?`,
  Delete_failed: `Delete failed`,
  Confirm_to_delete_this_product_: `Confirm to delete this product?`,
  Change_product_group: `Change product group`,
  Move_Product: `Move Product`,
  Move___product_to: `Move * product to`,
  Move_product_to_____successfully: `Move product to *** successfully`,
  Move___products_to_____successfully: `Move * products to *** successfully`,
  Each_product_group_supports_importing_up_to_50_products_: `Each product group supports importing up to 50 products.`,
  This_product_group_already_has___products_: `This product group already has * products,`,
  You_can_move_no_more_than___products_: `You can move no more than * products.`,
  Move_to: `Move to`,
  Each_product_group_supports_importing_up_to_50_products_: `Each product group supports importing up to 50 products.`,
  This_product_group_already_has___products_: `This product group already has * products,`,
  You_can_import_no_more_than___products_: `You can import no more than * products.`,
  If_you_don_t_want_to_add_any_of_the_above__go_to_the_homepage_to_select_new_products_to_add_to_the_wishlist_: `If you don't want to add any of the above, go to the homepage to select new products to add to the wishlist.`,
  Save_switch: `Save&switch`,
  Discard_edit_switch: `Discard edit&switch`,
  Product_Details: `Product Details`,
  last_30_days: `last 30 days`,
  Click_the_button_below_to_know_more_about_product_details: `Click the button below to know more about product details`,
  Buy_Now: `Buy Now`,
  Confirm_to_give_tips_to______: `Confirm to give tips to ****?`,
  Tip_Amounts: `Tip Amounts`,
  Please_enter_the_number_: `Please enter the number.`,
  whether_to_be_anonymous: `whether to be anonymous`,
  Anonymous: `Anonymous`,
  Please_enter_your_name_: `Please enter your name.`,
  Leave_the_message_for_the_creator_optional_: `Leave the message for the creator(optional)`,
  Tip_Information: `Tip Information`,
  Tip_to: `Tip to`,
  Select_Payment_Methods: `Select Payment Methods`,
  Total_Amounts: `Total Amounts`,
  youpik_show: `youpik.show`,
  order_from__page: `order from: page`,
  Tip_from_fans: `Tip from fans`,
  Jump_to_the_homepage_shortly_: `Jump to the homepage shortly.`,
  We_recommend_saving_all_data_on_the_Lazada_Affiliate_product_edit_page_before_jumping_: `We recommend saving all data on the Lazada Affiliate product edit page before jumping.`,
  Discard_and_jump: `Discard and jump`,
  Save_and_jump: `Save and jump`,
  Hot_sale_products: `Hot sale products`,
  Popular_products: `Popular products`,
  Please_enter_correct_link: `Please enter correct link`,
  '《用户协议》已更新，点击同意后继续使用': `The "《User Agreement》" has been updated. Click "Agree" to continue using.`,
  Please_enter_correct_E_mail: `Please enter correct E-mail`
}

export const bypTh = {
  No_tip_invoices_generated_at_the_moment_: `ไม่มีอินวอยซ์`,
  Use_Template_: `ตัวอย่าง`,
  Guide: `คู่มือ`,
  Preview: `แสดงตัวอย่าง`,
  Publish: `เผยแพร่`,
  No_block: `ไม่มีบล็อก`,
  No_block_has_been_added_yet: `ยังไม่มีการเพิ่มบล็อกใด ๆ ในขณะนี้`,
  Add_block: `เพิ่มบล็อก`,
  Custom_domain: `กำหนดโดเมน`,
  Block_theme_Settings: `การตั้งค่าธีมบล็อก`,
  Re_edit: `แก้ไขอีกครั้ง`,
  Please_add_blocks_before_publishing_: `โปรดเพิ่มบล็อกก่อนการเผยแพร่!`,
  You_currently_have_no_edited_content__After_publishing__fans_will_see_a_blank_page__It_is_recommended_to_add_and_edit_blocks_before_publishing_: `ขณะนี้คุณยังไม่มีเนื้อหาที่แก้ไข หลังจากเผยแพร่แล้ว ผู้ติดตามของคุณจะเห็นหน้าเปล่า แนะนำให้เพิ่มและแก้ไขบล็อกก่อนการเผยแพร่`,
  Go_to_add_blocks: `เพิ่มบล็อกของคุณ`,
  Custom_domain_name: `กำหนดชื่อโดเมน`,
  If_you_change_your_domain_name__you_ll_have_to_wait_30_days_for_the_next_change_: `หากคุณเปลี่ยนชื่อโดเมน คุณจะต้องรอ 30 วันสำหรับการเปลี่ยนแปลงครั้งถัดไป`,
  The_domain_name_must_start_with_a_letter_and_only_contain_letters__numbers__and_underscores_____: `ชื่อโดเมนจะต้องขึ้นต้นด้วยตัวอักษรและตัวเลข ห้ามใช้อักขระ`,
  This_domain_name_is_already_exists__please_choose_another_one_: `ชื่อลิงก์นี้มีอยู่แล้ว โปรดใช้ชื่ออื่น`,
  Domain_name_cannot_be_longer_than_50_characters: `ชื่อโดเมนไม่สามารถยาวเกิน 50 ตัวอักษร`,
  Your_modifications_will_not_be_saved_: `การแก้ไขของคุณจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_exit_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Continue_editting: `ดำเนินการแก้ไขต่อ`,
  Exit: `ออก`,
  Save: `บันทึก`,
  Copy_successfully: `คัดลอกสำเร็จ`,
  Save_successfully: `บันทึกสำเร็จ`,
  Select_Block_Type: `เลือกประเภทบล็อก`,
  Bio: `Bio`,
  Used_to_display_your_profile_picture__bio_name__bio_text_and_your_social_links_: `ใช้สำหรับแสดงรูปโปรไฟล์ ชื่อ bio และลิงก์โซเชียลของคุณ`,
  Image_Image_Gallery: `รูปภาพและคลังรูปภาพ`,
  Add_single_multiple_images_to_your_site_: `เพิ่มรูปภาพเข้าเว็บไซต์ของคุณ`,
  Lazada_Affiliate: `Lazada Affiliate`,
  Recommend_affiliate_products_to_your_fans_: `แนะนำสินค้า Affiliate ให้กับผู้ติดตามของคุณ`,
  Tip_from_fans: `รางวัลจากผู้ติดตามของคุณ`,
  Receive_tips_from_your_fans_: `ได้รับรางวัลจากผู้ติดตามของคุณ`,
  Your_name: `ชื่อของคุณ`,
  Edit_Bio_text: `แก้ไข Bio ของคุณ`,
  Grayed_logo_will_not_be_displayed_to_your_fans_Click_to_add_social_link_for_the_logo_: `คลิกที่โลโก้เพื่อเพิ่มช่องทางในการแชร์เพจของคุณ`,
  Edit_Name: `แก้ไขชื่อ`,
  Display_Name: `ชื่อที่แสดง`,
  Please_enter: `กดที่นี่`,
  No_more_than_50_characters: `ห้ามเกิน 50 ตัวอักษร`,
  Upload_Image: `อัปโหลดรูปภาพ`,
  Support__JPG__JPEG__GIF__PNG__BMP__WebP__up_to_30MB: `รองรับ: JPG, JPEG, GIF, PNG, BMP, WebP ขนาดไม่เกิน 20MB`,
  Use_Image: `ใช้รูปภาพ`,
  No_more_than_500_characters: `ไม่เกิน 500 ตัวอักษร`,
  Edit_Bio_text: `แก้ไข Bio ของคุณ`,
  Are_you_sure_you_want_to_delete_this_social_network_: `คุณแน่ใจหรือไม่ว่าต้องการลบ Social Networks`,
  Confirm: `ยืนยัน`,
  Cancel: `ยกเลิก`,
  Delete_successfully: `ลบสำเร็จ`,
  Edit_Social_Networks: `แก้ไข Social Networks`,
  Select_an_icon: `เลือกไอคอน`,
  Select_link_type: `เลือกประเภทลิงก์`,
  Enter_URL: `ใส่ URL`,
  Enter_phone_number: `ใส่หมายเลขโทรศัพท์`,
  Enter_email: `ใส่อีเมล`,
  Add_Social_Network: `ใส่ Social Networks`,
  Add_Image_Image_Gallery: `เพิ่มรูปภาพและอัลบั้มรูปภาพ`,
  Step_1__Upload_Image: `ขั้นตอนที่ 1: อัปโหลดรูปภาพ`,
  __Add: `เพิ่ม`,
  Next: `ถัดไป`,
  The_images_you_upload_will_not_be_saved_: `รูปภาพที่คุณอัปโหลดจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_leave_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Step_2__Add_Image_Description__Optional_: `ขั้นตอนที่ 2: เพิ่มคำอธิบายรูปภาพ (ไม่บังคับ)`,
  Previous: `ย้อนกลับ`,
  Next_skip_this_step_: `ถัดไป (ข้ามขั้นตอนนี้)`,
  The_content_you_edit_in_this_step_and_the_images_uploaded_in_the_previous_step_will_not_be_saved_: `เนื้อหาที่คุณแก้ไขในขั้นตอนนี้และรูปภาพที่อัปโหลดในขั้นตอนก่อนหน้าจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_leave_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Step_3__Add_link_for_Images__Optional_: `ขั้นตอนที่ 3: เพิ่มลิงก์สำหรับรูปภาพ (ไม่บังคับ)`,
  Done__skip_this_step_: `เสร็จสิ้น (ข้ามขั้นตอนนี้)`,
  The_content_you_edit_in_this_step_and_the_data_from_the_previous_2_steps_will_not_be_saved_: `เนื้อหาที่คุณแก้ไขในขั้นตอนนี้ และข้อมูลจากขั้นตอนก่อนหน้านี้จะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_leave_: `คุณแน่ใจหรือไม่ว่าต้องการออก`,
  Image_block_title: `ชื่อรูปภาพ`,
  Image_block_description___: `คำบรรยายรูปภาพ`,
  Click_to_add_image_description__optional_: `คลิกเพื่อเพิ่มคำบรรยายรูปภาพ (ไม่บังคับ)`,
  Image_descriptions_for_each_image_should_not_exceed_50_characters_: `คำบรรยายรูปภาพห้ามเกิน 50 ตัวอักษร`,
  Add_Affiliate_Products: `เพิ่มสินค้า Affiliate`,
  Before_adding_affiliate_products_to_the_link__you_need_to_add_products_to_your_wishlist_first_: `ก่อนเพิ่มสินค้า Affiliate คุณต้องเพิ่มสินค้าลงรายการโปรดก่อน`,
  The_wishlist_is_empty_now_: `ไม่มีรายการโปรด`,
  Go_to_the_homepage_to_: `ไปที่หน้าหลักเพื่อ`,
  add_products_to_wishlist__: `เพิ่มสินค้าไปที่รายการโปรด`,
  Jump_to_the_homepage_shortly_: `กำลังไปหน้าหลัก`,
  Back_to_edit: `กลับไปแก้ไข`,
  Please_select_products_that_already_added_to_wishlist_: `โปรดเลือกสินค้าจากในรายการโปรด`,
  Product_List: `รายการสินค้า`,
  Selected___products: `สินค้าที่เลือก`,
  Confirm: `ยืนยัน`,
  Select: `เลือก`,
  Import_Products: `อัปโหลดสินค้า`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `แต่ละรายการสามารถอัปโหลดสินค้าได้สูงสุด 50 รายการ`,
  Product_block_title: `ชื่อบล็อกสินค้า`,
  Product_block_description: `คำอธิบายบล็อกสินค้า`,
  Search_product_by_name_: `ค้นหาชื่อสินค้า`,
  __Add: `เพิ่ม`,
  Youpik_platform_will_deduct_3__service_fee__Ex_VAT__from_fan_tips__If_you_need_an_invoice_for_this_service_fee__you_can_download_it_in__Account___My_Service___Tip_History__after_getting_fan_tips_: `Youpik จะหักค่าบริการ 3% (ยกเว้นภาษีมูลค่าเพิ่ม) จากรางวัลที่ได้รับ หากคุณต้องการอินวอยซ์สำหรับค่าบริการ คุณสามารถดาวน์โหลดได้ที่ "บัญชี - บริการของฉัน - ประวัติการรับรางวัล" หลังจากที่ได้รับรางวัลจากผู้ติดตาม`,
  Confirm_to_add: `ยืนยันการเพิ่ม`,
  Added_successfully: `เพิ่มสำเร็จ`,
  The__User_Agreement__has_been_updated__: `"ข้อตกลงผู้ใช้" ได้รับการอัปเดต`,
  Click__Agree__to_continue_using_: `คลิก "ยอมรับ" เพื่อดำเนินการใช้งานต่อ`,
  Agree: `ยอมรับ`,
  Do_it_later: `ทำในภายหลัง`,
  Update_successfully: `อัปเดตเรียบร้อยแล้ว`,
  Added: `เพิ่มแล้ว`,
  Your_current_changes_will_not_be_saved_: `การเปลี่ยนแปลงปัจจุบันของคุณจะไม่ถูกบันทึก`,
  Are_you_sure_you_want_to_re_edit_: `คุณแน่ใจหรือไม่ว่าต้องการแก้ไขอีกครั้ง`,
  Confirm_re_edit: `ยืนยันการแก้ไข`,
  Cancel_re_edit: `ยกเลิกการแก้ไข`,
  You_are_about_to_exit_: `หากคุณต้องการที่จะออกจากหน้านี้ `,
  It_s_recommended_to_save_your_current_edits_before_leaving_: `กรุณาบันทึกการแก้ไขก่อนออก`,
  Save_and_Publish: `บันทึกและเผยแพร่`,
  Still_leave: `ไม่บันทึก`,
  You_have_successfully_published_your_site_You_can_access_it_through_the_following_url_: `คุณได้เผยแพร่เพจของคุณเรียบร้อยแล้ว สามารถเข้าได้ทาง URL`,
  Your_site_url: `URL ของคุณ`,
  Go_to_customize_the_site: `ปรับแต่งเพจ`,
  View_site_in_browser: `ดูเว็บไซต์ในเบราว์เซอร์`,
  Share: `แชร์`,
  Comfirm_to_delete_this_block_: `ยืนยันการลบบล็อกนี้หรือไม่`,
  Confirm_delete_: `ยืนยันการลบใช่หรือไม่`,
  Comfirm_to_delete_this_product_group_and_all_products_under_it_: `ยืนยันการลบกลุ่มสินค้านี้หรือไม่`,
  Product_group_name: `ชื่อกลุ่มสินค้า`,
  Add_failed: `เพิ่มไม่สำเร็จ`,
  Give_tip_to____: `ให้รับรางวัลกับ`,
  Be_the_first_to_give_tip_to______: `เป็นคนแรกที่ให้รางวัลกับ ****!`,
  the_person: `ผู้ติดตาม`,
  See_More: `ดูเพิ่ม`,
  _404___This_page_is_not_be_found_: `ไม่มีหน้านี้`,
  Preview_mode: `โหมดแสดงตัวอย่าง`,
  Welcome_to_Youpik_show: `ยินดีต้อนรับสู่ Youpik.show`,
  Start_by_following_our_tutuorial_to_experience_the_functions_of_Youpik_show__: `เพื่อการใช้งานที่ง่ายขึ้น เพียงแค่ทำตามคำแนะนำการใช้งานตามนี้`,
  Get_Started: `เริ่มเลย!`,
  Add_a_new_block: `เพิ่มบล็อก`,
  Several_Block_types_ready_to_add_to_your_site__help_you_create_unique_bio_page__: `คุณสามารถสร้างบล็อกบนเพจของคุณได้เลย`,
  Skip: `ข้าม`,
  Quick_Actions: `เพื่อการใช้ที่รวดเร็วขึ้น`,
  Click_to__Move_up__Move_down__Delete__blocks_: `คลิกเพื่อ "เลื่อนขึ้น" "เลื่อนลง" "ลบบล็อก"`,
  And_you_can_also_drag_to_adjust_the_order_by_long_press_the_up_left_button_in_every_block_: `และคุณยังสามารถลากเพื่อปรับลำดับโดยการกดค้างปุ่มด้านบนซ้ายในทุกบล็อก`,
  Edit_the_block: `กรแก้ไขบล็อก`,
  There_is_an_edit_button_in_the_upper_right_corner_of_every_block__click_to_enter_the_edit_page_to_edit_more_details_: `มีปุ่มแก้ไขอยู่ในมุมขวาบนของทุกบล็อก คลิกเพื่อเข้าสู่หน้าแก้ไขเพื่อแก้ไขรายละเอียด`,
  Site_Setting: `ตั้งค่าเพจ`,
  You_can_set_up_your_personal_domain_name_for_your_site_here_: `คุณสามารถตั้งค่าชื่อเพจของคุณได้ที่นี้`,
  Block_theme_settings: `ตั้งค่าธีมบล็อก`,
  Click_to_customize_the_theme_settings_here__These_settings_will_apply_to_your_entire_site_: `คลิกเพื่อปรับแต่งการตั้งค่าธีมที่นี่ การตั้งค่าจะถูกใช้กับเพจของคุณ`,
  Restore_editting: `ล้างการแก้ไข`,
  if_you_are_not_satisfied_with_the_changes_you_made__you_can_use_this_function__The_system_will_restore_your_Design_screen_back_to_the_site_version_that_was_publishing_: `หากคุณต้องการยกเลิกการแก้ไข คุณสามารถเลือก "ล้างการแก้ไข" เพื่อเรียกคืนธีมเดิมของเพจ`,
  Using_template: `เทมเพลต`,
  Replace_your_entire_page_design_with_the_template_design_: `ถ้าเพจของคุณยังไม่มีบล็อกหรือยังไม่ได้ใช้เทมเพล`,
  If_you_don_t_add_any_block__you_can_also_use_the_template_design_and_data_: `ต ระบบจะตั้งค่าเพจของคุณเป็นเทมเพลตเริ่มต้น `,
  User_Guide: `คู่มือ`,
  you_can_review_the_user_guide_at_any_time_by_clicking_this_button_: `หากมีข้อสงสัยเกี่ยวกับการใช้งาน คุณสามารถมาที่ "คู่มือ" ได้ตลอดเวลา`,
  Preview_site: `ดูตัวอย่าง`,
  Preview_the_site_to_see_the_current_changes_of_site_Publish_should_only_be_made_once_you_have_previewed_and_are_satisfied_with_the_changes_: `คุณสามารถดูตัวอย่าง ก่อนการเผยแพร่เพจ`,
  Publish_design: `เผยแพร่`,
  Design_edits_are_always_in__Draft__mode__The_changes_will_be_visible_to_your_fans_once_you_decide_to__Publish__: `การแก้ไขการออกแบบจะอยู่ในโหมด "ร่าง" เสมอ และจะแสดงให้ผู้ติดตามเห็นต่อเมื่อคุณเผยแพร่ `,
  Block_Style_Settings: `ตั้งค่ารูปแบบบล็อก`,
  Square: `สี่เหลี่ยม`,
  Rounded: `มุมโค้ง`,
  Custom: `กำหนดเอง`,
  Block_s_padding: `ช่องว่างภายในบล็อก`,
  Radius: `รัศมี`,
  Block_s_margin: `ขอบของบล็อก`,
  Spacing_between_blocks: `ระยะห่างระหว่างบล็อก`,
  Block_Background_color: `สีพื้นหลั`,
  Block_Border: `ขอบของบล็อก`,
  Block_Border_Color: `สีของขอบบล็อก`,
  Border_Color_Style: `สไตล์สีขอบ`,
  Solid: `ขนาดเส้น`,
  Dot: `จุด`,
  Dash: `เส้นประ`,
  Block_Border_width_px_: `ความกว้างของเส้นขอบบล็อก (px)`,
  Site_page_background_setting: `การตั้งค่าพื้นหลังของเพจ`,
  Color: `สี`,
  Blur: `ความมัวของภาพ`,
  View_Template: `ดูเทมเพลต`,
  Preview_with_Template: `ดูตัวอย่าง`,
  your_name: `ชื่อ`,
  your_bio_text: `Bio`,
  Apply_Template_Design_and_Data: `ใส่เทมเพลตและข้อมูลของคุณ`,
  Do_you_want_to_apply_the_template_: `คุณต้องการที่จะใช้เทมเพลตนี้หรือไม่`,
  Applying_the_template_will_replace_all_your_edited_designs_and_data_with_the_template_s_designs_and_data_: `หากคุณต้องการใช้เทมเพลตนี้ การออกแบบเพจก่อนหน้าจะหายไป`,
  Confirm: `ยืนยัน`,
  Applied_Successfully: `ใช้งานเทมเพลตสำเร็จ`,
  Apply_Template_Design: `ใช้งานเทมเพลต`,
  Applying_the_template_will_replace_all_your_block_designs_with_the_template_s_designs__while_your_data_remains_unchanged_: `หลังจากใช้งานเทมเพลตแล้ว เทมเพลตก่อนหน้าจะถูกแทนที่แต่ข้อมูลยังเหมือนเดิม`,
  Profile_Picture: `รูปโปรไฟล์`,
  Bio_Name: `Bio`,
  Bio_text: `ข้อความ Bio`,
  List_of_Social_Networks: `Social Networks`,
  Add: `เพิ่ม`,
  Social_icons_without_setting_links_will_appear_grayed_out_in_the_design_homepage_and_not_displayed_in_the_preview_: `ช่องทางโซเชียลที่คุณไม่ได้เลือกในการแชร์เพจ โลโก้จะแสดงเป็นสีเทาและไม่แสดงในหน้าตัวอย่าง`,
  Confirm_to_delete_this_social_network_: `คุณแน่ใจหรือไม่ว่าคุณต้องการ Social Networks หรือไม่`,
  Enter_your_social_network___: `ใส่ Social Networks ของคุณ`,
  Layout: `เค้าโครง`,
  Layout_1: `เค้าโครง 1`,
  Layout_2: `เค้าโครง 2`,
  Layout_3: `เค้าโครง 3`,
  Layout_4: `เค้าโครง 4`,
  Cancel_editting_: `ยกเลิกการแก้ไขหรือไม่`,
  Note__This_preview_state_is_what_fans_will_see_: `หมายเหตุ: ตัวอย่างที่ผู้ติดตามคุณจะเห็น`,
  The_preview_and_fan_side_will_not_see_grayed_out_social_icons_without_setting_social_networks_: `หากไม่ได้ตั้งค่า Social Networks ผู้ติดตามของคุณจะไม่เห็นไอคอนนี้`,
  Image_block_title_optional_: `ชื่อรูปภาพของบล็อก (ไม่บังคับ)`,
  Image_block_description_optional_: `คำบรรยายรูปภาพของบล็อก (ไม่บังคับ)`,
  Image_List: `รายการรูปภาพ`,
  Confirm_to_delete_this_image_: `ยืนยันที่จะลบรูปภาพ`,
  The_image_list_is_empty_: `ยังไม่มีรูปภาพ`,
  To_add_image_s___click_the__Add__button_above_: `คลิกปุ่มด้านบนเพื่อเพิ่มรูปภาพ`,
  Image_Details_Page: `หน้ารายละเอียดของรูปภาพ`,
  Image: `รูปภาพ`,
  Image_Description: `คำบรรยายรูปภาพ`,
  Link: `ลิงก์`,
  Select_link_type: `เลือกประเภทลิงก์`,
  Style: `สไตล์`,
  Grid: `ตาราง`,
  Slide: `สไลด์`,
  Number_of_Images_per_Row: `จำนวนรูปภาพต่อแถว`,
  Slider_Type: `ประเภทของสไลด์`,
  Partial_Visible: `มองเห็นบางส่วน`,
  Panorama: `พาโนรามา`,
  Manual_Slide: `สไลด์ด้วยตนเอง`,
  Auto_play: `เล่นอัตโนมัติ`,
  Auto_play_Interval: `ระยะเวลาเล่นอัตโนมัติ`,
  Number_of_Products_per_Row: `จำนวนสินค้าในแต่ละแถว`,
  Product_Group_List: `รายการกลุ่มสินค้า`,
  Select: `เลือก`,
  products: `สินค้า`,
  Hide_rest_products_in_every_group: `ซ่อนสินค้าในทุกกลุ่ม`,
  Hide: `ซ่อน`,
  Expand_to_Show_All: `ขยายเพื่อแสดงทั้งหมด`,
  Rename: `เปลี่ยนชื่อ`,
  Delete: `ลบ`,
  Move_to_the_bottom: `ย้ายไปด้านล่าง`,
  Move_to_the_top: `ย้ายไปด้านบน`,
  Product_group_name: `ชื่อกลุ่มสินค้า`,
  Add_product_group: `เพิ่มกลุ่มสินค้า`,
  All_products_from_your_wishlist_have_been_placed_in_other_product_groups_: `สินค้าทั้งหมดจากรายการโปรดได้ย้ายไปยังหน้ากลุ่มสินค้าแล้ว`,
  Go_to_the_homepage_to_: `ไปที่หน้าหลัก`,
  add_products_to_wishlist__: `เพื่อเพิ่มสินค้าไปยังรายการโปรด`,
  Product_Edit_Page: `หน้าสำหรับแก้ไขสินค้า`,
  Select_product_group: `เลือกกลุ่มสินค้า`,
  Product_Import_Page: `หน้าสินค้าที่เลือก`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `สามารถใส่สินค้าในแต่ละกลุ่มสินค้าสูงสุดได้ 50 ชิ้น`,
  Selected___product_groups: `เลือก * กลุ่มสินค้า`,
  Confirm_to_delete_the_selected___product_groups_and_all_products_under_them_: `ยืนยันที่จะลบ`,
  Delete_failed: `การลบล้มเหลว`,
  Confirm_to_delete_this_product_: `ยืนยันที่จะลบสินค้า`,
  Change_product_group: `เปลี่ยนกลุ่มสินค้า`,
  Move_Product: `ย้ายสินค้า`,
  Move___product_to: `ย้าย * สินค้าไปยัง`,
  Move_product_to_____successfully: `ย้าย * สินค้าไปยัง *** เสร็จสิ้น`,
  Move___products_to_____successfully: `ย้าย * สินค้าไปยัง *** เสร็จสิ้น`,
  Each_product_group_supports_importing_up_to_50_products_: `แต่ละกลุ่มสินค้าสามารถเพิ่มสินค้าได้สูงสุด 50 รายการ`,
  This_product_group_already_has___products_: `สินค้านี้มีอยู่แล้ว *`,
  You_can_move_no_more_than___products_: `คุณสามารถเพิ่มสินค้าได้ไม่เกิน * ชิ้น`,
  Move_to: `ย้ายไปยัง`,
  Each_product_group_supports_importing_up_to_50_products_: `แต่ละกลุ่มสินค้าสามารถเพิ่มสินค้าได้สูงสุด 50 รายการ`,
  This_product_group_already_has___products_: `สินค้านี้มีอยู่แล้ว *`,
  You_can_import_no_more_than___products_: `คุณสามารถเพิ่มสินค้าได้ไม่เกิน * ชิ้น`,
  If_you_don_t_want_to_add_any_of_the_above__go_to_the_homepage_to_select_new_products_to_add_to_the_wishlist_: `หากไม่ต้องเพิ่มสินค้าแล้ว กลับสู่หน้าโฮมเพจเพื่อเลือกสินค้าเข้าไปในรายการโปรด`,
  Save_switch: `บันทึก`,
  Discard_edit_switch: `ยกเลิกการแก้ไข`,
  Product_Details: `รายละเอียดสินค้า`,
  last_30_days: `สำหรับรายละเอียสินค้า`,
  Click_the_button_below_to_know_more_about_product_details: `คลิกที่ปุ่มด้านล่าง`,
  Buy_Now: `ซื้อทันที`,
  Confirm_to_give_tips_to______: `ยืนยันที่จะรางวัลใ้กับ **** ใช่ไหม`,
  Tip_Amounts: `จำนวนรางวัล`,
  Please_enter_the_number_: `โปรดใส่จำนวนต้องที่การรางวัล`,
  whether_to_be_anonymous: `ต้องการระบุชื่อหรือไม่`,
  Anonymous: `นามแฝง`,
  Please_enter_your_name_: `ระบุชื่อของคุณ`,
  Leave_the_message_for_the_creator_optional_: `ฝากข้อความให้กับครีเอเตอร์คนโปรดของคุณ`,
  Tip_Information: `รายละเอียกการรางวัล`,
  Tip_to: `รางวัลให้กับ`,
  Select_Payment_Methods: `เลือกวิธีการชำระเงิน`,
  Total_Amounts: `ยอดเงินรวม`,
  youpik_show: `Youpik.show`,
  order_from__page: `คำสั่งซื้อจาก: หน้า`,
  Tip_from_fans: `รางวัลจากผู้ติดตาม`,
  Jump_to_the_homepage_shortly_: `กำลังไปหน้าหลักในไม่ช้านี้`,
  We_recommend_saving_all_data_on_the_Lazada_Affiliate_product_edit_page_before_jumping_: `กรุณาบันทึกก่อนที่จะไปยัง Lazada`,
  Discard_and_jump: `ไม่บันทึกและข้าม`,
  Save_and_jump: `บันทึกและข้าม`,
  Hot_sale_products: `สินค้าขายดี`,
  Popular_products: `สินค้ายอดนิยม`,
  Please_enter_correct_link: `กรุณาใส่ลิงก์ที่ถูกต้อง`,
  '《用户协议》已更新，点击同意后继续使用': `"《ข้อตกลงผู้ใช้》" ได้รับการอัปเดตคลิก "ยอมรับ" เพื่อดำเนินการใช้งานต่อ`,
  Please_enter_correct_E_mail: `กรุณากรอกอีเมลที่ถูกต้อง`
}

export const bypVi = {
  No_tip_invoices_generated_at_the_moment_: `Không có hóa đơn tiền boa nào được tạo ra tại thời điểm này.`,
  Use_Template_: `Sử dụng Mẫu`,
  Guide: `Hướng dẫn`,
  Preview: `Xem trước`,
  Publish: `Xuất bản`,
  No_block: `Không có khối`,
  No_block_has_been_added_yet: `Chưa có khối nào được thêm`,
  Add_block: `Thêm khối`,
  Custom_domain: `Tên miền tùy chỉnh`,
  Block_theme_Settings: `Cài đặt chủ đề khối`,
  Re_edit: `Chỉnh sửa lại`,
  Please_add_blocks_before_publishing_: `Vui lòng thêm khối trước khi xuất bản!`,
  You_currently_have_no_edited_content__After_publishing__fans_will_see_a_blank_page__It_is_recommended_to_add_and_edit_blocks_before_publishing_: `Hiện tại bạn không có nội dung chỉnh sửa nào. Sau khi xuất bản, người hâm mộ sẽ thấy một trang trống. Nên thêm và chỉnh sửa các khối trước khi xuất bản.`,
  Go_to_add_blocks: `Đi đến thêm khối`,
  Custom_domain_name: `Tên miền tùy chỉnh`,
  If_you_change_your_domain_name__you_ll_have_to_wait_30_days_for_the_next_change_: `Nếu bạn thay đổi tên miền, bạn sẽ phải đợi 30 ngày cho lần thay đổi tiếp theo.`,
  The_domain_name_must_start_with_a_letter_and_only_contain_letters__numbers__and_underscores_____: `Tên miền phải bắt đầu bằng chữ cái và chỉ chứa chữ cái, số và dấu gạch dưới "_"`,
  This_domain_name_is_already_exists__please_choose_another_one_: `Tên miền này đã tồn tại, vui lòng chọn tên khác.`,
  Domain_name_cannot_be_longer_than_50_characters: `Tên miền không được dài quá 50 ký tự`,
  Continue_editting: `Tiếp tục chỉnh sửa`,
  Exit: `Thoát`,
  Save: `Lưu`,
  Copy_successfully: `Sao chép thành công`,
  Save_successfully: `Lưu thành công`,
  Select_Block_Type: `Chọn Loại Khối`,
  Bio: `Tiểu sử`,
  Used_to_display_your_profile_picture__bio_name__bio_text_and_your_social_links_: `Hiển thị hình ảnh hồ sơ của bạn, tên tiểu sử, văn bản tiểu sử và liên kết xã hội của bạn.`,
  Image_Image_Gallery: `Thư viện Hình ảnh`,
  Add_single_multiple_images_to_your_site_: `Thêm một hoặc nhiều hình ảnh vào trang tiểu sử của bạn.`,
  Lazada_Affiliate: `Liên kết Lazada`,
  Recommend_affiliate_products_to_your_fans_: `Đề xuất các sản phẩm liên kết cho người hâm mộ của bạn.`,
  Tip_from_fans: `Tiền boa từ người hâm mộ`,
  Receive_tips_from_your_fans_: `Nhận tiền boa từ người hâm mộ của bạn.`,
  Your_name: `Tên của bạn`,
  Edit_Bio_text: `Chỉnh sửa văn bản Tiểu sử`,
  Grayed_logo_will_not_be_displayed_to_your_fans_Click_to_add_social_link_for_the_logo_: `Logo xám sẽ không được hiển thị cho người hâm mộ của bạn. Nhấp để thêm liên kết xã hội cho logo.`,
  Edit_Name: `Chỉnh sửa Tên`,
  Display_Name: `Tên hiển thị`,
  Please_enter: `Vui lòng nhập`,
  No_more_than_50_characters: `Không quá 50 ký tự`,
  Upload_Image: `Tải lên Hình ảnh`,
  Support__JPG__JPEG__GIF__PNG__BMP__WebP__up_to_30MB: `Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, lên đến 20MB`,
  Use_Image: `Sử dụng Hình ảnh`,
  No_more_than_500_characters: `Không quá 500 ký tự`,
  Edit_Bio_text: `Chỉnh sửa văn bản Tiểu sử`,
  Are_you_sure_you_want_to_delete_this_social_network_: `Bạn có chắc chắn muốn xóa mạng xã hội này không?`,
  Confirm: `Xác nhận`,
  Cancel: `Hủy`,
  Delete_successfully: `Xóa thành công`,
  Edit_Social_Networks: `Chỉnh sửa Mạng Xã hội`,
  Select_an_icon: `Chọn biểu tượng`,
  Select_link_type: `Chọn loại liên kết`,
  Enter_URL: `Nhập URL`,
  Enter_phone_number: `Nhập số điện thoại`,
  Enter_email: `Nhập email`,
  Add_Social_Network: `Thêm Mạng Xã hội`,
  Add_Image_Image_Gallery: `Thêm Hình ảnh/Thư viện Hình ảnh`,
  Step_1__Upload_Image: `Bước 1: Tải lên Hình ảnh`,
  __Add: `+ Thêm`,
  Next: `Tiếp theo`,
  The_images_you_upload_will_not_be_saved_: `Các hình ảnh bạn tải lên sẽ không được lưu.`,
  Are_you_sure_you_want_to_leave_: `Bạn có chắc chắn muốn rời đi không?`,
  Step_2__Add_Image_Description__Optional_: `Bước 2: Thêm Mô tả Hình ảnh (Tùy chọn)`,
  Previous: `Trước`,
  Next_skip_this_step_: `Tiếp theo (bỏ qua bước này)`,
  The_content_you_edit_in_this_step_and_the_images_uploaded_in_the_previous_step_will_not_be_saved_: `Nội dung bạn chỉnh sửa trong bước này và các hình ảnh tải lên trong bước trước sẽ không được lưu.`,
  Are_you_sure_you_want_to_leave_: `Bạn có chắc chắn muốn rời đi không?`,
  Step_3__Add_link_for_Images__Optional_: `Bước 3: Thêm liên kết cho Hình ảnh (Tùy chọn)`,
  Done__skip_this_step_: `Hoàn tất (bỏ qua bước này)`,
  The_content_you_edit_in_this_step_and_the_data_from_the_previous_2_steps_will_not_be_saved_: `Nội dung bạn chỉnh sửa trong bước này và dữ liệu từ 2 bước trước sẽ không được lưu.`,
  Image_block_title: `Tiêu đề khối Hình ảnh`,
  Image_block_description___: `Mô tả khối Hình ảnh...`,
  Click_to_add_image_description__optional_: `Nhấp để thêm mô tả hình ảnh (tùy chọn)`,
  Image_descriptions_for_each_image_should_not_exceed_50_characters_: `Mô tả hình ảnh cho mỗi hình ảnh không nên vượt quá 50 ký tự.`,
  Add_Affiliate_Products: `Thêm Sản phẩm Liên kết`,
  Before_adding_affiliate_products_to_the_link__you_need_to_add_products_to_your_wishlist_first_: `Trước khi thêm sản phẩm liên kết vào liên kết, bạn cần thêm sản phẩm vào danh sách yêu thích của mình trước.`,
  The_wishlist_is_empty_now_: `Danh sách yêu thích hiện đang trống.`,
  Go_to_the_homepage_to_: `Đi đến trang chủ để`,
  add_products_to_wishlist__: `thêm sản phẩm vào danh sách yêu thích..`,
  Jump_to_the_homepage_shortly_: `Sẽ chuyển đến trang chủ ngay.`,
  Back_to_edit: `Quay lại chỉnh sửa`,
  Please_select_products_that_already_added_to_wishlist_: `Vui lòng chọn sản phẩm đã được thêm vào danh sách yêu thích.`,
  Product_List: `Danh sách Sản phẩm`,
  Selected___products: `Đã chọn * sản phẩm`,
  Confirm: `Xác nhận`,
  Select: `Chọn`,
  Import_Products: `Nhập Sản phẩm`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `Tối đa 50 sản phẩm có thể được nhập trong mỗi nhóm sản phẩm.`,
  Product_block_title: `Tiêu đề khối Sản phẩm`,
  Product_block_description: `Mô tả khối Sản phẩm`,
  Search_product_by_name_: `Tìm kiếm sản phẩm theo tên...`,
  __Add: `+ Thêm`,
  Youpik_platform_will_deduct_3__service_fee__Ex_VAT__from_fan_tips__If_you_need_an_invoice_for_this_service_fee__you_can_download_it_in__Account___My_Service___Tip_History__after_getting_fan_tips_: `Nền tảng Youpik sẽ khấu trừ 3% phí dịch vụ (Chưa VAT) từ tiền boa của người hâm mộ. Nếu bạn cần hóa đơn cho khoản phí dịch vụ này, bạn có thể tải xuống trong "Tài khoản - Dịch vụ của tôi - Lịch sử tiền boa" sau khi nhận được tiền boa.`,
  Confirm_to_add: `Xác nhận thêm`,
  Added_successfully: `Thêm thành công`,
  The__User_Agreement__has_been_updated__: `Thỏa thuận Người dùng đã được cập nhật.`,
  Click__Agree__to_continue_using_: `Nhấp vào "Đồng ý" để tiếp tục sử dụng.`,
  Agree: `Đồng ý`,
  Do_it_later: `Để sau`,
  Update_successfully: `Cập nhật thành công`,
  Added: `Đã thêm`,
  Your_current_changes_will_not_be_saved_: `Các thay đổi hiện tại của bạn sẽ không được lưu.`,
  Are_you_sure_you_want_to_re_edit_: `Bạn có chắc chắn muốn chỉnh sửa lại không?`,
  Confirm_re_edit: `Xác nhận chỉnh sửa lại`,
  Cancel_re_edit: `Hủy chỉnh sửa lại`,
  You_are_about_to_exit_: `Bạn sắp thoát ra.`,
  It_s_recommended_to_save_your_current_edits_before_leaving_: `Nên lưu các chỉnh sửa hiện tại của bạn trước khi rời đi.`,
  Save_and_Publish: `Lưu và Xuất bản`,
  Still_leave: `Vẫn rời đi`,
  You_have_successfully_published_your_site_You_can_access_it_through_the_following_url_: `Bạn đã xuất bản thành công trang web của mình. Bạn có thể truy cập nó thông qua URL sau.`,
  Your_site_url: `URL trang web của bạn`,
  Go_to_customize_the_site: `Đi đến tùy chỉnh trang web`,
  View_site_in_browser: `Xem trang web trong trình duyệt`,
  Share: `Chia sẻ`,
  Comfirm_to_delete_this_block_: `Xác nhận xóa khối này?`,
  Confirm_delete_: `Xác nhận xóa?`,
  Comfirm_to_delete_this_product_group_and_all_products_under_it_: `Xác nhận xóa nhóm sản phẩm này và tất cả các sản phẩm trong đó?`,
  Product_group_name: `Tên nhóm sản phẩm`,
  Add_failed: `Thêm thất bại`,
  Give_tip_to____: `Tặng tiền boa cho ***`,
  Be_the_first_to_give_tip_to______: `Hãy là người đầu tiên tặng tiền boa cho ****!`,
  the_person: `người đó`,
  See_More: `Xem Thêm`,
  _404___This_page_is_not_be_found_: `404 | Trang này không được tìm thấy.`,
  Preview_mode: `Chế độ xem trước`,
  Welcome_to_Youpik_show: `Chào mừng đến với Youpik.show`,
  Start_by_following_our_tutuorial_to_experience_the_functions_of_Youpik_show__: `Bắt đầu bằng cách theo dõi hướng dẫn của chúng tôi để trải nghiệm các chức năng của Youpik.show!`,
  Get_Started: `Bắt đầu`,
  Add_a_new_block: `Thêm khối mới`,
  Several_Block_types_ready_to_add_to_your_site__help_you_create_unique_bio_page__: `Nhiều loại khối sẵn sàng để thêm vào trang web của bạn, giúp bạn tạo trang tiểu sử độc đáo.`,
  Skip: `Bỏ qua`,
  Quick_Actions: `Hành động Nhanh`,
  Click_to__Move_up__Move_down__Delete__blocks_: `Nhấp để “Di chuyển lên”“Di chuyển xuống”“Xóa” các khối.`,
  And_you_can_also_drag_to_adjust_the_order_by_long_press_the_up_left_button_in_every_block_: `Bạn cũng có thể kéo để điều chỉnh thứ tự bằng cách nhấn và giữ nút ở góc trên bên trái của mỗi khối.`,
  Edit_the_block: `Chỉnh sửa khối`,
  There_is_an_edit_button_in_the_upper_right_corner_of_every_block__click_to_enter_the_edit_page_to_edit_more_details_: `Có một nút chỉnh sửa ở góc trên bên phải của mỗi khối, nhấp vào để vào trang chỉnh sửa và chỉnh sửa chi tiết hơn.`,
  Site_Setting: `Cài đặt Trang`,
  You_can_set_up_your_personal_domain_name_for_your_site_here_: `Bạn có thể thiết lập tên miền cá nhân cho trang web của mình tại đây.`,
  Block_theme_settings: `Cài đặt chủ đề khối`,
  Click_to_customize_the_theme_settings_here__These_settings_will_apply_to_your_entire_site_: `Nhấp để tùy chỉnh cài đặt chủ đề tại đây. Các cài đặt này sẽ áp dụng cho toàn bộ trang web của bạn.`,
  Restore_editting: `Khôi phục chỉnh sửa`,
  if_you_are_not_satisfied_with_the_changes_you_made__you_can_use_this_function__The_system_will_restore_your_Design_screen_back_to_the_site_version_that_was_publishing_: `Nếu bạn không hài lòng với các thay đổi đã thực hiện, bạn có thể sử dụng chức năng này. Hệ thống sẽ khôi phục màn hình Thiết kế của bạn trở lại phiên bản trang web đang xuất bản.`,
  Using_template: `Sử dụng mẫu`,
  Replace_your_entire_page_design_with_the_template_design_: `Thay thế toàn bộ thiết kế trang của bạn bằng thiết kế mẫu.`,
  If_you_don_t_add_any_block__you_can_also_use_the_template_design_and_data_: `Nếu bạn không thêm bất kỳ khối nào, bạn cũng có thể sử dụng thiết kế và dữ liệu mẫu.`,
  User_Guide: `Hướng dẫn Sử dụng`,
  you_can_review_the_user_guide_at_any_time_by_clicking_this_button_: `Bạn có thể xem lại hướng dẫn sử dụng bất cứ lúc nào bằng cách nhấp vào nút này.`,
  Preview_site: `Xem trước trang web`,
  Preview_the_site_to_see_the_current_changes_of_site_Publish_should_only_be_made_once_you_have_previewed_and_are_satisfied_with_the_changes_: `Xem trước trang web để thấy các thay đổi hiện tại của trang web. Chỉ nên xuất bản khi bạn đã xem trước và hài lòng với các thay đổi.`,
  Publish_design: `Xuất bản thiết kế`,
  Design_edits_are_always_in__Draft__mode__The_changes_will_be_visible_to_your_fans_once_you_decide_to__Publish__: `Chỉnh sửa thiết kế luôn ở chế độ "Bản nháp". Các thay đổi sẽ hiển thị cho người hâm mộ của bạn khi bạn quyết định "Xuất bản".`,
  Block_Style_Settings: `Cài đặt Phong cách Khối`,
  Square: `Vuông`,
  Rounded: `Tròn`,
  Custom: `Tùy chỉnh`,
  Block_s_padding: `Đệm của Khối`,
  Radius: `Bán kính`,
  Block_s_margin: `Lề của Khối`,
  Spacing_between_blocks: `Khoảng cách giữa các khối`,
  Block_Background_color: `Màu nền của Khối`,
  Block_Border: `Viền của Khối`,
  Block_Border_Color: `Màu Viền của Khối`,
  Border_Color_Style: `Kiểu Màu Viền`,
  Solid: `Đặc`,
  Dot: `Chấm`,
  Dash: `Gạch ngang`,
  Block_Border_width_px_: `Chiều rộng Viền của Khối (px)`,
  Site_page_background_setting: `Cài đặt nền trang web`,
  Color: `Màu sắc`,
  Blur_bg: `Mờ`,
  View_Template: `Xem Mẫu`,
  Preview_with_Template: `Xem trước với Mẫu`,
  your_name: `tên của bạn`,
  your_bio_text: `văn bản tiểu sử của bạn`,
  Apply_Template_Design_and_Data: `Áp dụng Thiết kế và Dữ liệu Mẫu`,
  Do_you_want_to_apply_the_template_: `Bạn có muốn áp dụng mẫu này không?`,
  Applying_the_template_will_replace_all_your_edited_designs_and_data_with_the_template_s_designs_and_data_: `Áp dụng mẫu sẽ thay thế tất cả thiết kế và dữ liệu đã chỉnh sửa của bạn bằng thiết kế và dữ liệu của mẫu.`,
  Confirm: `Xác nhận`,
  Applied_Successfully: `Áp dụng thành công`,
  Apply_Template_Design: `Áp dụng Thiết kế Mẫu`,
  Applying_the_template_will_replace_all_your_block_designs_with_the_template_s_designs__while_your_data_remains_unchanged_: `Áp dụng mẫu sẽ thay thế tất cả thiết kế khối của bạn bằng thiết kế của mẫu, trong khi dữ liệu của bạn vẫn không thay đổi.`,
  Profile_Picture: `Chân dung`,
  Bio_Name: `Tên Tiểu sử`,
  Bio_text: `Văn bản Tiểu sử`,
  List_of_Social_Networks: `Danh sách Mạng Xã hội`,
  Add: `Thêm`,
  Social_icons_without_setting_links_will_appear_grayed_out_in_the_design_homepage_and_not_displayed_in_the_preview_: `Các biểu tượng xã hội không cài đặt liên kết sẽ xuất hiện màu xám trên trang chủ thiết kế và không được hiển thị trong chế độ xem trước.`,
  Confirm_to_delete_this_social_network_: `Xác nhận xóa mạng xã hội này?`,
  Enter_your_social_network___: `Nhập mạng xã hội của bạn...`,
  Layout: `Bố cục`,
  Layout_1: `Bố cục 1`,
  Layout_2: `Bố cục 2`,
  Layout_3: `Bố cục 3`,
  Layout_4: `Bố cục 4`,
  Cancel_editting_: `Hủy chỉnh sửa?`,
  Your_modifications_will_not_be_saved_: `Các chỉnh sửa của bạn sẽ không được lưu.`,
  Are_you_sure_you_want_to_exit_: `Bạn có chắc chắn muốn thoát không?`,
  Note__This_preview_state_is_what_fans_will_see_: `Lưu ý: Trạng thái xem trước này là những gì người hâm mộ sẽ thấy.`,
  The_preview_and_fan_side_will_not_see_grayed_out_social_icons_without_setting_social_networks_: `Chế độ xem trước và phía người hâm mộ sẽ không thấy các biểu tượng xã hội màu xám nếu không cài đặt mạng xã hội.`,
  Image_block_title_optional_: `Tiêu đề khối hình ảnh (tùy chọn)`,
  Image_block_description_optional_: `Mô tả khối hình ảnh (tùy chọn)`,
  Image_List: `Danh sách Hình ảnh`,
  Confirm_to_delete_this_image_: `Xác nhận xóa hình ảnh này?`,
  The_image_list_is_empty_: `Danh sách hình ảnh trống.`,
  To_add_image_s___click_the__Add__button_above_: `Để thêm hình ảnh, nhấp vào nút "Thêm" phía trên.`,
  Image_Details_Page: `Trang Chi tiết Hình ảnh`,
  Image: `Hình ảnh`,
  Image_Description: `Mô tả Hình ảnh`,
  Link: `Liên kết`,
  Select_link_type: `Chọn loại liên kết`,
  Style: `Phong cách`,
  Grid: `Lưới`,
  Slide: `Trượt`,
  Number_of_Images_per_Row: `Số Hình ảnh mỗi Hàng`,
  Slider_Type: `Loại Trình Chiếu`,
  Partial_Visible: `Một phần hiển thị`,
  Panorama: `Toàn cảnh`,
  Manual_Slide: `Trượt Thủ công`,
  Auto_play: `Tự động phát`,
  Auto_play_Interval: `Khoảng thời gian Tự động phát`,
  Number_of_Products_per_Row: `Số Sản phẩm mỗi Hàng`,
  Product_Group_List: `Danh sách Nhóm Sản phẩm`,
  Select: `Chọn`,
  products: `sản phẩm`,
  Hide_rest_products_in_every_group: `Ẩn các sản phẩm còn lại trong mỗi nhóm`,
  Hide: `Ẩn`,
  Expand_to_Show_All: `Mở rộng để Hiển thị Tất cả`,
  Rename: `Đổi tên`,
  Delete: `Xóa`,
  Move_to_the_bottom: `Di chuyển xuống dưới`,
  Move_to_the_top: `Di chuyển lên trên`,
  Product_group_name: `Tên nhóm sản phẩm`,
  Add_product_group: `Thêm nhóm sản phẩm`,
  All_products_from_your_wishlist_have_been_placed_in_other_product_groups_: `Tất cả sản phẩm trong danh sách yêu thích của bạn đã được đặt trong các nhóm sản phẩm khác.`,
  Go_to_the_homepage_to_: `Đi đến trang chủ để`,
  add_products_to_wishlist__: `thêm sản phẩm vào danh sách yêu thích..`,
  Product_Edit_Page: `Trang Chỉnh sửa Sản phẩm`,
  Select_product_group: `Chọn nhóm sản phẩm`,
  Product_Import_Page: `Trang Nhập khẩu Sản phẩm`,
  Up_to_50_products_can_be_imported_under_each_product_group_: `Mỗi nhóm sản phẩm có thể nhập tối đa 50 sản phẩm.`,
  Selected___product_groups: `Đã chọn * nhóm sản phẩm`,
  Confirm_to_delete_the_selected___product_groups_and_all_products_under_them_: `Xác nhận xóa * nhóm sản phẩm đã chọn và tất cả các sản phẩm bên trong?`,
  Delete_failed: `Xóa không thành công`,
  Confirm_to_delete_this_product_: `Xác nhận xóa sản phẩm này?`,
  Change_product_group: `Thay đổi nhóm sản phẩm`,
  Move_Product: `Di chuyển Sản phẩm`,
  Move___product_to: `Di chuyển * sản phẩm đến`,
  Move_product_to_____successfully: `Di chuyển sản phẩm đến *** thành công`,
  Move___products_to_____successfully: `Di chuyển * sản phẩm đến *** thành công`,
  Each_product_group_supports_importing_up_to_50_products_: `Mỗi nhóm sản phẩm hỗ trợ nhập tối đa 50 sản phẩm.`,
  This_product_group_already_has___products_: `Nhóm sản phẩm này đã có * sản phẩm,`,
  You_can_move_no_more_than___products_: `Bạn không thể di chuyển quá * sản phẩm.`,
  Move_to: `Di chuyển đến`,
  Each_product_group_supports_importing_up_to_50_products_: `Mỗi nhóm sản phẩm hỗ trợ nhập tối đa 50 sản phẩm.`,
  This_product_group_already_has___products_: `Nhóm sản phẩm này đã có * sản phẩm,`,
  You_can_import_no_more_than___products_: `Bạn không thể nhập quá * sản phẩm.`,
  If_you_don_t_want_to_add_any_of_the_above__go_to_the_homepage_to_select_new_products_to_add_to_the_wishlist_: `Nếu bạn không muốn thêm bất kỳ sản phẩm nào trong số trên, hãy đi đến trang chủ để chọn sản phẩm mới thêm vào danh sách yêu thích.`,
  Save_switch: `Lưu và chuyển đổi`,
  Discard_edit_switch: `Bỏ chỉnh sửa và chuyển đổi`,
  Product_Details: `Chi tiết Sản phẩm`,
  last_30_days: `30 ngày qua`,
  Click_the_button_below_to_know_more_about_product_details: `Nhấp vào nút bên dưới để biết thêm chi tiết về sản phẩm`,
  Buy_Now: `Mua ngay`,
  Confirm_to_give_tips_to______: `Xác nhận để đưa tiền tip cho ****?`,
  Tip_Amounts: `Số tiền Tip`,
  Please_enter_the_number_: `Vui lòng nhập số.`,
  whether_to_be_anonymous: `có ẩn danh hay không`,
  Anonymous: `Ẩn danh`,
  Please_enter_your_name_: `Vui lòng nhập tên của bạn.`,
  Leave_the_message_for_the_creator_optional_: `Để lại tin nhắn cho người sáng tạo (tùy chọn)`,
  Tip_Information: `Thông tin Tiền Tip`,
  Tip_to: `Tip cho`,
  Select_Payment_Methods: `Chọn Phương thức Thanh toán`,
  Total_Amounts: `Tổng Số Tiền`,
  youpik_show: `youpik.show`,
  order_from__page: `đặt hàng từ: trang`,
  Tip_from_fans: `Tip từ người hâm mộ`,
  Jump_to_the_homepage_shortly_: `Sẽ chuyển sang trang chủ ngay.`,
  We_recommend_saving_all_data_on_the_Lazada_Affiliate_product_edit_page_before_jumping_: `Chúng tôi khuyên bạn nên lưu tất cả dữ liệu trên trang chỉnh sửa sản phẩm của Lazada Affiliate trước khi chuyển.`,
  Discard_and_jump: `Bỏ qua và chuyển`,
  Save_and_jump: `Lưu và chuyển`,
  Hot_sale_products: `Sản phẩm bán chạy`,
  Popular_products: `Sản phẩm phổ biến`,
  Please_enter_correct_link: `Vui lòng nhập liên kết chính xác`,
  '《用户协议》已更新，点击同意后继续使用': `“《Thỏa thuận Người dùng》” đã được cập nhật. Nhấp vào "Đồng ý" để tiếp tục sử dụng.`,
  Please_enter_correct_E_mail: `Vui lòng nhập E-mail chính xác`,
}