import Taro from '@tarojs/taro'
import user from './user'
import api from './api'

function dingtalkRecord(chain, res) {
  if (!chain.requestParams.url.includes('api.ypkshop.com')) {
    return
  }

  const userInfo = Taro.getStorageSync('userInfo')

  api
    .dingtalkHttpMonitorRobotSend({
      msgtype: 'text',
      text: {
        content: `【H5监控到接口响应500】：

用户手机：${userInfo && userInfo.mobile}

请求信息：${JSON.stringify(chain.requestParams)}

message：${res.data.message}`
      }
    })
    .catch(() => { })
}

const customInterceptor = (chain) => {
  const requestParams = chain.requestParams
  console.log('request start:', chain.requestParams)

  return chain
    .proceed(requestParams)
    .then((res) => {
      // 业务code错误处理
      console.log('request responder:', res, chain)

      const data = res.data
      if (res.statusCode > 400 && res.statusCode < 404) {
        console.log('statusCode 401')
        // user.removeToken()
        return data
      }

      if (data.code == 83006 || data.code == 26001) {
        return data
      }

      if (data.code !== 200) {

        if (data.code === 500) {
          console.log('code 500')
          if (data.message) {
            setTimeout(() => {
              Taro.showToast({
                title: data.message,
                duration: 1000,
                icon: 'none'
              })
            }, 200)
          }
          dingtalkRecord(chain, res)
          return Promise.reject(data)
        }

        if (data.code > 400 && data.code < 404) {
          console.log('code 401')
          setTimeout(() => {
            Taro.showToast({
              title: 'please login again',
              duration: 1000
            })
          }, 200)
          setTimeout(() => {
            // user.removeToken()
          }, 1200)
        }

        if (!chain.requestParams.header.silent) {
          setTimeout(() => {
            Taro.showToast({
              title: data.message,
              duration: 1000,
              icon: 'none'
            })
          }, 200)
        }
        return Promise.reject(data)
      }
      // 返回成功请求后的数据
      return data
    })
    .catch((err) => {
      console.log(111, err)
      if (err.status == 400 && err.url.includes('api-uaa/oauth/token')) {
        setTimeout(() => {
          Taro.showToast({
            title: 'Incorrect account or password',
            duration: 1000,
            icon: 'none'
          })
        }, 200)
        return
      }

      if (err.status > 400 && err.status < 404) {
        setTimeout(() => {
          Taro.showToast({
            title: 'please login again',
            duration: 1000,
            icon: 'none'
          })
        }, 200)
        setTimeout(() => {
          // user.removeToken()
        }, 1200)
      }
      return Promise.reject(err)
    })
}

const interceptors = [customInterceptor, Taro.interceptors.logInterceptor]

export default interceptors
