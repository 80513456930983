import { EventAttr } from '../const'

export const watchClick = (fn: (data: string) => void) => {
  document.addEventListener('click', function (event) {
    let dom = event.target as Element

    function findout(dom: Element) {
      if (dom.hasAttribute(EventAttr)) {
        return dom.getAttribute(EventAttr)
      } else if (dom.parentElement && dom.parentElement.nodeName !== 'BODY') {
        if (dom.parentElement.hasAttribute(EventAttr)) {
          return dom.parentElement.getAttribute(EventAttr)
        } else {
          return findout(dom.parentElement)
        }
      } else {
        return undefined
      }
    }

    const statisticData = findout(dom)
    statisticData && fn(statisticData)
  })
}
