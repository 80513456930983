export const rechargeEn = {
  step1: '1. Click #“Pay Now”# button below in the page to jump to lazada homepage.',
  step2: '2. Click the wallet icon in Lazada homepage.',
  step3: '3. Click the “Bill Payment” in Lazada Wallet page.'
}

export const rechargeTh = {
  step1: '1. คลิกที่ #"จ่ายบิล"# ด้านล่างเพื่อไปที่ Lazada',
  step2: '2. คลิกปุ่มกระเป๋าเงินที่หน้าแรกของ Lazada',
  step3: '3. คลิกที่ "จ่ายบิล" ในหน้า Lazada Wallet'
}

export const rechargeVi = {
  step1: '1. Nhấp vào nút #“Thanh Toán Ngay”# bên dưới trong trang để chuyển đến trang chủ Lazada.',
  step2: '2. Nhấp vào biểu tượng ví trên trang chủ Lazada.',
  step3: '3. Nhấp vào “Thanh Toán Hóa Đơn” trong trang Ví Lazada.'
};
