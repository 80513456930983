export const activityEn = {
  activity_rules: 'Terms & Conditions',
  end_in: 'End in',
  activity_rules_1:
    'The number of rewards displayed on the scoreboard may be different from the final reward amount received.',
  activity_rules_2: 'Only the direct order value is calculated.',
  activity_rules_3: 'Prizes are subject to 5% VAT.',
  activity_rules_4: 'The award is expected to be paid on February 5, 2022.',
  activity_rules_lo_4: 'It is expected that YOUPIKKER will receive the bonus on February 25, 2022.',
  activity_rules_n_4: 'It is expected that YOUPIKKER will receive the bonus on March 11, 2022.',
  activity_rules_lx_4: 'It is expected that YOUPIKKER will receive the bonus on April 1, 2022.',
  activity_rules_ycsr_4: 'It is expected that YOUPIKKER will receive the bonus on April 29, 2022.',

  activity_rules_5:
    'We reserve the right to change the terms and conditions. If any fraud is found, the increased commission will be invalid.',
  activity_rules_6: 'Other company regulations',

  activity_rules_7: 'The list data will be updated until January 26, 2022',
  activity_rules_lo_7: 'The list data will be updated until February 20, 2022',
  activity_rules_n_7: 'The list data will be updated until March 2, 2022.',
  activity_rules_lx_7: 'The list data will be updated until March 21, 2022',
  activity_rules_ycsr_7: 'The list data will be updated until April 19, 2022',

  estimated: 'Estimated',
  confirmed: 'Confirmed',
  estimated_ranking: 'Estimated ranking',

  actual_ranking: 'Actual ranking',
  data_update: 'Data Update',
  no_one_is_on_the_list: 'No Data',
  activity_has_ended: 'Promotion has ended',
  view_details: 'View Details',
  don_t_remind_again: 'Never Remind',
  begin_in: 'Start in',
  join_now: 'Join Now',
  Valid_for: 'expire in ',
  from_collection: ' days',
  //下单奖励
  place_rewards: 'Go to Lazada',
  gotoYoupik: 'Go to Youpik',
  participatedTips:
    'Users who meet the above conditions will receive 20 baht after the order is completed, if they do not meet the above conditions, there will be no 20 baht.',
  needaccessAppTip: 'Participating in this campaign requires access to Youpik APP',
  'Invite you to download the uChoice Pro': `Invite you to download the uChoice Pro and join the community for TikTok creators to make the sales of videos and livestream. Let's earn higher commissions.`,
  Cancel: 'Cancel',
  'Get the APP': 'Get the APP',
  continueShopping: 'Stay',
  downloadApp: 'Download APP',
  thisEventHasEnded: 'The campaign has ended.',
  registered: 'Participated',
  theEventIsFull: 'The campaign is full.',

  Share450_Detail_Title: 'Invite Reward',
  Share450_Time_Rules:
    'Campaign start from 16 - 29 Jan 2023 Announcement of results and receiving rewards on 17 Feb 2023',
  Share450_Completed: 'Completed!',
  Share450_NewYoupikker: 'New Youpikker',
  Share450_Total_Price: 'Order Amount',
  Share450_Total_Price2: 'Amounts of completed orders',
  Share450_Total_Congratulation: 'Congratulation!'
}

export const activityTh = {
  activity_rules: 'เงื่อนไขและข้อกำหนด',
  end_in: 'สิ้นสุดใน',
  activity_rules_1: 'จำนวนรางวัลที่แสดงบนกระดานคะแนนอาจแตกต่างไปจากจำนวนรางวัลสุดท้ายที่ได้รับ',
  activity_rules_2: 'คำนวณเฉพาะมูลค่าการสั่งซื้อโดยตรง',
  activity_rules_3: 'ของรางวัลไม่รวมภาษีมูลค่าเพิ่ม 5%',

  activity_rules_4: ' รางวัลกิจกรรมคาดว่าจะโอนได้ในวันที่ 5 กุมภาพันธ์ 2022',
  activity_rules_lo_4: ' YOUPIKKER จะได้รับโบนัสประมาณวันที่ 25 กุมภาพันธ์ พ.ศ. 2565',
  activity_rules_n_4: 'รางวัลกิจกรรมคาดว่าจะโอนได้ในวันที่ 11 มีนาคม 2022',
  activity_rules_lx_4: ' คาดว่า YOUPIKKER จะได้รับโบนัสในวันที่ 1 เมษายน 2565',
  activity_rules_ycsr_4: ' คาดว่า YOUPIKKER จะได้รับโบนัสในวันที่ 29 เมษายน 2565',

  activity_rules_5:
    'ขอสงวนสิทธิ์ในการเปลี่ยนแปลงข้อกำหนดและเงื่อนไข หากพบว่ามีการฉ้อโกง ค่าคอมมิชชั่นที่เพิ่มขึ้นจะถือเป็นโมฆะ',
  activity_rules_6: 'ข้อบังคับของบริษัทอื่นๆ',

  activity_rules_7: 'รับข้อมูลรายการสิ้นสุดในวันที่ 26 มกราคม 2022',
  activity_rules_lo_7: 'รับข้อมูลรายการสิ้นสุดในวันที่ 20 กุมภาพันธ์ 2022',
  activity_rules_n_7: 'รับข้อมูลรายการสิ้นสุดในวันที่ 2 มีนาคม 2022',
  activity_rules_lx_7: 'ข้อมูลลีดเดอร์บอร์ดจะอัปเดตจนถึงวันที่ 21 มีนาคม 2565',
  activity_rules_ycsr_7: 'ข้อมูลลีดเดอร์บอร์ดจะอัปเดตจนถึงวันที่ 19 เมษายน 2565',

  estimated: 'โดยประมาณ',
  confirmed: 'ยอดขายที่ยืนยัน',
  estimated_ranking: 'อันดับโดยประมาณ',
  actual_ranking: 'อันดับตามจริง',
  data_update: 'อัพเดทข้อมูล',
  no_one_is_on_the_list: 'ไม่มีข้อมูล',
  activity_has_ended: 'หมดเวลาโปรโมชั่นแล้ว',
  view_details: 'รายละเอียด',
  don_t_remind_again: 'อย่าเตือนอีก',
  begin_in: 'เริ่มใน',
  join_now: 'เข้าร่วมเดี๋ยวนี้',
  Valid_for: 'หมดอายุภายใน ',
  from_collection: ' วัน',
  //下单奖励
  place_rewards: 'Go to Lazada',
  gotoYoupik: 'Go to Youpik',
  participatedTips:
    'ผู้ใช้ที่ปฎิบัติตามเงื่อนไขข้างต้นจะได้รับเงินคืน 20 บาทหลังจากคำสั่งซื้อเสร็จสมบูรณ์ หากไม่ปฎิบัติตามเงื่อนไขดังกล่าวจะไม่ได้เงินคืน 20 บาท',
  needaccessAppTip: 'ดาวน์โหลด Youpik เพื่อรับสิทธิพิเศษเลย!',
  'Invite you to download the uChoice Pro': `ถ้าอยากเพิ่มยอดขายบน TikTok และรับค่าคอมที่มากกว่า ดาวน์โหลด uChoice Pro เลย!`,
  Cancel: 'ยกเลิก',
  'Get the APP': 'ดาวน์โหลด',
  continueShopping: 'ไม่ดีกว่า',
  downloadApp: 'ดาวน์โหลด Youpik',
  thisEventHasEnded: 'กิจกรรมสิ้นสุดแล้ว',
  registered: 'ใช้สิทธิแล้ว',
  theEventIsFull: 'สิทธิเต็มจำนวนแล้ว',

  Share450_Detail_Title: 'ส่งคำเชิญเพื่อรับโบนัส',
  Share450_Time_Rules:
    'เริ่มร่วมกิจกรรมตั้งแต่วันที่ 16 - 29 ม.ค. 66 ประกาศผลและได้รับเงินโบนัส วันที่ 17 ก.พ. 66',
  Share450_Completed: 'ชวนเพื่อนสำเร็จ!',
  Share450_NewYoupikker: 'ผู้ใช้ใหม่',
  Share450_Total_Price: 'จำนวนคำสั่งซื้อ',
  Share450_Total_Price2: 'คำสั่งซื้อสำเร็จทั้งหมด',
  Share450_Total_Congratulation: 'ยินดีด้วย คุณทำภารกิจสำเร็จ!'
}

export const activityVi = {
  activity_rules: 'Điều khoản & Điều kiện',
  end_in: 'Kết thúc trong',
  activity_rules_1:
    'Số lượng phần thưởng hiển thị trên bảng xếp hạng có thể khác với số tiền thưởng cuối cùng nhận được.',
  activity_rules_2: 'Chỉ giá trị đơn hàng trực tiếp mới được tính.',
  activity_rules_3: 'Giải thưởng phải chịu 5% VAT.',
  activity_rules_4: 'Dự kiến giải thưởng sẽ được thanh toán vào ngày 5 tháng 2 năm 2022.',
  activity_rules_lo_4: 'Dự kiến YOUPIKKER sẽ nhận được tiền thưởng vào ngày 25 tháng 2 năm 2022.',
  activity_rules_n_4: 'Dự kiến YOUPIKKER sẽ nhận được tiền thưởng vào ngày 11 tháng 3 năm 2022.',
  activity_rules_lx_4: 'Dự kiến YOUPIKKER sẽ nhận được tiền thưởng vào ngày 1 tháng 4 năm 2022.',
  activity_rules_ycsr_4: 'Dự kiến YOUPIKKER sẽ nhận được tiền thưởng vào ngày 29 tháng 4 năm 2022.',

  activity_rules_5:
    'Chúng tôi có quyền thay đổi các điều khoản và điều kiện. Nếu phát hiện có gian lận, hoa hồng tăng thêm sẽ bị vô hiệu.',
  activity_rules_6: 'Các quy định khác của công ty',

  activity_rules_7: 'Dữ liệu danh sách sẽ được cập nhật đến ngày 26 tháng 1 năm 2022',
  activity_rules_lo_7: 'Dữ liệu danh sách sẽ được cập nhật đến ngày 20 tháng 2 năm 2022',
  activity_rules_n_7: 'Dữ liệu danh sách sẽ được cập nhật đến ngày 2 tháng 3 năm 2022.',
  activity_rules_lx_7: 'Dữ liệu danh sách sẽ được cập nhật đến ngày 21 tháng 3 năm 2022',
  activity_rules_ycsr_7: 'Dữ liệu danh sách sẽ được cập nhật đến ngày 19 tháng 4 năm 2022',

  estimated: 'Ước tính',
  confirmed: 'Đã xác nhận',
  estimated_ranking: 'Xếp hạng ước tính',

  actual_ranking: 'Xếp hạng thực tế',
  data_update: 'Cập nhật dữ liệu',
  no_one_is_on_the_list: 'Không có dữ liệu',
  activity_has_ended: 'Khuyến mãi đã kết thúc',
  view_details: 'Xem chi tiết',
  don_t_remind_again: 'Không nhắc lại',
  begin_in: 'Bắt đầu trong',
  join_now: 'Tham gia ngay',
  Valid_for: 'hết hạn trong',
  from_collection: ' ngày',
  //下单奖励
  place_rewards: 'Đi đến Lazada',
  gotoYoupik: 'Đi đến Youpik',
  participatedTips:
    'Người dùng đáp ứng các điều kiện trên sẽ nhận được 20 baht sau khi hoàn thành đơn hàng, nếu không đáp ứng các điều kiện trên sẽ không nhận được 20 baht.',
  needaccessAppTip: 'Tham gia chiến dịch này yêu cầu truy cập vào ứng dụng Youpik',
  'Invite you to download the uChoice Pro': `Mời bạn tải xuống uChoice Pro và tham gia cộng đồng cho các nhà sáng tạo TikTok để tăng doanh số bán hàng từ video và livestream. Hãy kiếm hoa hồng cao hơn.`,
  Cancel: 'Hủy',
  'Get the APP': 'Tải ứng dụng',
  continueShopping: 'Tiếp tục mua sắm',
  downloadApp: 'Tải ứng dụng',
  thisEventHasEnded: 'Chiến dịch đã kết thúc.',
  registered: 'Đã tham gia',
  theEventIsFull: 'Chiến dịch đã đầy.',

  Share450_Detail_Title: 'Phần thưởng mời',
  Share450_Time_Rules:
    'Chiến dịch diễn ra từ ngày 16 - 29 tháng 1 năm 2023. Công bố kết quả và nhận phần thưởng vào ngày 17 tháng 2 năm 2023',
  Share450_Completed: 'Hoàn thành!',
  Share450_NewYoupikker: 'Youpikker mới',
  Share450_Total_Price: 'Số tiền đặt hàng',
  Share450_Total_Price2: 'Số tiền đơn hàng đã hoàn thành',
  Share450_Total_Congratulation: 'Chúc mừng!'
}
