import Taro from '@tarojs/taro'
import route from './route'

const memberLevelTranslation = (args) => {
  let memberLevel = 0

  if (window.ReactNativeWebView && route.getRouteParams().memberLevel) {
    memberLevel = parseInt(route.getRouteParams().memberLevel)
  } else {
    const userInfo = JSON.parse(Taro.getStorageSync('userInfo') || '{}')
    memberLevel = userInfo.memberLevel || 0
  }

  for (const key of Object.keys(args)) {
    if (args[key].includes(memberLevel)) {
      return key
    }
  }
}

export default memberLevelTranslation
