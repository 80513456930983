export const tiktokEn = {
  WhatIsUchoiceTiktok: 'What is Uchoice - Tiktok？',
  LogOut: 'Logout',
  Collected: 'My Wishlist',
  logIn: 'Login',
  Homepage: 'Homepage',
  price: 'Price',
  sales: 'sales',
  Stock: 'Stock',
  Variation: 'Variation',
  CommissionRate: 'Commission%',
  SuccessfullyaddedtoMyWishlist: 'Successfully added to My Wishlist',
  tiktokHomeText1: 'Click the logo to return to the main page.',
  tiktokHomeText2:
    'Uchoice-Tiktok select good quality products and high commission goods for your Tiktok shop to make your Tiktok live selling products more convenient and easier. As well as sharing products to earn commissions. ',
  tiktokHomeText3:
    'You can also select your favorite products onUchoice-Tiktok, then copy the product link and add it to your Tiktok shop.',
  tiktokHomeStepTips: 'Learn more about using Uchoice - Tiktok:',
  banckHone: 'Homepage',
  shopDetail: 'Product Details',
  saleShop: 'Commission per item sold',
  freeShipping: 'Free shipping',
  pdpSkuMessage:
    'เครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผม',
  shopTime: 'Expiry date：',
  ProductDescription: 'Product Description',
  copyLink: 'Copy Link',
  collection: 'Add',
  collectioned: 'Remove',
  All: `All`,
  Sales: `Sales`,
  Price: `Price`,
  Commission: `Commission`,
  Commission_Rate: `Commission%`,
  Earn: `Earn`,
  xxx_Sold: `Sold`,
  Copy_Link: `Copy Link`,
  Until: `Until`,
  Quit: `Quit`,
  Login: `Login`,
  English: `English`,
  Thai: `Thai`,
  Are_you_sure_to_log_out_of_the_current_account_: `Are you sure to log out of the current account?`,
  Earn_xxx_for_every_item_: `Earn xxx for every item `,
  What_is_Uchoice___Tiktok_: `What is Uchoice & Tiktok？`,
  Click_the_logo_to_return_to_the_main_page_: `Click the logo to return to the main page.`,
  Uchoice_select_good_quality_products_and_high_commissions_from_Tiktok_shop_to_make_your_Tiktok_live_selling_products_more_convenient_and_easy__As_well_as_sharing_products_to_earn_commissions__You_can_select_your_favorite_products_on_Uchoice__then_copy_the_product_link_and_add_it_to_your_Tiktok_shop_: `Uchoice select good quality products and high commissions from Tiktok shop to make your Tiktok live selling products more convenient and easy. As well as sharing products to earn commissions. You can select your favorite products on Uchoice, then copy the product link and add it to your Tiktok shop.`,
  Learn_more_about_using_Uchoice_with_Tiktok_: `Learn more about using Uchoice with Tiktok:`,
  Expired: `Expired`,
  Successfully_added_to_My_Favorites: `Successfully added to My Wishlist`,
  Copied_: `Copied!`,
  SoldAfter: 'Sold',
  Sold: 'Sold',
  My_Favorites: `My Wishlist`,
  Clear_all_expired_products: `Clear all expired products`,
  //tiktok授权
  Authorization: `Authorization`,
  Benefits_with_authorization: `Benefits with authorization`,
  Copy_the_High_Commission_product_link: `Copy the High Commission product link`,
  AI_product_selection_with_analysis_fans_portrait: `AI product selection with analysis fans portrait`,
  Millions_of_Tiktok_high_commission_product: `Millions of Tiktok high-commission product`,
  Exclusive_1v1_advisor: `Exclusive 1v1 advisor`,
  Free_Tiktok_selling_training_video: `Free Tiktok selling training video`,
  Priority_quota_for_free_samples: `Priority quota for free samples`,
  Authorize_now: `Authorize now`,
  Cancel: `Cancel`,
  Copy_failed__please_authorize_tiktok_account_and_try_again_: `Copy failed, please authorize tiktok account and try again~`,
  Authorize_succeeded_: `Authorize succeeded!`,
  Link_copied_successfully_: `Link copied successfully!`,
  Authorize_failed__xxxx: `Authorize failed: xxxx`,
  This_Tiktok_account_is_bound_phone_number_0614564076: `This Tiktok account is bound phone number 0614564076`,
  Duplicate_authorization: `Duplicate authorization`,
  Please_try_again: `Please try again`,
  Tiktok_system_error__please_contact_customer_service: `Tiktok system error, please contact customer service`,
  The_request_is_overloaded__please_try_again_later: `The request is overloaded, please try again later`,
  Authorized_and_login_successfully_: `Authorized and login successfully!`,
  Bind_mobile_phone_number: `Bind Phone Number`,
  After_binding__there_is_a_chance_to_get_free_sample_qualification_: `After binding, there is a chance to get free sample qualification~`,
  Phone_number: `Phone number`,
  OTP: `OTP`,
  Send: `Send`,
  Register_or_bind_account: `Register or bind account`,
  Later: `Later`,
  Uchoice_has_detected_that_the_tiktok_account_authorized_by_you_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_this_account_: `Uchoice has detected that the tiktok account authorized by you has been registered and associated with the following tiktok account. Do you want to merge them into this account?`,
  Merge_accounts: `Merge accounts`,
  Uchoice_has_detected_that_the_mobile_phone_number_you_bound_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_one_account_: `Uchoice has detected that the mobile phone number you bound has been registered and associated with the following tiktok account. Do you want to merge them into one account?`,
  Binding_failed__the_phone_number_has_already_been_registered_: `Binding failed: the phone number has already been registered!`,
  Merge_successfully_: `Merge successfully!`,
  Your_Tiktok_account__xxxxxxx_authorization_has_expired__please_re_authorize_: `Your Tiktok account [#] authorization has expired, please re-authorize~`,
  Reauthorize: `Reauthorize`,
  Never_remind: `Never remind`,
  Authorization_has_been_canceled_: `Authorization has been canceled!`,
  Tiktok_Account: `Tiktok Account`,
  Log_in_with_your_mobile_phone_number_and_have_a_chance_to_get_free_samples_: `Log in with your mobile phone number and have a chance to get free samples!`,
  Login_now__: `Login now`,
  Add_Tiktok_account: `Add Tiktok account`,
  pending_reauthorization: `pending reauthorization`,
  The_tiktok_authorized_account_has_expired_and_needs_to_be_re_authorized_: `The tiktok authorized account has expired and needs to be re-authorized.`,
  Authorized: `Authorized`,
  Multiple_Tiktok_accounts_can_only_be_authorized_after_binding_phone_number_: `Multiple Tiktok accounts can only be authorized after binding phone number~`,
  Confirmed_and_Authorize_Now: `Confirmed and Authorize Now`,
  Remind: `Remind: `,
  Before_authorizing__please_switch_and_log_in_to_the_target_account_on_Tiktok_: `Before authorizing, please switch and log in to the target account on Tiktok.`,
  Failed_to_add__fail_to_bind_phone_number_: `Failed to add: fail to bind phone number!`,
  You_have_logged_in__bound_mobile_phone_number: `You have logged in, bound mobile phone number: `,
  Other_Method: `Other Method`,
  Tiktok_Login_register: `Tiktok Login/register`,
  Authorizing: `In the process of authorization, please be patient...`,
  canceled: `Canceled`,
  canceledCollect: 'Cancelled ',
  You_should_bind_the_phone_number_first: `You should bind the phone number first`,
  'Authorization failed': 'Authorization failed: ',
  'Please re-authorize': 'Please try again',
  Merge: `Merge`,
  moreDetails: 'More details',
  OpenApp: 'Open App',
  openAppTips: 'Boom Your Sales Today.',
  soldMonth: '{sold} Sold/Month',
  // 翻译 投流
  feedSample: 'Free Samples',
  salesForLast30Days: 'Last 30 Days Sales',
  salesForTwoWeeksRate: 'Sales Growth %',
  salesStr: 'Total Sales',
  anchorCount: 'Top Sellers',
  addShowCase: 'Add to Showcase',
  addCase: 'Add to Showcase',
  toSample: 'Get free sample',
  //
  活动已过期: 'Campaign Expired',
  '您浏览的活动或商品已下架，首页商品更丰富哦~':
    'The campaign or product you were browsing has ended. Check out uChoice pro homepage for more options!',
  去首页: 'Go to Homepage'
}
export const tiktokTh = {
  Sold: 'ขายแล้ว',
  SoldAfter: 'ชิ้น',
  WhatIsUchoiceTiktok: 'Uchoice - Tiktok คืออะไร',
  sales: 'สินค้าขายดี',
  Stock: 'คลัง',
  price: 'ราคา',
  Variation: 'ตัวเลือก',
  LogOut: 'ออกจากระบบ',
  Collected: 'รายการของฉัน',
  logIn: 'ล็อกอิน',
  Homepage: 'ไปที่หน้าแรก',
  CommissionRate: 'คอมมิชชั่น (%)',
  ProductDescription: 'คำบรรยายสินค้าแบบเต็ม',
  tiktokHomeText1: 'กดโลโก้เพื่อย้อนกลับหน้าหลัก',
  tiktokHomeText2:
    'Uchoice - TikTok ได้คัดสินค้าคุณภาพดีและค่าคอมมิชชั่นสูงจากหลากหลายร้านค้าบน TikTok ที่จะช่วยให้การขายสินค้าผ่านวิดีโอและการไลฟ์สดบน Tiktok ของคุณง่ายและสะดวกมากยิ่งขึ้น ตลอดจนการแชร์สินค้าเพื่อรับค่าคอมมิชชั่น ',
  tiktokHomeText3:
    'โดยคุณสามารถเลือกสินค้าที่ชื่นชอบใน Uchoice - TikTok จากนั้นคัดลอกลิงก์สินค้าและเพิ่มไปยังหน้าร้านค้า Tiktok ของคุณ',
  tiktokHomeStepTips: ' เรียนรู้เพิ่มเติมเกี่ยวกับการใช้ Uchoice - Tiktok',
  banckHone: 'ไปที่หน้าแรก',
  shopDetail: 'รายละเอียดสินค้า',
  saleShop: 'ค่าคอมมิชชั่นโดยประมาณ ',
  freeShipping: 'จัดส่งฟรี',
  pdpSkuMessage:
    'เครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผมเครื่องม้วนผม',
  shopTime: 'เวลาโปรโมทสินค้าจนถึง:',
  copyLink: 'คัดลอกลิงก์',
  collection: 'เพิ่ม',
  collectioned: 'นำออก',
  All: `ทั้งหมด`,
  Sales: `สำเร็จ`,
  Price: `ราคา`,
  Commission: `ค่าคอมสูงสุด 
        xx ฿`,
  Commission_Rate: `ค่าคอมสูงสุด 
        xx %`,
  Earn: `ค่าคอม`,
  xxx_Sold: `ขายแล้ว`,
  Copy_Link: `คัดลอกลิงก์`,
  Until: `สิ้นสุด`,
  Quit: `ออก`,
  Login: `ล็อกอิน`,
  English: `ภาษาอังกฤษ`,
  Thai: `ภาษาไทย`,
  Are_you_sure_to_log_out_of_the_current_account_: `คุณต้องการออกจากระบบใช่หรือไม่`,
  Earn_xxx_for_every_item_: `ทุก 1 ชิ้นที่ขายได้จะได้รับ xxx`,
  What_is_Uchoice___Tiktok_: `Uchoice & Tiktok คืออะไร`,
  Click_the_logo_to_return_to_the_main_page_: `กดโลโก้เพื่อย้อนกลับไปดูหน้าหลัก`,
  Uchoice_select_good_quality_products_and_high_commissions_from_Tiktok_shop_to_make_your_Tiktok_live_selling_products_more_convenient_and_easy__As_well_as_sharing_products_to_earn_commissions__You_can_select_your_favorite_products_on_Uchoice__then_copy_the_product_link_and_add_it_to_your_Tiktok_shop_: `Uchoice ได้คัดสินค้าคุณภาพดีและค่าคอมมิชชั่นสูงจากร้านค้าใน Tiktok เพื่อช่วยให้การขายสินค้าผ่านการไลฟ์สดบน Tiktok ของคุณสะดวกและง่ายมากยิ่งขึ้น ตลอดจนการแชร์สินค้าเพื่อรับค่าคอมมิชชั่น โดยคุณสามารถเลือกสินค้าที่ชื่นชอบใน Uchoice จากนั้นคัดลอกลิงก์สินค้าและเพิ่มไปยังหน้าร้านค้า Tiktok ของคุณ`,
  Learn_more_about_using_Uchoice_with_Tiktok_: `เรียนรู้เพิ่มเติมเกี่ยวกับการใช้ Uchoice ร่วมกับ Tiktok`,
  Expired: `หมดเวลา`,
  Successfully_added_to_My_Favorites: `เพิ่มไปยังรายการโปรด`,
  Copied_: `คัดลอก`,
  My_Favorites: `รายการโปรด`,
  Clear_all_expired_products: `ล้างสินค้าที่`,
  SuccessfullyaddedtoMyWishlist: 'เพิ่มไปยังรายการโปรด',
  //tiktok授权
  Authorization: `เข้าถึงข้อมูล`,
  Benefits_with_authorization: `สิทธิประโยชน์ในการเข้าถึงข้อมูล`,
  Copy_the_High_Commission_product_link: `สามารถคัดลอกลิงก์สินค้าค่าคอมสูง`,
  AI_product_selection_with_analysis_fans_portrait: `ใช้ฟังก์ชันอัจฉริยะ AI ในการเลือกสินค้า `,
  Millions_of_Tiktok_high_commission_product: `สามารถเลือกสินค้าค่าคอมสูงกว่าล้านชิ้นจาก TikTok`,
  Exclusive_1v1_advisor: `ให้คำแนะนำแบบตัวต่อตัว`,
  Free_Tiktok_selling_training_video: `มีเทรนนิ่งการขายจาก TikTok ฟรีไม่มีค่าใช้จ่าย`,
  Priority_quota_for_free_samples: `ได้โควต้ารับสินค้าตัวอย่างฟรีก่อนใคร`,
  Authorize_now: `อนุญาต`,
  Cancel: `ไม่อนุญาต`,
  Copy_failed__please_authorize_tiktok_account_and_try_again_: `คัดลอกไม่สำเร็จ กรุณาอนุญาตการเข้าถึง และลองใหม่อีกครั้ง`,
  Authorize_succeeded_: `อนุญาติเรียบร้อย`,
  Link_copied_successfully_: `คัดลอกลิงก์สำเร็จ`,
  Authorize_failed__xxxx: `อนุญาตไม่สำเร็จ: xxxx`,
  This_Tiktok_account_is_bound_phone_number_0614564076: `บัญชี TikTok นี้ผูกกับหมายเลขโทรศัพท์ 0614564076`,
  Duplicate_authorization: `อนุญาตซ้ำ`,
  Please_try_again: `กรุณาลองอีกครั้ง`,
  Tiktok_system_error__please_contact_customer_service: `TikTok เกิดข้อผิดพลาด กรุณาติดต่อศูนย์ช่วยเหลือ`,
  The_request_is_overloaded__please_try_again_later: `คำขออนุญาตเกินกำหนด กรุณาลองใหม่อีกครั้ง`,
  Authorized_and_login_successfully_: `อนุญาตการเข้าถึงและลงชื่อเข้าใช้เรียบร้อย`,
  Bind_mobile_phone_number: `ผูกหมายเลขโทรศัพท์`,
  After_binding__there_is_a_chance_to_get_free_sample_qualification_: `หลังจากผูกบัญชีเรียบร้อย มีโอกาสได้รับสินค้าตัวอย่างฟรี`,
  Phone_number: `หมายเลขโทรศัพท์`,
  OTP: `รหัส OTP`,
  Send: `ส่ง`,
  Register_or_bind_account: `ลงทะเบียนหรือผูกบัญชี`,
  Later: `ไว้ภายหลัง`,
  Uchoice_has_detected_that_the_tiktok_account_authorized_by_you_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_this_account_: `พบว่าหมายเลขโทรศัพท์ได้ผูกกับบัญชี TikTok ต่อไปนี้ คุณต้องการรวมบัญชี TikTok เข้าด้วยกันใน Uchoice เพื่อการใช้งานที่สะดวกขึ้นหรือไม่`,
  Merge_accounts: `ผูกบัญชี`,
  Uchoice_has_detected_that_the_mobile_phone_number_you_bound_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_one_account_: `พบว่าหมายเลขโทรศัพท์ได้ผูกกับบัญชี TikTok ต่อไปนี้ คุณต้องการรวมบัญชี TikTok เข้าด้วยกันใน Uchoice เพื่อการใช้งานที่สะดวกขึ้นหรือไม่`,
  Binding_failed__the_phone_number_has_already_been_registered_: `ผูกบัญชีไม่สำเร็จ เนื่อจากหมายเลขโทรศัพท์นี้มีการลงทะบียนแล้ว`,
  Merge_successfully_: `ผูกบัญชีเรียบร้อย`,
  Your_Tiktok_account__xxxxxxx_authorization_has_expired__please_re_authorize_: `บัญชี Tiktok ของคุณ [#] การอนุญาตหมดอายุแล้ว โปรดอนุญาตการเข้าถึงข้อมูลอีกครั้ง`,
  Reauthorize: `อนุญาติอีกครั้ง`,
  Never_remind: `ปิดการแจ้งเตือน`,
  Authorization_has_been_canceled_: `ยกเลิกการอนุญาตการเข้าถึงข้อมูล`,
  Tiktok_Account: `บัญชี TikTok`,
  Log_in_with_your_mobile_phone_number_and_have_a_chance_to_get_free_samples_: `เข้าสู่ระบบด้วยหมายเลขโทรศัพท์ มีโอกาสได้รับสินค้าตัวอย่างฟรี`,
  Login_now__: `เข้าสู่ระบบ`,
  Add_Tiktok_account: `เพิ่มบัญชี TikTok`,
  pending_reauthorization: `รออนุญาตการเข้าถึงข้อมูล`,
  The_tiktok_authorized_account_has_expired_and_needs_to_be_re_authorized_: `การอนุญาตเข้าถึงบัญชี Tiktok ของคุณหมดอายุแล้ว โปรดอนุญาตการเข้าถึงข้อมูลอีกครั้ง`,
  Authorized: `ได้รับอนุญาตแล้ว`,
  Multiple_Tiktok_accounts_can_only_be_authorized_after_binding_phone_number_: `หลังจากผูกหมายเลขโทรศัพท์แล้ว จึงจะสามารถอนุญาตการเข้าถึงข้อมูลของ TikTok ได้หลายบัญชี`,
  Confirmed_and_Authorize_Now: `ยืนยัน`,
  Remind: `คำเตือน: `,
  Before_authorizing__please_switch_and_log_in_to_the_target_account_on_Tiktok_: `ก่อนอนุญาต โปรดสลับและลงชื่อเข้าใช้บัญชีที่ได้รับอนุญาตบน Tiktok ก่อน`,
  Failed_to_add__fail_to_bind_phone_number_: `ผูกหมายเลขโทรศัพท์ไม่สำเร็จ`,
  You_have_logged_in__bound_mobile_phone_number: `คุณเข้าสู่ระบบด้วยหมายเลขโทรศัพท์: `,
  Other_Method: `เข้าสู่ระบบใช้ด้วยวิธีอื่น`,
  Tiktok_Login_register: `เข้าสู่ระบบ / ลงทะเบียน เพื่อเข้าใช้ TikTok`,
  Authorizing: `อยู่ในระหว่างดำเนินการอนุญาตการเข้าถึงข้อมูล โปรดรอสักครู่`,
  canceled: `ยกเลิก`,
  canceledCollect: 'ลบออก',
  You_should_bind_the_phone_number_first: `สามารถเพิ่มสินค้าไปยังรายการของฉันได้ หลังจากผูกหมายเลขโทรศัพท์แล้วเท่านั้น`,
  'Authorization failed': 'อนุญาตไม่สำเร็จ: ',
  'Please re-authorize': 'กรุณาลองอีกครั้ง',
  Merge: `การเข้าถึงบัญชี`,
  moreDetails: 'เพิ่มเติม',
  OpenApp: 'เปิดแอป',
  openAppTips: 'สมัครวันนี้ เพื่อเพิ่มยอดขายของคุณ',
  soldMonth: 'ยอดขาย 30 วัน {sold}',
  // 翻译 投流
  feedSample: 'ตัวอย่างฟรี',
  salesForLast30Days: 'ยอดขาย 30 วันล่าสุด',
  salesForTwoWeeksRate: 'Sales Growth %',
  salesStr: 'Total Sales',
  anchorCount: 'Top Sellers',
  addShowCase: 'เพิ่มใน Showcase',
  addCase: 'เพิ่มใน Showcase',
  toSample: 'รับสินค้าตัวอย่างฟรี',
  //
  活动已过期: 'แคมเปญสิ้นสุดแล้ว',
  '您浏览的活动或商品已下架，首页商品更丰富哦~':
    'แคมเปญหรือสินค้าหน้านี้ได้ถูกลบออกแล้ว คุณสามารถกลับไปที่หน้าแรกเพื่อค้นหาสินค้าชิ้นอื่น',
  去首页: 'ไปที่หน้าแรก'
}

export const tiktokVi = {
  WhatIsUchoiceTiktok: 'Uchoice - Tiktok là gì？',
  LogOut: 'Đăng xuất',
  Collected: 'Danh sách yêu thích của tôi',
  logIn: 'Đăng nhập',
  Homepage: 'Trang chủ',
  price: 'Giá',
  sales: 'Doanh số',
  Stock: 'Kho hàng',
  Variation: 'Biến thể',
  CommissionRate: 'Hoa hồng%',
  SuccessfullyaddedtoMyWishlist: 'Thêm vào danh sách yêu thích của tôi thành công',
  tiktokHomeText1: 'Nhấn vào logo để trở về trang chính.',
  tiktokHomeText2:
    'Uchoice-Tiktok chọn sản phẩm chất lượng tốt và hàng hóa hoa hồng cao cho cửa hàng Tiktok của bạn để làm cho việc bán hàng trực tiếp trên Tiktok của bạn thuận tiện và dễ dàng hơn. Cũng như chia sẻ sản phẩm để kiếm hoa hồng.',
  tiktokHomeText3:
    'Bạn cũng có thể chọn sản phẩm yêu thích của mình trên Uchoice-Tiktok, sau đó sao chép liên kết sản phẩm và thêm vào cửa hàng Tiktok của bạn.',
  tiktokHomeStepTips: 'Tìm hiểu thêm về cách sử dụng Uchoice - Tiktok:',
  banckHone: 'Trang chủ',
  shopDetail: 'Chi tiết sản phẩm',
  saleShop: 'Hoa hồng trên mỗi sản phẩm bán được',
  freeShipping: 'Miễn phí vận chuyển',
  pdpSkuMessage:
    'Máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc máy uốn tóc',
  shopTime: 'Ngày hết hạn：',
  ProductDescription: 'Mô tả sản phẩm',
  copyLink: 'Sao chép liên kết',
  collection: 'Thêm vào',
  collectioned: 'Xóa',
  All: 'Tất cả',
  Sales: 'Doanh số',
  Price: 'Giá',
  Commission: 'Hoa hồng',
  Commission_Rate: 'Hoa hồng%',
  Earn: 'Kiếm',
  xxx_Sold: 'Đã bán',
  Copy_Link: 'Sao chép liên kết',
  Until: 'Đến',
  Quit: 'Thoát',
  Login: 'Đăng nhập',
  English: 'Tiếng Anh',
  Thai: 'Tiếng Thái',
  Are_you_sure_to_log_out_of_the_current_account_: 'Bạn có chắc chắn muốn đăng xuất khỏi tài khoản hiện tại?',
  Earn_xxx_for_every_item_: 'Kiếm xxx cho mỗi sản phẩm ',
  What_is_Uchoice___Tiktok_: 'Uchoice & Tiktok là gì？',
  Click_the_logo_to_return_to_the_main_page_: 'Nhấn vào logo để trở về trang chính.',
  Uchoice_select_good_quality_products_and_high_commissions_from_Tiktok_shop_to_make_your_Tiktok_live_selling_products_more_convenient_and_easy__As_well_as_sharing_products_to_earn_commissions__You_can_select_your_favorite_products_on_Uchoice__then_copy_the_product_link_and_add_it_to_your_Tiktok_shop_: 'Uchoice chọn sản phẩm chất lượng tốt và hoa hồng cao từ cửa hàng Tiktok để làm cho việc bán hàng trực tiếp trên Tiktok của bạn thuận tiện và dễ dàng hơn. Cũng như chia sẻ sản phẩm để kiếm hoa hồng. Bạn có thể chọn sản phẩm yêu thích của mình trên Uchoice, sau đó sao chép liên kết sản phẩm và thêm vào cửa hàng Tiktok của bạn.',
  Learn_more_about_using_Uchoice_with_Tiktok_: 'Tìm hiểu thêm về cách sử dụng Uchoice với Tiktok:',
  Expired: 'Hết hạn',
  Successfully_added_to_My_Favorites: 'Thêm vào danh sách yêu thích của tôi thành công',
  Copied_: 'Đã sao chép!',
  SoldAfter: 'Đã bán',
  Sold: 'Đã bán',
  My_Favorites: 'Danh sách yêu thích của tôi',
  Clear_all_expired_products: 'Xóa tất cả sản phẩm đã hết hạn',
  Authorization: 'Ủy quyền',
  Benefits_with_authorization: 'Lợi ích với ủy quyền',
  Copy_the_High_Commission_product_link: 'Sao chép liên kết sản phẩm hoa hồng cao',
  AI_product_selection_with_analysis_fans_portrait: 'Lựa chọn sản phẩm AI với phân tích chân dung người hâm mộ',
  Millions_of_Tiktok_high_commission_product: 'Hàng triệu sản phẩm hoa hồng cao trên Tiktok',
  Exclusive_1v1_advisor: 'Tư vấn viên 1v1 độc quyền',
  Free_Tiktok_selling_training_video: 'Video đào tạo bán hàng trên Tiktok miễn phí',
  Priority_quota_for_free_samples: 'Ưu tiên hạn mức mẫu miễn phí',
  Authorize_now: 'Ủy quyền ngay',
  Cancel: 'Hủy',
  Copy_failed__please_authorize_tiktok_account_and_try_again_: 'Sao chép thất bại, vui lòng ủy quyền tài khoản Tiktok và thử lại~',
  Authorize_succeeded_: 'Ủy quyền thành công!',
  Link_copied_successfully_: 'Sao chép liên kết thành công!',
  Authorize_failed__xxxx: 'Ủy quyền thất bại: xxxx',
  This_Tiktok_account_is_bound_phone_number_0614564076: 'Tài khoản Tiktok này đã liên kết với số điện thoại 0614564076',
  Duplicate_authorization: 'Ủy quyền trùng lặp',
  Please_try_again: 'Vui lòng thử lại',
  Tiktok_system_error__please_contact_customer_service: 'Lỗi hệ thống Tiktok, vui lòng liên hệ dịch vụ khách hàng',
  The_request_is_overloaded__please_try_again_later: 'Yêu cầu quá tải, vui lòng thử lại sau',
  Authorized_and_login_successfully_: 'Ủy quyền và đăng nhập thành công!',
  Bind_mobile_phone_number: 'Liên kết số điện thoại',
  After_binding__there_is_a_chance_to_get_free_sample_qualification_: 'Sau khi liên kết, có cơ hội nhận được tư cách nhận mẫu miễn phí~',
  Phone_number: 'Số điện thoại',
  OTP: 'OTP',
  Send: 'Gửi',
  Register_or_bind_account: 'Đăng ký hoặc liên kết tài khoản',
  Later: 'Để sau',
  Uchoice_has_detected_that_the_tiktok_account_authorized_by_you_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_this_account_: 'Uchoice phát hiện tài khoản Tiktok bạn đã ủy quyền đã được đăng ký và liên kết với tài khoản Tiktok sau. Bạn có muốn hợp nhất chúng vào tài khoản này không?',
  Merge_accounts: 'Hợp nhất tài khoản',
  Uchoice_has_detected_that_the_mobile_phone_number_you_bound_has_been_registered_and_associated_with_the_following_tiktok_account__Do_you_want_to_merge_them_into_one_account_: 'Uchoice phát hiện số điện thoại bạn liên kết đã được đăng ký và liên kết với tài khoản Tiktok sau. Bạn có muốn hợp nhất chúng thành một tài khoản không?',
  Binding_failed__the_phone_number_has_already_been_registered_: 'Liên kết thất bại: số điện thoại đã được đăng ký!',
  Merge_successfully_: 'Hợp nhất thành công!',
  Your_Tiktok_account__xxxxxxx_authorization_has_expired__please_re_authorize_: 'Ủy quyền tài khoản Tiktok của bạn [#] đã hết hạn, vui lòng ủy quyền lại~',
  Reauthorize: 'Ủy quyền lại',
  Never_remind: 'Không nhắc lại',
  Authorization_has_been_canceled_: 'Ủy quyền đã bị hủy!',
  Tiktok_Account: 'Tài khoản Tiktok',
  Log_in_with_your_mobile_phone_number_and_have_a_chance_to_get_free_samples_: 'Đăng nhập bằng số điện thoại của bạn và có cơ hội nhận mẫu miễn phí!',
  Login_now__: 'Đăng nhập ngay',
  Add_Tiktok_account: 'Thêm tài khoản Tiktok',
  pending_reauthorization: 'chờ ủy quyền lại',
  The_tiktok_authorized_account_has_expired_and_needs_to_be_re_authorized_: 'Tài khoản Tiktok ủy quyền đã hết hạn và cần được ủy quyền lại.',
  Authorized: 'Đã ủy quyền',
  Multiple_Tiktok_accounts_can_only_be_authorized_after_binding_phone_number_: 'Nhiều tài khoản Tiktok chỉ có thể được ủy quyền sau khi liên kết số điện thoại~',
  Confirmed_and_Authorize_Now: 'Xác nhận và Ủy quyền ngay',
  Remind: 'Nhắc nhở: ',
  Before_authorizing__please_switch_and_log_in_to_the_target_account_on_Tiktok_: 'Trước khi ủy quyền, vui lòng chuyển và đăng nhập vào tài khoản mục tiêu trên Tiktok.',
  Failed_to_add__fail_to_bind_phone_number_: 'Thêm thất bại: không thể liên kết số điện thoại!',
  You_have_logged_in__bound_mobile_phone_number: 'Bạn đã đăng nhập, số điện thoại liên kết: ',
  Other_Method: 'Phương pháp khác',
  Tiktok_Login_register: 'Đăng nhập/đăng ký Tiktok',
  Authorizing: 'Đang trong quá trình ủy quyền, vui lòng kiên nhẫn...',
  canceled: 'Đã hủy',
  canceledCollect: 'Đã hủy ',
  You_should_bind_the_phone_number_first: 'Bạn nên liên kết số điện thoại trước',
  'Authorization failed': 'Ủy quyền thất bại: ',
  'Please re-authorize': 'Vui lòng thử lại',
  Merge: 'Hợp nhất',
  moreDetails: 'Thêm chi tiết',
  OpenApp: 'Mở ứng dụng',
  openAppTips: 'Tăng doanh số của bạn hôm nay.',
  soldMonth: '{sold} đã bán/tháng',
  feedSample: 'Mẫu miễn phí',
  salesForLast30Days: 'Doanh số 30 ngày qua',
  salesForTwoWeeksRate: 'Tăng trưởng doanh số%',
  salesStr: 'Tổng doanh số',
  anchorCount: 'Người bán hàng đầu',
  addShowCase: 'Thêm vào gian hàng',
  addCase: 'Thêm vào gian hàng',
  toSample: 'Nhận mẫu miễn phí',
  活动已过期: 'Chiến dịch đã hết hạn',
  '您浏览的活动或商品已下架，首页商品更丰富哦~': 'Chiến dịch hoặc sản phẩm bạn đã duyệt đã kết thúc. Kiểm tra trang chủ uChoice pro để có thêm nhiều lựa chọn!',
  去首页: 'Đi tới trang chủ'
};
